import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { 
	Box,
	Button,
	Container,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Fab,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemButton,
	Link as MUILink,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';

import { 
	Add as IconAdd, 
	MoreHoriz as IconMoreHoriz,
	Info as IconInfo,
	Edit as IconEdit,
	// ContentCopy as IconContentCopy,
	Delete as IconDelete,
} from '@mui/icons-material';

import { Link, useMatches, useNavigate } from "react-router-dom";

import Moment from 'moment';
import PropTypes from 'prop-types';

import { 
	loadCalculationList,
	createNewCalculation,
	deleteCalculation,
	calculationList,
	calculationStatus,
	clientInfo,
} from './calculationSlice';

import mixpanel from 'mixpanel-browser';

import { promiseContainer } from '../../components';
import { defaultCalculationTitle } from '../../constants';
import { Helmet } from 'react-helmet';

const CalculationList = props => {

	const [ menuAnchorEl, setMenuAnchorEl ] = useState(null);
	const [ menuAnchorData, setMenuAnchorData ] = useState(null);
	const [ menuId, setMenuId ] = useState(null);
	const [ advisorOrgId, setAdvisorOrgId ] = useState(null);
	const [ clientOrgId, setClientOrgId ] = useState(null);

	const list = useSelector(calculationList) || [];
	const propsClientInfo = useSelector(clientInfo) || {};
	const status = useSelector(calculationStatus);
	const dispatch = useDispatch();
	const matches = useMatches();
	const navigate = useNavigate();

	useEffect(() => {

		mixpanel.track_pageview({ page: 'list' });

		const params = matches[matches.length - 1]?.params;

		let _advisorOrgId, _clientOrgId;

		if(Object.prototype.hasOwnProperty.call(params, 'advisorOrgId') && Object.prototype.hasOwnProperty.call(params, 'clientOrgId')){
			_advisorOrgId = params?.advisorOrgId;
			_clientOrgId = params?.clientOrgId;
		}else if(props.advisorOrgId && props.clientOrgId){
			_advisorOrgId = props?.advisorOrgId;
			_clientOrgId = props?.clientOrgId;
		}

		if(_advisorOrgId && _clientOrgId){
			setAdvisorOrgId(_advisorOrgId);
			setClientOrgId(_clientOrgId);

			const load = () => {
				dispatch(loadCalculationList({ advisorOrgId: _advisorOrgId, clientOrgId: _clientOrgId }))
					.unwrap()
					.catch(err => {
						promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
							open={show}
							onClose={onDismiss}
						>
							<DialogTitle>Failed to load the calculation list</DialogTitle>
							<DialogContent>
								<DialogContentText>{err}</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={onDismiss}>Cancel</Button>
								<Button onClick={onConfirm} autoFocus>Retry</Button>
							</DialogActions>
						</Dialog>
						)
							.then(() => {
								load();
							})
							.catch(() => {});
					});
			};

			load();
		}
	}, []);

	const onMoreButtonClick = (e, item) => {
		mixpanel.track('Click "more" button on list');

		setMenuAnchorEl(e.currentTarget);
		setMenuAnchorData(item);
		setMenuId(item.id);
	};

	const closeMoreMenu = () => {
		setMenuAnchorEl(null);
		setMenuAnchorData(null);
		setMenuId(null);
	};

	const createNew = () => {
		mixpanel.track('Create new calculation');

		let new_name = default_name;
		const name = default_name;
		const reg_name = new RegExp(`(^${name}[^\\d]*)(\\d*)$`);

		let number_found = [];
		let count_found = 0;

		list.forEach(item => {
			const reg_result = reg_name.exec(item.name);
			if(reg_result?.length){
				count_found++;
				let num = reg_result[reg_result.length - 1];
				if(!Number.isNaN(parseInt(num))){
					number_found.push(parseInt(num));
				}
			}
		});

		number_found = Array.from(new Set(number_found)).sort().reverse();

		if(count_found){
			if(number_found.length){
				new_name = new_name + ' ' + (number_found[0] + 1);
			}else{
				new_name = new_name + ' 1';
			}
			// console.log(count_found, 'number_found', number_found, 'max val', number_found[0], 'new_name', new_name);
		}

		dispatch(createNewCalculation({ advisorOrgId, clientOrgId, data: { name: new_name, title: defaultCalculationTitle } }))
			.unwrap()
			.then(res => {
				navigate(`${matches[matches?.length - 1]?.pathname}${res?.id}${window.location.search || ''}`);
			})
			.catch(err => {
				promiseContainer(({ onDismiss, show }) => <Dialog
					open={show}
					onClose={onDismiss}
				>
					<DialogTitle>Failed to create</DialogTitle>
					<DialogContent>
						<DialogContentText>{err}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onDismiss}>OK</Button>
					</DialogActions>
				</Dialog>
				).catch(() => { });
			});
	};

	const onDelete = () => {
		mixpanel.track('Click "Delete" on calculation list menu');
	
		const deleteOne = () => {
			dispatch(deleteCalculation({ advisorOrgId, clientOrgId, calculationId: menuAnchorData.id }))
				.unwrap()
				.catch(err => {
					promiseContainer(({ onDismiss, show }) => <Dialog
						open={show}
						onClose={onDismiss}
					>
						<DialogTitle>Failed to delete</DialogTitle>
						<DialogContent>
							<DialogContentText>{err}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onDismiss}>OK</Button>
						</DialogActions>
					</Dialog>
					).catch(() => { });
				});
		};

		promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
			open={show}
			onClose={onDismiss}
		>
			<DialogTitle>Delete Calculation</DialogTitle>
			<DialogContent>
				<DialogContentText>Are you sure you want to delete &ldquo;{menuAnchorData?.name}&rdquo;?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onDismiss}>No</Button>
				<Button onClick={onConfirm} color="error">Delete</Button>
			</DialogActions>
		</Dialog>
		)
			.then(() => {
				mixpanel.track('Confirm "delete calculation"');
				deleteOne();
			})
			.catch(() => { })
			.finally(() => {
				closeMoreMenu();
			});
	};

	return (
		<Container>

			<Helmet>
				<title>Value Gap Calculator - List</title>
			</Helmet>

			<Stack direction="row" alignItems="start" justifyContent="space-between" sx={{ my: 5, px: 2 }}>
				<Stack>
					<Typography variant="h4" color="primary.dark" sx={{ fontWeight: 700 }}>Value Gap Calculator</Typography>
					{
						!!propsClientInfo.clientName &&
						<Typography variant="h5" color="primary">{propsClientInfo.clientName}</Typography>
					}
				</Stack>

				<MUILink href="https://help.thegaphq.com/portal/en/kb/articles/value" target="_blank"><Button variant="outlined" size="small" sx={{ borderRadius: '4rem' }}
					onClick={ () => mixpanel.track('Read Guide (via list)') }
				>
					<IconInfo size="small" sx={{ mr: 1 }} />
					Read Guide
				</Button></MUILink>
			</Stack>

			<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1, px: 2 }}>
				<Typography variant="h6" color="primary.dark" sx={{ fontWeight: 700 }}>Scenarios</Typography>

				<Fab
					variant="extended"
					color="primary"
					size="large"
					onClick={createNew}
					disabled={status === 'SAVING'}
				><IconAdd sx={{ mr: 1 }} />New</Fab>
			</Stack>

			<Stack alignItems="center" justifyContent="center">
				{
					status === 'LOADING' &&
					<CircularProgress size={32} sx={{ mb: 2 }} />
				}

				{
					status === 'LOADED' && !list?.length &&
					<Box sx={{ borderTopStyle: 'solid', borderTopWidth: 1, borderColor: 'lightgray', width: '100%', py: 6 }}>
						<Typography variant="h6" color="gray" sx={{ textAlign: 'center' }}>You have not created any scenarios yet. Use the &ldquo;+ New&rdquo; button to add your first scenario.</Typography>
					</Box>
				}
			</Stack>

			<List sx={{ pb: 16 }}>

				<Menu
					anchorEl={ menuAnchorEl }
					open={ Boolean(menuAnchorEl) }
					onClose={ closeMoreMenu }
				>
					<MenuItem component={Link} to={`${matches[matches?.length - 1]?.pathname}${menuId}${window.location.search || ''}`}
						onClick={() => mixpanel.track('Click calculation list - edit menu') } 
					>
						<ListItemIcon><IconEdit fontSize="small" /></ListItemIcon>
						<ListItemText>Edit</ListItemText>
					</MenuItem>
					{
						/*
						<MenuItem disabled>
							<ListItemIcon><IconContentCopy fontSize="small" /></ListItemIcon>
							<ListItemText>Duplicate</ListItemText>
						</MenuItem>
						*/
					}
					<Divider />
					<MenuItem sx={ { color: 'error.dark' } } onClick={onDelete}>
						<ListItemIcon><IconDelete fontSize="small" color="error" /></ListItemIcon>
						<ListItemText>Delete</ListItemText>
					</MenuItem>
				</Menu>

				{
					list.map((item, key) => (
						<React.Fragment key={ `calculation_list_item_${key}` }>
							{ key === 0 && <Divider component="li" /> }

							<ListItem
								secondaryAction={
									<IconButton aria-label="more options" onClick={ e => onMoreButtonClick(e, item) }>
										<IconMoreHoriz />
									</IconButton>
								}
								disablePadding
							>
								<ListItemButton component={Link}
									to={`${matches[matches?.length - 1]?.pathname}${item.id}${window.location.search || ''}`}
									onClick={ () => mixpanel.track('Click calculation list item') }
								>
									<Stack direction="row" alignItems="center" justifyContent="space-between" 
										sx={{ 
											width: '100%',
											flexDirection: {
												md: 'row',
												sm: 'column',
											},
											alignItems: {
												md: 'center',
												sm: 'start',
											},
										}}
									>
										<ListItemText
											sx={{ flexGrow: 1, mr: 2 }}
											primary={item.name}
											secondary={item.title}
										/>
										<ListItemText
											sx={{ flexGrow: 0, flexShrink: 0, pr: 2, whiteSpace: 'nowrap' }}
											secondary={Moment(item.createdAt).format('ddd D MMM, YYYY h:mm A')}
										/>
									</Stack>
								</ListItemButton>
							</ListItem>

							<Divider component="li" />
						</React.Fragment>
					))
				}
			</List>
		</Container>
	);
};

export default CalculationList;

const default_name = 'Unnamed calculation';

CalculationList.propTypes = {
	advisorOrgId: PropTypes.string,
	clientOrgId: PropTypes.string,
	calcId: PropTypes.string,
};
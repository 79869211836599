import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { 
	Box,
	Grid,
	Card,
	CardContent,
	Divider,
	Tooltip,
	Typography,
} from '@mui/material';
import _ from 'lodash';

import { 
	InfoOutlined as IconInfo,
} from '@mui/icons-material';

import { SliderInput } from '../../';
import { beautifyToFixed, addPlusMinusToNumber } from '../../../utilities';
import { tooltip, card, cardContent } from '../constants';
import thousands from 'thousands';
import { CurrencyContext } from '../contexts';

const Cashflow = (props) => {
	const [ data, setData ] = useState({});
	const { calculationResults } = props;
	const { debtorDays, inventoryDays, payableDays, cashConversionCycle, impact } = calculationResults?.increaseCashflow || {};
	const currency = useContext(CurrencyContext);

	useEffect(() => {
		if (!_.isEqual(props.values, data)) {
			setData(props.values);
		}
	}, [ props.values ]);

	const changeFieldValue = (field, value) => {
		setData(prevState => {
			const new_state = {
				...prevState,
				[field]: value,
			};

			if (props.onChange?.constructor?.name === 'Function') props.onChange(new_state);

			return new_state;
		});
	};

	return (
		<Card {...card}>
			<CardContent {...cardContent}>
				<Typography variant="h6" sx={{ px: 2.5, py: 1.5 }}>Cash Conversion</Typography>

				<Box>

					<Box sx={{ px: 1.5 }}>
						<Grid {...gridContainerHeader}>
							<Grid {...gridColumn1}>
								<Typography sx={{ color: 'gray' }}>Strategy</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<Typography sx={{ color: 'gray' }}>Change</Typography>
							</Grid>
							<Grid {...gridColumn3}>
								<Typography sx={{ color: 'gray' }}>Impact</Typography>
							</Grid>
						</Grid>
					</Box>

					<Divider />

					<Box sx={{ px: 1.5 }}>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Box>
									<Typography sx={{ mr: 1 }}>
										<Typography sx={{ mr: 1, display: 'inline' }}>Reduce debtor days</Typography>
										<Tooltip {...tooltip} title="How long on average it takes your customers to pay you impacts the amount of cash you need available to cover the delay.">
											<IconInfo fontSize="small" />
										</Tooltip>
									</Typography>
								</Box>
								{
									(debtorDays?.current || 0) === (debtorDays?.new || 0) ? 
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Debtor days are currently {thousands(beautifyToFixed(debtorDays?.current || 0))} days</Typography>
										:
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change debtor days from {thousands(beautifyToFixed(debtorDays?.current || 0))} to <strong>{thousands(beautifyToFixed(debtorDays?.new || 0))} days</strong></Typography>
								}
							</Grid>
							<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
								<SliderInput
									type="number"
									size="small"
									sliderMin={-20}
									sliderMax={20}
									valueLabelTemplate="{value} days"
									value={data?.debtorDaysChangeBy}
									onChange={e => changeFieldValue('debtorDaysChangeBy', parseFloat(e.target.value))}
								/>
							</Grid>
							<Grid {...gridColumn3}>
								<Typography>{addPlusMinusToNumber(beautifyToFixed(debtorDays?.impact?.cash || 0), true, currency)} freed up</Typography>
							</Grid>
						</Grid>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Box>
									<Typography sx={{ mr: 1 }}>
										<Typography sx={{ mr: 1, display: 'inline' }}>Reduce inventory/WIP days</Typography>
										<Tooltip {...tooltip} title="How long on average it takes to invoice your work or sell your stock. The longer this takes, the more of your cash is tied up in your business, or worse, the more debt and therefore more interest you are paying each year.">
											<IconInfo fontSize="small" />
										</Tooltip>
									</Typography>
								</Box>
								{
									inventoryDays?.current === inventoryDays?.new ?
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Inventory/WIP days are currently {thousands(beautifyToFixed(inventoryDays?.current))} days</Typography>
										:
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change inventory/WIP days from {thousands(beautifyToFixed(inventoryDays?.current))} to <strong>{thousands(beautifyToFixed(inventoryDays?.new))} days</strong></Typography>
								}
							</Grid>
							<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
								<SliderInput
									type="number"
									size="small"
									sliderMin={-20}
									sliderMax={20}
									valueLabelTemplate="{value} days"
									value={data?.inventoryDaysChangeBy}
									onChange={e => changeFieldValue('inventoryDaysChangeBy', parseFloat(e.target.value))}
								/>
							</Grid>
							<Grid {...gridColumn3}>
								<Typography>{addPlusMinusToNumber(beautifyToFixed(inventoryDays?.impact?.cash), true, currency)} freed up</Typography>
							</Grid>
						</Grid>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Box>
									<Typography sx={{ mr: 1 }}>
										<Typography sx={{ mr: 1, display: 'inline' }}>Reduce payable days</Typography>
										<Tooltip {...tooltip} title="How quickly you pay your suppliers could have an impact on your credit rating, as well as the willingness of your suppliers to do business with you.">
											<IconInfo fontSize="small" />
										</Tooltip>
									</Typography>
								</Box>
								{
									payableDays?.current === payableDays?.new ?
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Payable days are currently {thousands(beautifyToFixed(payableDays?.current))} days</Typography>
										: 
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change payable days from {thousands(beautifyToFixed(payableDays?.current))} to <strong>{thousands(beautifyToFixed(payableDays?.new))} days</strong></Typography>
								}
							</Grid>
							<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
								<SliderInput
									type="number"
									size="small"
									sliderMin={-20}
									sliderMax={20}
									valueLabelTemplate="{value} days"
									value={data?.payableDaysChangeBy}
									onChange={e => changeFieldValue('payableDaysChangeBy', parseFloat(e.target.value))}
								/>
							</Grid>
							<Grid {...gridColumn3}>
								<Typography>{addPlusMinusToNumber(beautifyToFixed(payableDays?.impact?.cash), true, currency)} cash required</Typography>
							</Grid>
						</Grid>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Box>
									<Typography sx={{ mr: 1 }}>
										<Typography sx={{ mr: 1, display: 'inline' }}>Cash conversion cycle</Typography>
										<Tooltip {...tooltip} title="Cash conversion cycle is calculated as average debtor days + inventory/WIP days - payable days. ">
											<IconInfo fontSize="small" />
										</Tooltip>
									</Typography>
								</Box>
								{
									(cashConversionCycle?.current || 0) === (cashConversionCycle?.new || 0) ?
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Current cash conversion cycle is {thousands(beautifyToFixed(cashConversionCycle?.current || 0))} days</Typography>
										:
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change cash conversion cycle from {thousands(beautifyToFixed(cashConversionCycle?.current || 0))} to <strong>{thousands(beautifyToFixed(cashConversionCycle?.new || 0))} days</strong></Typography>
								}
							</Grid>
							<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
							</Grid>
							<Grid {...gridColumn3}>
								<Typography>{addPlusMinusToNumber(beautifyToFixed(cashConversionCycle?.diff || 0))} days</Typography>
							</Grid>
						</Grid>

					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', borderTop: 1, borderColor: 'divider', p: 1 }}>
						<Typography variant="subtitle2" sx={{ fontWeight: 700, textAlignt: 'right', ml: 3, mr: 2 }}>Cash Impact</Typography>
						<Typography variant="h6">{addPlusMinusToNumber(beautifyToFixed(impact?.cash || 0), true, currency)}</Typography>
					</Box>

				</Box>
			</CardContent>
		</Card>
	);
};

export default Cashflow;

export const propTypes = {
	values: PropTypes.shape({
		debtorDaysChangeBy: 		PropTypes.number.isRequired,
		inventoryDaysChangeBy: 	PropTypes.number.isRequired,
		payableDaysChangeBy: 	PropTypes.number.isRequired,
	}).isRequired,
	calculationResults: PropTypes.object.isRequired,
	onChange: PropTypes.func,
};

Cashflow.propTypes = propTypes;

Cashflow.defaultProps ={
	values: {
		debtorDaysChangeBy: 		0,
		inventoryDaysChangeBy: 	0,
		payableDaysChangeBy: 	0,
	},
};


const gridContainer = {
	container: true,
	columnSpacing: 2,
	rowSpacing: 2,
	sx: {
		p: 1,
		mb: 2, 
	},
	direction: "row",
	justifyContent: "space-between",
	alignItems: "baseline",
};

const gridContainerHeader = {
	...gridContainer,
	sx: {
		...gridContainer.sx,
		mb: 0,
	},
};

const gridColumn = {
	item: true,
	sx: {
		display: 'flex', 
		flexDirection: 'column',
		alignItems: 'start',
	},
};

const gridColumn1 = {
	...gridColumn,
	xs: 5,
	sm: 5,
	md: 5,
	lg: 5,
};

const gridColumn2 = {
	...gridColumn,
	sx: {
		...gridColumn.sx,
		justifyContent: 'end',
	},
	xs: 4,
	sm: 4,
	md: 4,
	lg: 4,
};

const gridColumn3 = {
	...gridColumn,
	sx: {
		...gridColumn.sx,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'end',
		textAlign: 'right',
	},
	xs: 3,
	sm: 3,
	md: 3,
	lg: 3,
};

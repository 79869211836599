import axios from 'axios';

const baseSearchParams = {
	batch: '1',
};

/**
 * Gets the calculation list.
 * @param advisorOrgId
 * @param clientOrgId 
 */
export const fetchList = (advisorOrgId: string, clientOrgId: string): Promise<TCalculationListItem[]> => {
	return new Promise((resolve, reject) => {
		const inputData = {
			0: {
				json: {
					clientOrgId,
					advisorOrgId,
				}
			}
		};

		const params = new URLSearchParams({
			...baseSearchParams,
			input: JSON.stringify(inputData),
		});

		const url = `/vgc.getCalculationList?${params.toString()}`;

		axios({
			url,
			method: 'get',
		})
			.then(res => {
				resolve(res.data?.['0']?.result?.data?.json || []);
			})
			.catch(err => {
				reject(err);
			});
	});
};

/**
 * Creates a new calculation.
 * @param advisorOrgId
 * @param clientOrgId 
 * @param data 
 */
export const create = (advisorOrgId: string, clientOrgId: string, data: TCreateCalculationPayload): Promise<TCalculationListItem> => {
	return new Promise((resolve, reject) => {
		const params = new URLSearchParams({
			...baseSearchParams,
		});

		const url = `/vgc.createNewCalculation?${params.toString()}`;

		const payloadData = {
			0: {
				json: {
					...data || {},
					advisorOrgId,
					clientOrgId
				}
			}
		};

		axios({
			url,
			method: 'post',
			data: payloadData,
		})
			.then(res => {
				resolve(res.data?.['0']?.result?.data?.json || {});
			})
			.catch(err => {
				reject(err);
			});
	});
};

/**
 * Saves a calculation as a new one.
 * @param advisorOrgId
 * @param clientOrgId 
 * @param calculationId 
 */
export const saveAs = (advisorOrgId: string, clientOrgId: string, calculationId: string): Promise<TCalculationListItem> => {
	return new Promise((resolve, reject) => {
		const payloadData = {
			0: {
				json: {
					advisorOrgId,
					clientOrgId,
					id: calculationId,
				}
			}
		};

		const params = new URLSearchParams({
			...baseSearchParams,
		});

		const url = `/vgc.saveAsNewCalculation?${params.toString()}`;

		axios({
			url,
			method: 'post',
			data: payloadData,
		})
			.then(res => {
				resolve(res.data?.['0']?.result?.data?.json || {});
			})
			.catch(err => {
				reject(err);
			});
	});
};

/**
 * Deletes a calculation
 * @param advisorOrgId
 * @param clientOrgId 
 * @param calculationId 
 * @returns 
 */
export const deleteOne = (advisorOrgId: string, clientOrgId: string, calculationId: string): Promise<string> => {
	return new Promise((resolve, reject) => {
		const payloadData = {
			0: {
				json: {
					advisorOrgId,
					clientOrgId,
					id: calculationId,
				}
			}
		};

		const params = new URLSearchParams({
			...baseSearchParams,
		});

		const url = `/vgc.deleteCalculation?${params.toString()}`;

		axios({
			url,
			method: 'post',
			data: payloadData
		})
			.then(() => {
				resolve(calculationId);
			})
			.catch(err => {
				reject(err);
			});
	});
};

/**
 * Gets a calculation data.
 * @param advisorOrgId
 * @param clientOrgId 
 * @param calculationId 
 */
export const load = (advisorOrgId: string, clientOrgId: string, calculationId: string): Promise<TCalculationDetails> => {
	return new Promise((resolve, reject) => {

		const inputData = {
			0: {
				json: {
					advisorOrgId,
					clientOrgId,
					id: calculationId,
				}
			}
		};

		const params = new URLSearchParams({
			...baseSearchParams,
			input: JSON.stringify(inputData),
		});

		const url = `/vgc.getCalculationData?${params.toString()}`;

		axios({
			url,
			method: 'get',
		})
			.then(res => {
				resolve(res.data?.['0']?.result?.data?.json || {});
			})
			.catch(err => {
				reject(err);
			});
	});
};

/**
 * Saves a calculation
 * @param advisorOrgId
 * @param clientOrgId 
 * @param calculationId 
 * @param calculation 
 */
export const save = (advisorOrgId: string, clientOrgId: string, calculationId: string, calculation: TCalculationDetails) => {
	return new Promise((resolve, reject) => {

		const payloadData = {
			0: {
				json: {
					advisorOrgId,
					clientOrgId,
					...calculation,
					id: calculationId,
				}
			}
		};

		const params = new URLSearchParams({
			...baseSearchParams,
		});

		const url = `/vgc.updateCalculation?${params.toString()}`;

		axios({
			url,
			method: 'POST',
			data: payloadData,
		})
			.then(() => {
				resolve(calculation);
			})
			.catch(err => {
				reject(err);
			});
	});
};

/**
 * Saves the report modifications
 * @param advisorOrgId
 * @param clientOrgId 
 * @param calculationId 
 * @param data 
 */
export const saveReportData = (advisorOrgId: string, clientOrgId: string, calculationId: string, reportModifications: TCalculationReportModifications): Promise<TCalculationReportModifications> => {
	return new Promise((resolve, reject) => {
		const payloadData = {
			0: {
				json: {
					advisorOrgId,
					clientOrgId,
					id: calculationId,
					reportModifications,
				}
			}
		};

		const params = new URLSearchParams({
			...baseSearchParams,
		});

		const url = `/vgc.updateReportModifications?${params.toString()}`;

		axios({
			url,
			method: 'post',
			data: payloadData,
		})
			.then(() => {
				resolve(reportModifications);
			})
			.catch(err => {
				reject(err);
			});
	});
};
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';

import { 
	ExpandMore as IconExpandMore,
} from '@mui/icons-material';

const Group = (props) => {

	const [ expanded, setExpanded ] = useState(props.expanded);

	useEffect(() => {
		setExpanded(props.expanded);
	}, [ props.expanded ]);

	const toggleExpand = () => {
		setExpanded(prevState => !prevState);
	};

	return (
		<Accordion 
			{...props.collapsible ? {
				expanded: expanded,
				onChange: toggleExpand,
			} : { expanded: true }}
			disabled={!props.enabled}
		>

			<AccordionSummary 
				{...props.collapsible && {
					expandIcon: (<IconExpandMore />),
				}}
			>
				<Typography>{ props.summaryComponent }</Typography>
			</AccordionSummary>

			<AccordionDetails sx={{ borderTop: '1px solid rgba(0, 0, 0, .125)', p: 0 }}>
				{ props.detailComponent }
			</AccordionDetails>

		</Accordion>
	);
};

export default Group;

Group.propTypes = {
	collapsible: PropTypes.bool,
	expanded: PropTypes.bool,
	enabled: PropTypes.bool,
	summaryComponent: PropTypes.element,
	detailComponent: PropTypes.element,
};

Group.defaultProps = {
	collapsible: true,
	expanded: false,
	enabled: true,
	summaryComponent: <>Group</>,
	detailComponent: <></>,
};
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
	fetchList, 
	create,
	deleteOne,
	load,
	save,
	saveAs,
	saveReportData,
} from "./calculationAPI";
import { presetReportModifications } from "../../components/calculator/constants";

export const statuses = {
	idle: 'IDLE',
	loading: 'LOADING',
	loaded: 'LOADED',
	saving: 'SAVING',
	deleting: 'DELETING',
	saved: 'SAVED',
	error: 'ERROR',
};

const initialState = {
	clientInfo: {},
	calculation: {},
	list: [],
	status: statuses.idle,
};

export const loadCalculationList = createAsyncThunk(
	'calculation/fetchList',
	async ({ advisorOrgId, clientOrgId }, { rejectWithValue }) => {
		try{
			const response = await fetchList(advisorOrgId, clientOrgId);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const createNewCalculation = createAsyncThunk(
	'calculation/create',
	async ({ advisorOrgId, clientOrgId, data }, { rejectWithValue }) => {
		try{
			const response = await create(advisorOrgId, clientOrgId, data);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const saveAsNew = createAsyncThunk(
	'calculation/saveAs',
	async ({ advisorOrgId, clientOrgId, calculationId }, { rejectWithValue }) => {
		try{
			const response = await saveAs(advisorOrgId, clientOrgId, calculationId);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const deleteCalculation = createAsyncThunk(
	'calculation/delete',
	async ({ advisorOrgId, clientOrgId, calculationId }, { rejectWithValue }) => {
		try{
			const response = await deleteOne(advisorOrgId, clientOrgId, calculationId);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const saveCalculation = createAsyncThunk(
	'calculation/save',
	async ({ advisorOrgId, clientOrgId, calculationId, calculation }, { rejectWithValue }) => {
		try{
			const response = await save(advisorOrgId, clientOrgId, calculationId, calculation);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);

export const loadCalculation = createAsyncThunk(
	'calculation/load',
	async ({ advisorOrgId, clientOrgId, calculationId }, { rejectWithValue }) => {
		try {
			const response = await load(advisorOrgId, clientOrgId, calculationId);
			return response;
		} catch (err) {
			return rejectWithValue(err.message);
		}
	}
);

export const saveReportModifications = createAsyncThunk(
	'calculation/saveReportModifications',
	async ({ advisorOrgId, clientOrgId, calculationId, reportModifications }, { rejectWithValue }) => {
		try{
			const response = await saveReportData(advisorOrgId, clientOrgId, calculationId, reportModifications);
			return response;
		}catch(err){
			return rejectWithValue(err.message);
		}
	}
);;


export const calculationSlice = createSlice({
	name: 'calculations',
	initialState,
	reducers: {
	// 	loadCalculationList: (state) => {
	// 		state.list = [ 
	// 			{
	// 				id: 'calc_1',
	// 				name: 'Calc 1',
	// 				data: { val_1: 16 },
	// 				created_at: new Date().toString(),
	// 				modified_at: new Date().toString(),
	// 			},
	// 			{
	// 				id: 'calc_2',
	// 				name: 'Calc 2',
	// 				data: { val_1: 16 },
	// 				created_at: new Date().toString(),
	// 				modified_at: new Date().toString(),
	// 			},
	// 			{
	// 				id: 'calc_3',
	// 				name: 'Calc 3',
	// 				data: { val_1: 16 },
	// 				created_at: new Date().toString(),
	// 				modified_at: new Date().toString(),
	// 			},
	// 		];
	// 	}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadCalculationList.pending, state => {
				state.status = statuses.loading;
			}).addCase(loadCalculationList.fulfilled, (state, action) => {
				state.status = statuses.loaded;
				state.list = action.payload?.list || [];
				state.clientInfo = action.payload?.clientInfo || {};
			}).addCase(loadCalculationList.rejected, state => {
				state.status = statuses.error;
			})
			
			.addCase(createNewCalculation.pending, state => {
				state.status = statuses.saving;
			}).addCase(createNewCalculation.fulfilled, (state, action) => {
				state.status = statuses.saved;
				state.list = [
					action.payload,
					...state.list,
				];
			}).addCase(createNewCalculation.rejected, state => {
				state.status = statuses.error;
			})

			.addCase(deleteCalculation.pending, state => {
				state.status = statuses.deleting;
			}).addCase(deleteCalculation.fulfilled, (state, action) => {
				state.status = statuses.saved;
				state.list = (state.list || []).filter(item => item.id != action.payload);
			}).addCase(deleteCalculation.rejected, state => {
				state.status = statuses.error;
			})
			
			.addCase(loadCalculation.pending, state => {
				state.status = statuses.loading;
				state.calculation = {};
			}).addCase(loadCalculation.fulfilled, (state, action) => {
				state.calculation = action.payload;

				if (!Object.keys(action.payload?.reportModifications || {}).length) {
					state.calculation = {
						...state.calculation,
						reportModifications: presetReportModifications,
					};
				}

				state.status = statuses.loaded;
			}).addCase(loadCalculation.rejected, state => {
				state.status = statuses.error;
			})
			
			.addCase(saveCalculation.pending, state => {
				state.status = statuses.saving;
			}).addCase(saveCalculation.fulfilled, (state, action) => {
				state.status = statuses.saved;
				state.calculation = { ...state.calculation, ...action.payload || {} };
			}).addCase(saveCalculation.rejected, state => {
				state.status = statuses.error;
			})
			
			.addCase(saveAsNew.pending, state => {
				state.status = statuses.saving;
			}).addCase(saveAsNew.fulfilled, (state) => {
				state.status = statuses.saved;
			}).addCase(saveAsNew.rejected, state => {
				state.status = statuses.error;
			})
			
			.addCase(saveReportModifications.pending, state => {
				state.status = statuses.saving;
			})
			.addCase(saveReportModifications.fulfilled, (state, action) => {
				state.status = statuses.saved;
				state.calculation = {
					...state.calculation,
					reportModifications: action.payload,
				};
			})
			.addCase(saveReportModifications.rejected, state => {
				state.status = statuses.error;
			});
	}
});

// export const { loadCalculationList } = calculationSlice.actions;

export const clientInfo = (state) => state.calculations?.clientInfo;
export const calculationList = (state) => state.calculations?.list;
export const calculation = (state) => state.calculations?.calculation;
export const calculationStatus = (state) => state.calculations?.status;

export default calculationSlice.reducer;

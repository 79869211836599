import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { 
	Box,
	Card,
	CardContent,
	Divider,
	Grid,
	InputAdornment,
	Tooltip,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	Stack,
	Switch,
} from '@mui/material';
import _ from 'lodash';

import { 
	InfoOutlined as IconInfo,
} from '@mui/icons-material';

// import { usePrevious } from '@uidotdev/usehooks';

import { NumericInput } from '../../';
import { beautifyToFixed } from '../../../utilities';
import { currencyInput, numberInput, percentageInput, tooltip, card, cardContent } from '../constants';
import { CurrencyContext } from '../contexts';

const Inputs = (props) => {
	const [ data, setData ] = useState({});
	const { calculationResults } = props;

	const currency = useContext(CurrencyContext);
	// const prevHaveOtherIncome = usePrevious(data?.haveOtherIncome);

	useEffect(() => {
		if (!_.isEqual(props.values, data)) {
			setData(props.values);
		}
	}, [ props.values ]);

	const changeFieldValue = (field, value) => {
		setData(prevState => {
			const new_state = {
				...prevState,
				[field]: value,
			};

			if (props.onChange?.constructor?.name === 'Function') props.onChange(new_state);

			return new_state;
		});
	};

	return (
		<>
			<Card {...card}>
				<CardContent {...cardContent}>
					<Typography variant="h6" sx={{ px: 2.5, py: 1.5 }}>Profit and Loss</Typography>

					<Divider />

					<Box sx={{ px: 1.5, py: 2 }}>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Typography sx={{ mr: 1 }}>
									<Typography sx={{ mr: 1, display: 'inline' }}>Sales</Typography>
									<Tooltip {...tooltip} title="Money generated from selling goods or services to customers">
										<IconInfo fontSize="small" />
									</Tooltip>
								</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<NumericInput {...currencyInput} currency={currency} showInnerSpinButtons={false} placeholder={currency} name="var_sales" value={data?.sales} onChange={e => changeFieldValue('sales', parseFloat(e.target.value))} />
							</Grid>
						</Grid>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Typography sx={{ mr: 1 }}>
									<Typography sx={{ mr: 1, display: 'inline' }}>Cost of sales / direct costs</Typography>
									<Tooltip {...tooltip} title="Cost of producing goods or services sold to customers.  These are largely variable costs in that, generally, they increase as sales increase.">
										<IconInfo fontSize="small" />
									</Tooltip>
								</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<NumericInput {...currencyInput} currency={currency} showInnerSpinButtons={false} placeholder={currency} name="var_cost_of_sales" value={data?.directCost} onChange={e => changeFieldValue('directCost', parseFloat(e.target.value))} />
							</Grid>
						</Grid>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Typography sx={{ mr: 1 }}>
									<Typography sx={{ mr: 1, display: 'inline' }}>Overhead expenses</Typography>
									<Tooltip {...tooltip} title="Expenses associated with running the business.  These are largely fixed costs that, generally, don't increase as sales increase - add an allowance for owners' wages here if owners are not currently receiving a wage.">
										<IconInfo fontSize="small" />
									</Tooltip>
								</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<NumericInput {...currencyInput} currency={currency} showInnerSpinButtons={false} placeholder={currency} name="var_overheadExpenses" value={data?.overheadExpenses} onChange={e => changeFieldValue('overheadExpenses', parseFloat(e.target.value))} />
							</Grid>
						</Grid>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Typography sx={{ mr: 1 }}>
									<Typography sx={{ mr: 1, display: 'inline' }}>Do you have other income?</Typography>
									<Tooltip {...tooltip} title="This is only included so you can display the correct net profit number. It is not a key driver.">
										<IconInfo fontSize="small" />
									</Tooltip>
								</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<ToggleButtonGroup
									size="small"
									value={data?.haveOtherIncome || 0}
									exclusive
									onChange={(e, newValue) => {
										if (Number.isFinite(newValue)) {
											if (newValue != 1) {
												changeFieldValue('otherIncome', 0);
											}
											changeFieldValue('haveOtherIncome', parseInt(newValue));
										}
									}}
								>
									<ToggleButton value={1}>Yes</ToggleButton>
									<ToggleButton value={0}>No</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>

						{
							data?.haveOtherIncome == 1 && <Grid {...gridContainer}>
								<Grid {...gridColumn1}>
									<Typography sx={{ mr: 1 }}>
										<Typography sx={{ mr: 1, display: 'inline' }}>Other income</Typography>
									</Typography>
								</Grid>
								<Grid {...gridColumn2}>
									<NumericInput {...currencyInput} currency={currency} showInnerSpinButtons={false} placeholder={currency} name="var_otherIncome" value={data?.otherIncome} onChange={e => changeFieldValue('otherIncome', parseFloat(e.target.value))} />
								</Grid>
							</Grid>
						}


					</Box>
				</CardContent>
			</Card>

			<Card {...card}>
				<CardContent {...cardContent}>
					<Typography variant="h6" sx={{ px: 2.5, py: 1.5 }}>Balance Sheet</Typography>

					<Divider />

					<Box sx={{ px: 1.5, py: 2 }}>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Typography sx={{ mr: 1 }}>
									<Typography sx={{ mr: 1, display: 'inline' }}>Accounts receivable / debtors</Typography>
									<Tooltip {...tooltip} title="Also known as debtors.">
										<IconInfo fontSize="small" />
									</Tooltip>
								</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<NumericInput {...currencyInput} currency={currency} showInnerSpinButtons={false} placeholder={currency} name="var_accountsReceivable" value={data?.accountsReceivable} onChange={e => changeFieldValue('accountsReceivable', parseFloat(e.target.value))} />
							</Grid>
						</Grid>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Typography sx={{ mr: 1 }}>
									<Typography sx={{ mr: 1, display: 'inline' }}>Inventory / Work in Progress (WIP)</Typography>
									<Tooltip {...tooltip} title="Inventory / stock on hand / goods or services awaiting completion.">
										<IconInfo fontSize="small" />
									</Tooltip>
								</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<NumericInput {...currencyInput} currency={currency} showInnerSpinButtons={false} placeholder={currency} name="var_inventory" value={data?.inventory} onChange={e => changeFieldValue('inventory', parseFloat(e.target.value))} />
							</Grid>
						</Grid>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Typography sx={{ mr: 1 }}>
									<Typography sx={{ mr: 1, display: 'inline' }}>Accounts payable / creditors</Typography>
									<Tooltip {...tooltip} title="Also known as creditors. Ensure this doesn't include tax as a payable.">
										<IconInfo fontSize="small" />
									</Tooltip>
								</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<NumericInput {...currencyInput} currency={currency} showInnerSpinButtons={false} placeholder={currency} name="var_accountsPayable" value={data?.accountsPayable} onChange={e => changeFieldValue('accountsPayable', parseFloat(e.target.value))} />
							</Grid>
						</Grid>

					
					</Box>
				</CardContent>
			</Card>

			<Card {...card}>
				<CardContent {...cardContent}>
					<Typography variant="h6" sx={{ px: 2.5, py: 1.5 }}>Other Financial Variables</Typography>

					<Divider />

					<Box sx={{ px: 1.5, py: 2 }}>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Typography sx={{ mr: 1 }}>
									<Typography sx={{ mr: 1, display: 'inline' }}>Tax rate</Typography>
									<Tooltip {...tooltip} title="Included only to demonstrate approximate after-tax impact.">
										<IconInfo fontSize="small" />
									</Tooltip>
								</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<NumericInput {...percentageInput} name="var_taxRate" showInnerSpinButtons={false} 
									value={data?.taxRate} 
									onChange={e => changeFieldValue('taxRate', parseFloat(e.target.value))} 
								/>
							</Grid>
						</Grid>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Typography sx={{ mr: 1 }}>
									<Typography sx={{ mr: 1, display: 'inline' }}>Valuation multiple</Typography>
									<Tooltip {...tooltip} title="Indicative value for illustration purposes only.">
										<IconInfo fontSize="small" />
									</Tooltip>
								</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<NumericInput {...numberInput} name="var_valuationMultiple" showInnerSpinButtons={false} value={data?.valuationMultiple} onChange={e => changeFieldValue('valuationMultiple', parseFloat(e.target.value))} />
							</Grid>
						</Grid>

					
					</Box>
				</CardContent>
			</Card>

			<Card {...card}>
				<CardContent {...cardContent}>
					<Typography variant="h6" sx={{ px: 2.5, py: 1.5 }}>Team Members</Typography>

					<Divider />

					<Box sx={{ px: 1.5, py: 2 }}>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Typography sx={{ mr: 1 }}>
									<Typography sx={{ mr: 1, display: 'inline' }}>Number of team members</Typography>
									<Tooltip {...tooltip} title="Enter as Full Time Equivalent (FTE) including owners. All team members contribute to overall revenue, even if indirectly; however, you can refine this to revenue-producing team members only if you prefer.">
										<IconInfo fontSize="small" />
									</Tooltip>
								</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<NumericInput {...numberInput}
									name="var_number_of_teamMembers"
									showInnerSpinButtons={false}
									min={0}
									endAdornment={<InputAdornment position="end">FTE</InputAdornment>}
									value={data?.teamMembers} onChange={e => changeFieldValue('teamMembers', parseFloat(e.target.value))}
								/>
							</Grid>
						</Grid>

					
					</Box>
				</CardContent>
			</Card>

			<Card {...card}>
				<CardContent {...cardContent}>
					<Stack direction="row" alignItems="center" justifyContent="space-between">

						<Typography variant="h6" sx={{ px: 2.5, py: 1.5 }}>Customers and Sales</Typography>

						<Stack direction="row" alignItems="center" justifyContent="end" sx={{ pr: 1 }}>
							<Typography variant="body2" sx={{ color: 'gray' }}>Enter detailed sales drivers</Typography>
							<Switch
								checked={data?.useCustomersAndSales == 1 ? true : false}
								onChange={e => changeFieldValue('useCustomersAndSales', e.target.checked ? 1 : 0)}
							/>
						</Stack>

					</Stack>

					{
						data?.useCustomersAndSales == 1 && <>

							<Divider />

							<Box sx={{ px: 1.5, py: 2 }}>

								<Grid {...gridContainer}>
									<Grid {...gridColumn1}>
										<Typography sx={{ mr: 1 }}>
											<Typography sx={{ mr: 1, display: 'inline' }}>Are your sales predominantly from repeat/ongoing customers?</Typography>
											<Tooltip {...tooltip} title="Select No if the majority of your business is transactional (eg retail, hospitality).">
												<IconInfo fontSize="small" />
											</Tooltip>
										</Typography>
									</Grid>
									<Grid {...gridColumn2}>
										<ToggleButtonGroup
											size="small"
											value={data?.repeatCustomers || 0}
											exclusive
											onChange={(e, newValue) => {
												if (Number.isFinite(newValue)) {
													changeFieldValue('repeatCustomers', parseInt(newValue));
												}
											}}
										>
											<ToggleButton value={1}>Yes</ToggleButton>
											<ToggleButton value={0}>No</ToggleButton>
										</ToggleButtonGroup>
									</Grid>
								</Grid>

								{
									data.repeatCustomers == 1 && <>

										<Grid {...gridContainer}>
											<Grid {...gridColumn1}>
												<Typography sx={{ mr: 1 }}>
													<Typography sx={{ mr: 1, display: 'inline' }}>Number of existing customers</Typography>
													<Tooltip {...tooltip} title="Use best estimate.">
														<IconInfo fontSize="small" />
													</Tooltip>
												</Typography>
											</Grid>
											<Grid {...gridColumn2}>
												<NumericInput {...numberInput}
													min={0}
													showInnerSpinButtons={false}
													value={data.existingCustomers}
													onChange={e => changeFieldValue('existingCustomers', parseInt(e.target.value))}
												/>
											</Grid>
										</Grid>

										<Grid {...gridContainer}>
											<Grid {...gridColumn1}>
												<Typography sx={{ mr: 1 }}>
													<Typography sx={{ mr: 1, display: 'inline' }}>Retention rate</Typography>
													<Tooltip {...tooltip} title="Use best estimate based on number of customers lost during the year divided by number of customers at the beginning of the year.">
														<IconInfo fontSize="small" />
													</Tooltip>
												</Typography>
											</Grid>
											<Grid {...gridColumn2}>
												<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100%' }}>
													<NumericInput {...percentageInput}
														min={0}
														max={100}
														showInnerSpinButtons={false}
														value={data.retentionRate}
														onChange={e => changeFieldValue('retentionRate', parseFloat(e.target.value))}
													/>
													<Typography variant="body2" color="gray" sx={{ mt: .5, textAlign: 'right' }}>{beautifyToFixed(calculationResults?.increaseSales?.retainedCustomers?.current || 0, 2)} retained customers</Typography>
												</Box>
											</Grid>
										</Grid>

									</>
								}

								<Grid {...gridContainer}>
									<Grid {...gridColumn1}>
										<Typography sx={{ mr: 1 }}>
											<Typography sx={{ mr: 1, display: 'inline' }}>Do you know your lead generation and conversion rates?</Typography>
											<Tooltip {...tooltip} title="A lead is an enquiry / request for proposal.  Your conversion rate is how many sales / proposals you win as a percentage of all enquiries / requests for proposals.">
												<IconInfo fontSize="small" />
											</Tooltip>
										</Typography>
									</Grid>
									<Grid {...gridColumn2}>
										<ToggleButtonGroup
											size="small"
											value={data?.knownLeadGen || 0}
											exclusive
											onChange={(e, newValue) => {
												if (Number.isFinite(newValue)) {
													changeFieldValue('knownLeadGen', parseInt(newValue));
												}
											}}
										>
											<ToggleButton value={1}>Yes</ToggleButton>
											<ToggleButton value={0}>No</ToggleButton>
										</ToggleButtonGroup>
									</Grid>
								</Grid>

								{
									data.knownLeadGen == 1 && <>

										<Grid {...gridContainer}>
											<Grid {...gridColumn1}>
												<Typography sx={{ mr: 1 }}>
													<Typography sx={{ mr: 1, display: 'inline' }}>Number of leads generated per year</Typography>
													<Tooltip {...tooltip} title="Use best estimate.">
														<IconInfo fontSize="small" />
													</Tooltip>
												</Typography>
											</Grid>
											<Grid {...gridColumn2}>
												<NumericInput {...numberInput}
													min={0}
													showInnerSpinButtons={false}
													value={data.leadsPerYear}
													onChange={e => changeFieldValue('leadsPerYear', parseInt(e.target.value))}
												/>
											</Grid>
										</Grid>

										<Grid {...gridContainer}>
											<Grid {...gridColumn1}>
												<Typography sx={{ mr: 1 }}>
													<Typography sx={{ mr: 1, display: 'inline' }}>Lead conversion rate</Typography>
													<Tooltip {...tooltip} title="Percentage of leads who go on to become customers.">
														<IconInfo fontSize="small" />
													</Tooltip>
												</Typography>
											</Grid>
											<Grid {...gridColumn2}>
												<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100%' }}>
													<NumericInput {...percentageInput}
														min={0}
														max={100}
														showInnerSpinButtons={false}
														value={data.leadConversionRate}
														onChange={e => changeFieldValue('leadConversionRate', parseFloat(e.target.value))}
													/>
													<Typography variant="body2" color="gray" sx={{ mt: .5, textAlign: 'right' }}>{beautifyToFixed(calculationResults?.increaseSales?.newCustomers?.current || 0, 2)} new customers</Typography>
												</Box>
											</Grid>
										</Grid>

									</>
								}

								{
									data.repeatCustomers == 1 && data.knownLeadGen == 0 &&
									<Grid {...gridContainer}>
										<Grid {...gridColumn1}>
											<Typography sx={{ mr: 1 }}>
												<Typography sx={{ mr: 1, display: 'inline' }}>Estimated new customers</Typography>
												<Tooltip {...tooltip} title="Use best estimate.">
													<IconInfo fontSize="small" />
												</Tooltip>
											</Typography>
										</Grid>
										<Grid {...gridColumn2}>
											<NumericInput {...numberInput}
												min={0}
												showInnerSpinButtons={false}
												value={data.estimatedNewCustomers}
												onChange={e => changeFieldValue('estimatedNewCustomers', parseInt(e.target.value))}
											/>
										</Grid>
									</Grid>
								}

								<Grid {...gridContainer}>
									<Grid {...gridColumn1}>
										<Typography sx={{ mr: 1 }}>
											<Typography sx={{ mr: 1, display: 'inline' }}>Total customers per year</Typography>
											<Tooltip {...tooltip} title="Use best estimate.">
												<IconInfo fontSize="small" />
											</Tooltip>
										</Typography>
									</Grid>
									<Grid {...gridColumn2}>
										<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100%' }}>
											<NumericInput {...numberInput}
												showInnerSpinButtons={false}
												{...(data?.repeatCustomers == 0 && data?.knownLeadGen == 0) ?
													// enabled
													{
														value: data?.totalCustomers,
														onChange: e => changeFieldValue('totalCustomers', parseInt(e.target.value)),
													}
													:
													// disabled
													{
														value: beautifyToFixed(calculationResults?.increaseSales?.totalCustomers?.new || 0),
													}
												}
												disabled={data?.repeatCustomers != 0 || data?.knownLeadGen != 0}
											/>
											{
												data?.repeatCustomers == 1 && data?.knownLeadGen == 0 &&
												<Typography variant="body2" color="gray" sx={{ mt: .5, textAlign: 'right' }}>Based on number of retained + new customers</Typography>
											}
										</Box>
									</Grid>
								</Grid>

								<Grid {...gridContainer}>
									<Grid {...gridColumn1}>
										<Typography sx={{ mr: 1 }}>
											<Typography sx={{ mr: 1, display: 'inline' }}>Transactions per customer per year</Typography>
											<Tooltip {...tooltip} title="Leave as 1 if unknown or if customers pay annual amount.">
												<IconInfo fontSize="small" />
											</Tooltip>
										</Typography>
									</Grid>
									<Grid {...gridColumn2}>
										<NumericInput {...numberInput}
											min={0}
											showInnerSpinButtons={false}
											value={data.transactionsPerClientPerYear}
											onChange={e => changeFieldValue('transactionsPerClientPerYear', parseFloat(e.target.value))}
										/>
									</Grid>
								</Grid>

								<Grid {...gridContainer}>
									<Grid {...gridColumn1}>
										<Typography sx={{ mr: 1 }}>
											<Typography sx={{ mr: 1, display: 'inline' }}>Average transaction value</Typography>
										</Typography>
									</Grid>
									<Grid {...gridColumn2}>
										<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100%' }}>
											<NumericInput {...currencyInput} currency={currency} placeholder={currency} showInnerSpinButtons={false}
												value={beautifyToFixed(calculationResults?.increaseSales?.averageTransactionValue?.new || 0)}
												disabled
											/>
											<Typography variant="body2" color="gray" sx={{ mt: .5, textAlign: 'right' }}>Based on sales ÷ number of customers ÷ transactions per year</Typography>
										</Box>
									</Grid>
								</Grid>

								{
									data.repeatCustomers == 1 &&
									<Grid {...gridContainer}>
										<Grid {...gridColumn1}>
											<Typography sx={{ mr: 1 }}>
												<Typography sx={{ mr: 1, display: 'inline' }}>Projected sales (before adoption of suggested strategies)</Typography>
											</Typography>
										</Grid>
										<Grid {...gridColumn2}>
											<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '100%' }}>
												<NumericInput {...currencyInput} currency={currency} placeholder={currency} showInnerSpinButtons={false}
													value={beautifyToFixed(calculationResults?.increaseSales?.projectedSales?.new || 0)}
													disabled
												/>
												<Typography variant="body2" color="gray" sx={{ mt: .5, textAlign: 'right' }}>Based on total customer transactions × average value</Typography>
											</Box>
										</Grid>
									</Grid>
								}
							</Box>
						</>
					}
					
				</CardContent>
			</Card>

		</>

	);
};

export default Inputs;

export const propTypes = {
	values: PropTypes.shape({
		sales:					PropTypes.number.isRequired,
		accountsReceivable:	PropTypes.number.isRequired,
		taxRate:				PropTypes.number.isRequired,
		directCost:			PropTypes.number.isRequired,
		inventory:				PropTypes.number.isRequired,
		valuationMultiple:		PropTypes.number.isRequired,
		overheadExpenses:		PropTypes.number.isRequired,
		accountsPayable:		PropTypes.number.isRequired,
		teamMembers:			PropTypes.number.isRequired,
		otherIncome:			PropTypes.number.isRequired,
	}).isRequired,
	calculationResults: PropTypes.object.isRequired,
	onChange: PropTypes.func,
};

Inputs.propTypes = propTypes;

Inputs.defaultProps ={
	values: {
		sales:								0,
		accountsReceivable:	0,
		taxRate:							0,
		directCost:					0,
		inventory:						0,
		valuationMultiple:		0,
		overheadExpenses:		0,
		accountsPayable:			0,
		teamMembers:					0,
		otherIncome:					0,
	},
};

const gridContainer = {
	container: true,
	columnSpacing: 1,
	rowSpacing: 1,
	sx: {
		p: 1,
	},
	direction: "row",
	justifyContent: "space-between",
	alignItems: "baseline",
};

const gridColumn = {
	item: true,
	sx: {
		display: 'flex', 
		alignItems: 'center',
	},
};

const gridColumn1 = {
	...gridColumn,
	xs: 6,
	sm: 6,
	md: 6,
	lg: 6,
};

const gridColumn2 = {
	...gridColumn,
	sx: {
		...gridColumn.sx,
		justifyContent: 'end',
	},
	xs: 6,
	sm: 6,
	md: 6,
	lg: 6,
};
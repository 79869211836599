import React, { useEffect } from 'react';
import { 
	RouterProvider,
	createRoutesFromElements,
	createBrowserRouter,
	Route, 
} from 'react-router-dom';
import { RouterError } from './components';
import axios from 'axios';
import Moment from 'moment';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GAPTheme } from './style/gap-theme';
import { getAndRemoveSearchParam } from './utilities';

import CalculationList from './features/calculations/calculationList';
import { Calculations } from './features/calculations/calculations';
import preval from 'preval.macro';

import packageJSON from '../package.json';

const VERSION = packageJSON.version;
// const VERSION = require('../package.json').version;

const theme = createTheme(GAPTheme);

// Test embed mode
// const _GAP_CALC = {
// 	EMBED_MODE: true,
// 	COUNTRY: 'AU', // 'DEFAULT' | 'AU' | 'UK'
// 	ROUTE: '/firm/calculator/'
// };
// window.GAP_CALC = _GAP_CALC;

var api_url;
const GAP_CALC = window.GAP_CALC;
const embed_mode = GAP_CALC?.EMBED_MODE || false;

if (embed_mode) {
	switch (GAP_CALC?.COUNTRY?.toUpperCase()) {
		case 'DEFAULT':
			api_url = process.env.REACT_APP_API_DEFAULT;
			break;
		case 'AU':
			api_url = process.env.REACT_APP_API_AU;
			break;
		case 'UK':
			api_url = process.env.REACT_APP_API_UK;
			break;
		default:
			api_url = process.env.REACT_APP_API;
			break;
	}

} else {
	const apiEndpoint = getAndRemoveSearchParam('apiEndpoint');
	const apiEndpointFromSession = sessionStorage.getItem('apiEndpoint');

	if (apiEndpoint) {
		sessionStorage.setItem('apiEndpoint', apiEndpoint);
		api_url = apiEndpoint;
		
	} else if (apiEndpointFromSession) {
		api_url = apiEndpointFromSession;

	} else {

		const gap_country = getAndRemoveSearchParam('ctry')?.toUpperCase();

		if (gap_country) {
			sessionStorage.setItem('country', gap_country);
		}

		const country = sessionStorage.getItem('country');

		switch (country) {
			case ('AU'):
				api_url = process.env.REACT_APP_API_AU;
				break;

			case ('UK'):
				api_url = process.env.REACT_APP_API_UK;
				break;

			default:
				api_url = process.env.REACT_APP_API;
				break;
		}
	}
}

axios.defaults.baseURL = api_url;

function App() {

	useEffect(() => {
		const dev_info = `Version: ${VERSION}\nBuilt: ${Moment(preval`module.exports = new Date().getTime()`).format('D MMM, YYYY h:mm a')}`;
		console.log(dev_info);
	}, []);

	const params = {};
	new URLSearchParams(window.location?.search)?.forEach((value, key) => params[key] = value);

	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route 
				path={embed_mode ? GAP_CALC.ROUTE : '/'}
				errorElement={<RouterError />}
				{...!embed_mode && {
					loader: () => {

						// if it is running standalone, get the token from the `at` search param.
						const paramToken = getAndRemoveSearchParam('at');

						if (!paramToken && !sessionStorage.getItem('authToken')) {
							throw new Response("Unauthorised Access", { status: 401 });
						}

						if (paramToken) {
							sessionStorage.setItem('authToken', paramToken);
						}

						const token = sessionStorage.getItem('authToken');

						axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
						return true;
					}
				}}
			>
				<Route index element={
					<div>Not available</div>
				} />

				<Route path=":advisorOrgId">

					<Route index element={<div>Not available</div>} />

					<Route path=":clientOrgId">

						<Route index element={<CalculationList />} />

						{/* URL scheme 

						  * embed_mode
						  * /firm/calculator/:firm_id/:client_id?ccy=$
						  *
						  * not embed_mode
						  * /:firm_id/:client_id?ccy=$
						  *
						  */}
						<Route path=":calculationId" element={<Calculations />} />

					</Route>

				</Route>

			</Route>
		)
	);


	return (
		<ThemeProvider theme={ theme }>
			<RouterProvider router={router} />
		</ThemeProvider>
	);
}

export default App;

import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
	AppBar,
	Alert,
	Box,
	Container,
	CircularProgress,
	Badge,
	Button,
	ButtonGroup,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText,
	IconButton,
	Input,
	InputAdornment,
	InputBase,
	Toolbar,
	Typography,
	Menu, 
	MenuItem,
	ListItemIcon,
	ListItemText,
	Link as MUILink,
	Slide,
	Stack,
	Snackbar,
	Paper,
} from '@mui/material';
import { useMatches, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Moment from 'moment';
import _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import { useBeforeunload } from 'react-beforeunload';

import { 
	ArrowBack as IconArrowBack, 
	InsertDriveFile as IconFile,
	Info as IconInfo,
	Save as IconSave,
	Download as IconDownload,
	ArrowDropDown as IconArrowDropDown,
	ContentCopy as IconContentCopy,
	Edit as IconEdit,
	Close as IconClose,
} from '@mui/icons-material';

import mixpanel from "mixpanel-browser";

import { ReactComponent as Logo } from '../../asstes/logo.svg';
import { defaultCalculationTitle } from "../../constants";

import { presetDataJson, presetReportModifications } from "../../components/calculator/constants";
import {
	Calculator,
	Report,
	promiseContainer,
} from "../../components";
import { 
	loadCalculation,
	saveCalculation,
	saveAsNew,
	calculation,
	calculationStatus,
	statuses,
	saveReportModifications,
} from './calculationSlice';
import classNames from "classnames";
import { Helmet } from "react-helmet";

export const Calculations = () => {
	const matches = useMatches();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const calculationProps = useSelector(calculation);
	const status = useSelector(calculationStatus);

	const [ advisorOrgId, setAdvisorOrgId ] = useState(null);
	const [ clientOrgId, setClientOrgId ] = useState(null);
	const [ exportShow, setExportShow ] = useState(false);
	const [ PDFGenerating, setPDFGenerating ] = useState(false);

	const [ saveMenuAnchorEl, setSaveMenuAnchorEl ] = useState(null);
	const [ calculationInfo, setCalculationInfo ] = useState(default_calculation_info);
	const [ data, setData ] = useState(null);
	// const [ data, setData ] = useState({
	// 	...presetDataJson,
	// 	inputs: {
	// 		...presetDataJson?.inputs,
	// 		taxRate: calculationProps?.advisorInfo?.taxRate || 0,
	// 	},
	// });
	const [ results, setResults ] = useState({});
	const [ reportModifications, setReportModifications ] = useState();

	const inputNameRef = useRef(null);
	const currency = new URLSearchParams(document.location.search).get('ccy')?.toUpperCase() || '$';

	const calculationId = useMemo(() => {
		return matches[matches.length - 1]?.params?.calculationId?.trim();
	}, [ matches ]);

	const reportModificationUnsaved = useMemo(() => {
		return !_.isEqual(reportModifications, calculationProps?.reportModifications);
	}, [ reportModifications, calculationProps.reportModifications ]);

	const calculationUnsaved = useMemo(() => {
		const isCalculationUnsaved = (
			!_.isEqual(data, calculationProps?.calculation) ||
			calculationInfo?.name !== calculationProps?.name ||
			calculationInfo?.title !== calculationProps?.title
		);

		return isCalculationUnsaved;
	}, [ data, calculationInfo?.name, calculationInfo?.title, calculationProps ]);

	useEffect(() => {
		mixpanel.track_pageview({ page: 'calculation' });
		// mixpanel.track_links('.track-link', 'Clicked Nav Link'); // all `.track-link` click events will be tracked.
	}, []);

	useEffect(() => {
		if (exportShow) {
			mixpanel.track_pageview({ page: 'report' });
		}
	}, [ exportShow ]);

	useEffect(() => {
		loadData();
	}, [ calculationId ]);

	useBeforeunload(
		(calculationUnsaved || reportModificationUnsaved) ? (event) => event.preventDefault() : null
	);

	const beforeNavigateAway = () => {
		const goBack = () => {
			navigate(`${matches[matches?.length - 2]?.pathname}${window.location.search || ''}`, { replace: true });
		};

		if (calculationUnsaved || reportModificationUnsaved) {
			promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
				open={show}
				onClose={onDismiss}
			>
				<DialogTitle>Leave the scenario?</DialogTitle>
				<DialogContent>
					<DialogContentText>Changes that you made have not been saved.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDismiss} autoFocus>Cancel</Button>
					<Button onClick={onConfirm} color="error">Leave</Button>
				</DialogActions>
			</Dialog>
			)
				.then(() => {
					goBack();
				})
				.catch(() => { });
		} else {
			goBack();
		}
	};

	const loadData = () => {
		const params = matches[matches.length - 1]?.params;
		if (params?.advisorOrgId && params?.clientOrgId) {
			setAdvisorOrgId(params?.advisorOrgId);
			setClientOrgId(params?.clientOrgId);

			const load = () => {
				dispatch(loadCalculation({ advisorOrgId: params?.advisorOrgId, clientOrgId: params?.clientOrgId, calculationId }))
					.unwrap()
					.then(res => {
						const { calculation: resData, ...rest } = res || {};
						setData(resData || {
							...presetDataJson,
							inputs: {
								...presetDataJson?.inputs,
								taxRate: res?.advisorInfo?.taxRate ?? calculationProps?.advisorInfo?.taxRate ?? 0,
							},
						});
						setReportModifications(res?.reportModifications || presetReportModifications);
						setCalculationInfo({ ...default_calculation_info, ...rest || {} });
					})
					.catch(err => {
						promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
							open={show}
							onClose={onDismiss}
						>
							<DialogTitle>Failed to load the scenario</DialogTitle>
							<DialogContent>
								<DialogContentText>{err}</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={onDismiss}>Cancel</Button>
								<Button onClick={onConfirm} autoFocus>Retry</Button>
							</DialogActions>
						</Dialog>
						)
							.then(() => {
								load();
							})
							.catch(() => { });
					});
			};

			load();
		}
	};

	const toggleSaveMenu = e => {
		setSaveMenuAnchorEl(!saveMenuAnchorEl ? e.currentTarget : null);
	};

	const debouncedSave = _.debounce(() => {
		if(calculationUnsaved && status !== statuses.loading && status !== statuses.saving) {
			handleSave(true);
			console.log('Autosaved at', new Date());
		}
	}, 5000);

	useEffect(() => {
		debouncedSave();
		return debouncedSave.cancel;
	}, [ calculationUnsaved, data, calculationInfo?.name, calculationInfo?.title, calculationProps ]);
	
	const handleSave = (isAutosave = false) => {
		const save = () => {

			if (!isAutosave) {
				mixpanel.track('Save calculation changes (manually)');
			}

			dispatch(saveCalculation({
				advisorOrgId,
				clientOrgId,
				calculationId,
				calculation: {
					name: calculationInfo.name,
					title: calculationInfo.title || default_calculation_info.title,
					calculation: data,
				}
			}))
				.unwrap()
				.then(() => {
					promiseContainer(({ onDismiss, show }) => <Snackbar open={show} autoHideDuration={2000}
						onClose={onDismiss}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						TransitionComponent={p => <Slide {...p} direction="left" />}
					>
						<Alert onClose={onDismiss} severity="success" sx={{ width: '100%' }}>
							{ isAutosave === true ? 'Scenario saved automatically' : 'Scenario saved' }
						</Alert>
					</Snackbar>
					)
						.catch(() => { });
				})
				.catch(err => {
					if (isAutosave) {
						promiseContainer(({ onDismiss, show }) => <Snackbar open={show} autoHideDuration={isAutosave ? 5000 : 2000}
							onClose={onDismiss}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							TransitionComponent={p => <Slide {...p} direction="left" />}
						>
							<Alert onClose={onDismiss} severity="error" sx={{ width: '100%' }}>Failed to autosave the scenario. Please try again manually.</Alert>
						</Snackbar>
						)
							.catch(() => { });
					}else{
						promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
							open={show}
							onClose={onDismiss}
						>
							<DialogTitle>Failed to save the scenario</DialogTitle>
							<DialogContent>
								<DialogContentText>{err}</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={onDismiss}>Cancel</Button>
								<Button onClick={onConfirm} autoFocus>Retry</Button>
							</DialogActions>
						</Dialog>
						)
							.then(() => {
								save();
							})
							.catch(() => { });
					}
				});
		};

		if (!calculationInfo?.name?.trim()?.length) {
			promiseContainer(({ onDismiss, show }) => <Dialog
				open={show}
				onClose={onDismiss}
			>
				<DialogTitle>Warning</DialogTitle>
				<DialogContent>
					<DialogContentText>Please input the scenario name</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDismiss}>OK</Button>
				</DialogActions>
			</Dialog>
			).catch(() => {
				inputNameRef.current?.focus();
			});
		} else {
			save();
		}
	};

	const handleSaveAsNew = () => {
		mixpanel.track('Save as a new scenario');

		toggleSaveMenu();
		dispatch(saveAsNew({
			advisorOrgId,
			clientOrgId,
			calculationId,
			// data: {
			// 	name: calculationInfo.name,
			// 	title: calculationInfo.title || default_calculation_info.title,
			// }
		}))
			.unwrap()
			.then(res => {
				promiseContainer(({ onDismiss, show }) => <Snackbar open={show} autoHideDuration={2000}
					onClose={onDismiss}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					TransitionComponent={p => <Slide {...p} direction="left" />}
				>
					<Alert onClose={onDismiss} severity="success" sx={{ width: '100%' }}>
						Saved the scenario as new
					</Alert>
				</Snackbar>
				)
					.catch(() => { });

				if (res?.id) {
					navigate(`${matches[matches?.length - 2]?.pathname}/${res?.id}${window.location.search || ''}`, { replace: true });
				} else {
					navigate(`${matches[matches?.length - 2]?.pathname}${window.location.search || ''}`, { replace: true });
				}
			})
			.catch(err => {
				promiseContainer(({ onDismiss, show }) => <Dialog
					open={show}
					onClose={onDismiss}
				>
					<DialogTitle>Failed to save the scenario as new</DialogTitle>
					<DialogContent>
						<DialogContentText>{err}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onDismiss}>OK</Button>
					</DialogActions>
				</Dialog>
				)
					.catch(() => { });
			});
	};

	const debouncedSaveReportModifications = _.debounce(() => {
		if(reportModificationUnsaved && exportShow && status !== statuses.loading && status !== statuses.saving) {
			handleSaveReportModifications(true);
			console.log('Autosaved report modifications at', new Date());
		}
	}, 5000);

	useEffect(() => {
		if(exportShow) debouncedSaveReportModifications();
		return debouncedSaveReportModifications.cancel;
	}, [ reportModifications, reportModificationUnsaved, calculationProps.reportModifications ]);

	const handleSaveReportModifications = (isAutosave = false) => {
		if (!isAutosave) {
			mixpanel.track('Save report modifications (manually)');
		}

		dispatch(saveReportModifications({
			advisorOrgId,
			clientOrgId,
			calculationId,
			reportModifications,
		}))
			.unwrap()
			.then(() => {
				promiseContainer(({ onDismiss, show }) => <Snackbar open={show} autoHideDuration={2000}
					onClose={onDismiss}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					TransitionComponent={p => <Slide {...p} direction="left" />}
				>
					<Alert onClose={onDismiss} severity="success" sx={{ width: '100%' }}>
						{ isAutosave === true ? 'Report saved automatically' : 'Report saved' }
					</Alert>
				</Snackbar>
				)
					.catch(() => { });
			})
			.catch(err => {
				if (isAutosave) {
					promiseContainer(({ onDismiss, show }) => <Snackbar open={show} autoHideDuration={isAutosave ? 5000 : 2000}
						onClose={onDismiss}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						TransitionComponent={p => <Slide {...p} direction="left" />}
					>
						<Alert onClose={onDismiss} severity="error" sx={{ width: '100%' }}>Failed to autosave the report. Please try again manually.</Alert>
					</Snackbar>
					)
						.catch(() => { });
				} else {
					promiseContainer(({ onDismiss, show }) => <Dialog
						open={show}
						onClose={onDismiss}
					>
						<DialogTitle>Failed to save the report changes</DialogTitle>
						<DialogContent>
							<DialogContentText>{err}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={onDismiss}>OK</Button>
						</DialogActions>
					</Dialog>
					)
						.catch(() => { });
				}
			});
	};

	const handleExportPDF = () => {
		mixpanel.track('Download PDF');

		setPDFGenerating(true);
		const pdf_el = document.querySelector('#export-element');
		const footer_height = 16;
		const options = {
			filename: `${calculationInfo?.title || calculationInfo?.name || 'Calculation export'} - ${calculationProps?.clientInfo?.clientName} - ${Moment().format('D MMMM, YYYY')}.pdf`,
			margin: [ 4, 0, footer_height, 0 ],
			image: { type: 'jpeg', quality: .75 },
			pagebreak: {
				mode: [ 'css', 'legacy' ],
				before: 'break-before',
				after: 'break-after',
				avoid: '.avoid-page-break'
			},
			html2canvas: {
				scale: 4,
				useCORS: true,
			},
			jsPDF: {
				format: 'A4',
				orientation: 'portrait',
			},
		};

		pdf_el.querySelectorAll('.no-print').forEach(el => el.style.display = 'none');

		html2pdf()
			.from(pdf_el)
			.set(options)
			.toPdf()
			.get('pdf')
			.then(pdf => {
				pdf_el.querySelectorAll('.no-print').forEach(el => el.style.display = 'block');
				var totalPages = pdf.internal.getNumberOfPages();

				for (let i = 1; i <= totalPages; i++) {

					const txt_title = calculationInfo?.title || defaultCalculationTitle;
					const page_width = pdf.internal.pageSize.getWidth();
					const page_height = pdf.internal.pageSize.getHeight();

					pdf.setDrawColor(225);
					pdf.setLineWidth(.1);
					pdf.line(0, page_height - footer_height, page_width, page_height - footer_height);

					pdf.setPage(i);
					pdf.setFontSize(8);

					pdf.setTextColor(50);
					pdf.text(
						`${calculationInfo?.title}`, 
						8, 
						page_height - footer_height / 3 - 4,
						{
							align: 'left'
						}
					);

					pdf.setTextColor(150);
					pdf.text(
						`| ${calculationInfo?.clientInfo?.clientName}`, 
						pdf.getTextDimensions(txt_title).w + 10, 
						page_height - footer_height / 3 - 4,
						{
							align: 'left'
						}
					);
					pdf.text(
						`Page ${i} of ${totalPages}`, 
						page_width - 8, 
						page_height - footer_height / 3 - 4,
						{
							align: 'right'
						}
					);
				}
			})
			.save()
			.catch(err => {

				promiseContainer(({ onDismiss, show }) => <Dialog
					open={show}
					onClose={onDismiss}
				>
					<DialogTitle>Failed to generate the PDF</DialogTitle>
					<DialogContent>
						<DialogContentText>{err.message}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onDismiss}>OK</Button>
					</DialogActions>
				</Dialog>
				)
					.catch(() => { });
				console.err('Failed to generate the PDF.', err);
			})
			.finally(() => {
				setPDFGenerating(false);
			});
	};

	/*
	 * experimental
	 * 
	const handlePrint = (selector) => {
		const element_to_print = document.querySelector(selector).cloneNode(true);
		element_to_print.id = 'element-print';
		document.body.appendChild(element_to_print);

		const print_style = document.createElement('style');
		print_style.innerHTML = `
			@media print{

				body > *:not(#element-print){
					display: none;
				}

				body > #element-print{
					display: block;
				}
			}
		`;

		document.head.appendChild(print_style);

		window.print();
		element_to_print.remove();
		print_style.remove();
	};
	 */

	const handleExportOpen = () => {
		setExportShow(true);
	};

	const handleExportClose = () => {
		if (reportModificationUnsaved) {
			promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
				open={show}
				onClose={onDismiss}
			>
				<DialogTitle>Leave the report?</DialogTitle>
				<DialogContent>
					<DialogContentText>Changes that you made have not been saved.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDismiss} autoFocus>Cancel</Button>
					<Button onClick={onConfirm} color="error">Leave</Button>
				</DialogActions>
			</Dialog>
			)
				.then(() => {
					setExportShow(false);
				})
				.catch(() => { });
		} else {
			setExportShow(false);
		}
	};

	return (
		<>

			<Helmet>
				<title>Value Gap Calculator - { calculationInfo.title || defaultCalculationTitle }</title>
			</Helmet>

			<Dialog
				fullScreen
				open={exportShow}
				onClose={handleExportClose}
				TransitionComponent={Transition}
			>
				<Stack direction="column" flex={1}>

					<AppBar sx={{ position: 'relative', flexGrow: 0 }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={handleExportClose}
								aria-label="close"
							>
								<IconClose />
							</IconButton>

							<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Report</Typography>

							<Box>

								<Badge color="warning" variant="dot" invisible={!reportModificationUnsaved || status === statuses.loading}>
									<Button color="inherit" onClick={() => handleSaveReportModifications()} disabled={status === statuses.saving}>
										<IconSave size="small" sx={{ mr: 1 }} />
										Save Changes
									</Button>
								</Badge>

								<Button color="inherit" onClick={handleExportPDF} disabled={PDFGenerating}>
									{
										PDFGenerating ?
											<CircularProgress size={24} color="white" />
											:
											<>
												<IconDownload size="small" sx={{ mr: 1 }} />
												Download PDF
											</>
									}
								</Button>

							</Box>
						</Toolbar>
					</AppBar>

					<Box sx={{ flexGrow: 1, backgroundColor: 'gray', width: '100%', height: '100%', position: 'relative' }}>
						<Box sx={{ position: 'absolute', width: '100%', height: '100%', overflowY: 'auto' }}>
							<Container maxWidth="md" sx={{ my: 4 }}>
								<Paper elevation={8} square>
									<div id="export-element">
										<Report
											currency={currency}
											calculationInfo={calculationInfo}
											calculation={data}
											calculationResults={results}
											readOnly={PDFGenerating}
											reportModifications={reportModifications || presetReportModifications}
											onModificationChange={val => setReportModifications(val)}
										/>
									</div>
								</Paper>
							</Container>
						</Box>
					</Box>

				</Stack>

			</Dialog>

			<Stack direction="column" sx={{ height: '100%' }}>

				<Box sx={{ flexGrow: 0, flexShrink: 0, zIndex: 1200 }}>
					<AppBar sx={{ position: 'static' }} color="white" elevation={0}>
						<Toolbar variant="dense">
							<Stack sx={{ flexGrow: 1 }} direction="row" alignItems="center">
								<IconButton
									edge="start"
									color="inherit"
									// component={Link}
									sx={{ flexGrow: 0 }}
									// to={`${matches[matches.length - 2].pathname}${window.location.search || ''}`}
									onClick={beforeNavigateAway}
								>
									<IconArrowBack />
								</IconButton>

								{
									status !== statuses.loading &&
									<Stack sx={{ flexGrow: 1 }}>
										<InputBase
											inputRef={inputNameRef}
											autoFocus
											sx={{ fontWeight: 900, borderRadius: 1 }}
											startAdornment={
												<InputAdornment position="start" sx={{ pl: 1 }}>
													<IconEdit fontSize="small" />
												</InputAdornment>
											}
											error={!calculationInfo?.name?.trim()?.length}
											placeholder="Input calculation title"
											value={calculationInfo?.name}
											onChange={e => {
												setCalculationInfo(prevState => {
													prevState = {
														...prevState,
														name: e.target.value,
													};
													return prevState;
												});
											}}
										/>
									</Stack>
								}
							</Stack>

							<Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>

								<MUILink href="https://help.thegaphq.com/portal/en/kb/articles/value" target="_blank"
									onClick={ () => mixpanel.track('Read Guide (via calculation details)') }
								>
									<Button variant="outlined" size="small" sx={{ ml: 1, borderRadius: '4rem' }}>
										<IconInfo size="small" sx={{ mr: 1 }} />
										Read Guide
									</Button>
								</MUILink>

								<Button variant="outlined" color="primary" size="small" sx={{ ml: 1, borderRadius: '4rem' }} disabled={status === statuses.loading} onClick={handleExportOpen}>
									<IconFile size="small" sx={{ mr: 1 }} />
									Generate Report
								</Button>

								<Badge color="warning" variant="dot" invisible={!calculationUnsaved || status === statuses.loading}>
									<ButtonGroup variant="contained" color="primary" aria-label="Save" sx={{ ml: 1, borderRadius: '4rem' }} disabled={status === statuses.loading || status === statuses.saving}>

										<Button size="small" onClick={() => handleSave()} sx={{ borderRadius: '4rem' }}>
											{
												status === statuses.saving ?
													<CircularProgress size={24} color="primary" sx={{ mr: 1 }} />
													:
													<IconSave size="small" sx={{ mr: 1 }} />
											}
											Save Scenario
										</Button>

										<Button
											size="small"
											aria-controls={saveMenuAnchorEl ? 'split-button-menu' : undefined}
											aria-expanded={saveMenuAnchorEl ? 'true' : undefined}
											aria-label="more saving options"
											aria-haspopup="menu"
											sx={{ px: 0, borderRadius: '4rem' }}
											onClick={toggleSaveMenu}
										>
											<IconArrowDropDown />
										</Button>
										<Menu
											anchorEl={saveMenuAnchorEl}
											open={Boolean(saveMenuAnchorEl)}
											onClose={toggleSaveMenu}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
										>
											<MenuItem disabled={status === statuses.saving || status === statuses.loading} onClick={handleSaveAsNew}>
												<ListItemIcon><IconContentCopy fontSize="small" /></ListItemIcon>
												<ListItemText>Save as a new scenario</ListItemText>
											</MenuItem>
										</Menu>
									</ButtonGroup>
								</Badge>
							</Box>

						</Toolbar>
					</AppBar>
				</Box>

				<Box sx={{ flexGrow: 1, flexShrink: 1, position: 'relative' }}>
					<Box sx={{ position: 'absolute', width: '100%', height: '100%', overflow: 'auto' }}>

						{
							status !== statuses.loading &&
							<Container maxWidth="xl" sx={{ flexGrow: 0 }}>
								<Box sx={{
									display: {
										lg: 'flex',
										md: 'block',
									},
									justifyContent: 'start',
									mt: 5,
									mb: 5,
								}}>
									<Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} sx={{ width: '100%', minHeight: 96 }}>

										<TitleInput style={{ flexGrow: 1 }}>
											<Input
												// autoFocus
												fullWidth
												sx={{ color: 'primary.dark', fontSize: 48, fontWeight: 700 }}
												// startAdornment={
												// 	<InputAdornment position="start" sx={{ pl: 1 }}>
												// 		<IconEdit fontSize="48px" />
												// 	</InputAdornment>
												// }
												error={!calculationInfo?.title?.trim()?.length}
												placeholder="Input calculation title"
												value={calculationInfo?.title}
												onChange={e => {
													setCalculationInfo(prevState => {
														prevState = {
															...prevState,
															title: e.target.value,
														};
														return prevState;
													});
												}}
											/>
										</TitleInput>

										{
											!!calculationProps?.advisorInfo?.logo === true ?
												<img src={calculationProps?.advisorInfo?.logo} style={{ maxWidth: 240, height: 96, objectFit: 'contain' }} />
												:
												<Logo className="gap-logo" width="240" />
										}

									</Stack>
								</Box>
							</Container>
						}

						{
							(status === statuses.loading || !data) ?
								<Stack alignItems="center" justifyContent="center" sx={{ height: '100%', flexGrow: 1 }}>
									<CircularProgress size={32} />
								</Stack>
								:
								<Box className={classNames({ 'disabled': status === statuses.loading })}>
									<Calculator
										currency={currency}
										calculation={data}
										defaultTaxRate={calculationProps?.advisorInfo?.taxRate || 0}
										onDataChange={setData}
										// onResultChange={data => console.log('results', data)}
										onResultChange={setResults}
									/>
								</Box>
						}

					</Box>
				</Box>
			</Stack>

		</>
	);
};

const default_calculation_info = {
	id: "",
	name: "",
	title: defaultCalculationTitle,
};

const TitleInput = styled.div`
.MuiInput-root::before{
	border-bottom-color: transparent;
}
.MuiInput-root:hover:not(.Mui-disabled, .Mui-error)::before{
	border-bottom-color: rgba(0, 0, 0, .1);
}
`;

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
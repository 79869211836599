import React from 'react';
import { useRouteError } from 'react-router-dom';
import {
	Container,
} from 'reactstrap';
import { ReactComponent as Logo } from '../asstes/logo.svg';

const RouterError: React.FC = () => {
	const error: unknown = useRouteError();

	return (
		<Container className="h-100 d-flex align-items-center justify-content-center">
			<div className="d-flex flex-column align-items-center">
				<Logo className="gap-logo mb-4" width="240" />
				<h5 className="text-center text-danger fw-light">
					{
						error?.status ?
							`${error?.data} (${error?.status})`
							: 'Oops! The page was dropped into the gap.'
					}
					
				</h5>
			</div>
		</Container>
	);
};

export default RouterError;
import React, { useState, useMemo, useEffect, PropsWithChildren } from 'react';
import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Moment from 'moment';
import thousands from 'thousands';
import _ from 'lodash';

import {
	Checkbox,
	FormControlLabel,
	TextareaAutosize,
} from '@mui/material';

// import { 
// 	InfoOutlined as IconInfo,
// } from '@mui/icons-material';

import { beautifyToFixed, addPlusMinusToNumber, clampNumber } from "../../utilities";
import { ReactComponent as Logo } from '../../asstes/logo.svg';
import { defaultCalculationTitle, pieChartOptions } from '../../constants';
import { presetReportModifications } from './constants';

export interface IReportModifications {
	reportModifications: TCalculationReportModifications;
	calculationInfo: TCalculationDetails;
	calculation: TCalculationData;
	calculationResults: TCalculationResults;
	currency: string;
	readOnly: boolean;
	// eslint-disable-next-line no-unused-vars
	onModificationChange: (...args: unknown[]) => unknown;
};

const Report: React.FC<PropsWithChildren<IReportModifications>> = (props) => {
	const [ modifications, setModifications ] = useState(props.reportModifications);
	const { calculationInfo, currency } = props || {};
	const { inputs, profit, cashflow, returnOnInvestment } = props.calculation || {};
	const {
		increaseSales,
		increaseCashflow,
		increaseProfit,
		improveROI,
		improveGrossMargin,
		totalImpact,
		potentialValueImpact,
	} = props?.calculationResults || {};

	const {
		sales,
		retentionRate,
		retainedCustomers,
		estimatedNewCustomers,
		transactionsPerClientPerYear,
		averageTransactionValue,
		leadsPerYear,
		leadConversionRate,
		newCustomers,
		totalCustomers,
		changeSales,
	} = increaseSales || {};
	const { debtorDays, inventoryDays, payableDays, cashConversionCycle, impact } = increaseCashflow || {};
	const { grossMargin, grossProfit, directCost } = improveGrossMargin || {};
	const { overheadExpenses, netProfit, netMargin, value, clientBase } = increaseProfit || {};
	const { revenueByTeamMember, currentEffectiveRate } = improveROI || {};

	const repeatCustomers = inputs?.repeatCustomers == 1 ? true : false;
	const knownLeadGeneration = inputs?.knownLeadGen == 1 ? true : false;
	const detailedSalesDrivers = profit?.detailedSalesDrivers == 1 ? true : false;

	const pieDataCash = useMemo(() => {
		return {
			labels: [
				'Improve cash conversion',
				'Increase sales',
				'Increase gross margin',
				'Reduce overheads/increase investment',
				'Increase ROI',
			],
			datasets: [
				{
					label: currency,
					data: [
						clampNumber(increaseCashflow?.impact?.cash?.toFixed() || 0, 0),
						clampNumber(increaseSales?.sales?.impact?.cash?.toFixed() || 0, 0),
						clampNumber(improveGrossMargin?.impact?.cash?.toFixed() || 0, 0),
						clampNumber(increaseProfit?.impact?.cash?.toFixed() || 0, 0),
						clampNumber(improveROI?.impact?.cash?.toFixed() || 0, 0),
					],
					backgroundColor: [
						'rgb(51,20,53)',
						'rgb(173,163,214)',
						'rgb(181,225,206)',
						'rgb(22,59,68)',
						'rgb(239,103,55)',
					],
					borderColor: "white",
					borderWidth: 3,
				},
			],
		};
	}, [ props.calculationResults ]);

	const pieDataImpact = useMemo(() => {
		return {
			labels: [
				'Improve cash conversion',
				'Increase sales',
				'Increase gross margin',
				'Reduce overheads/increase investment',
				'Increase ROI',
			],
			datasets: [
				{
					label: currency,
					data: [
						0,
						clampNumber(increaseSales?.sales?.impact?.profit?.toFixed() || 0, 0),
						clampNumber(improveGrossMargin?.impact?.profit?.toFixed() || 0, 0),
						clampNumber(increaseProfit?.impact?.profit?.toFixed() || 0, 0),
						clampNumber(improveROI?.impact?.profit?.toFixed() || 0, 0),
					],
					backgroundColor: [
						'rgb(51,20,53)',
						'rgb(173,163,214)',
						'rgb(181,225,206)',
						'rgb(22,59,68)',
						'rgb(239,103,55)',
					],
					borderColor: "white",
					borderWidth: 3,
				},
			],
		};
	}, [ props.calculationResults ]);

	const debtorDaysChanged = useMemo(() => cashflow?.debtorDaysChangeBy < 0 || cashflow?.debtorDaysChangeBy > 0, [ cashflow?.debtorDaysChangeBy ]);
	const inventoryDaysChanged = useMemo(() => cashflow?.inventoryDaysChangeBy < 0 || cashflow?.inventoryDaysChangeBy > 0, [ cashflow?.inventoryDaysChangeBy ]);
	const payableDaysChanged = useMemo(() => cashflow?.payableDaysChangeBy < 0 || cashflow?.payableDaysChangeBy > 0, [ cashflow?.payableDaysChangeBy ]);

	const grossMarginChanged = useMemo(() => profit?.grossMarginChangeBy < 0 || profit?.grossMarginChangeBy > 0, [ profit?.grossMarginChangeBy ]);
	const netProfitChanged = useMemo(() => profit?.overheadExpensesChangeBy < 0 || profit?.overheadExpensesChangeBy > 0, [ profit?.overheadExpensesChangeBy ]);

	const salesChanged = useMemo(() => profit?.salesChangeBy < 0 || profit?.salesChangeBy > 0, [ profit?.salesChangeBy ]);
	const numberOfCustomersChanged = useMemo(() => profit?.clientBaseChangeBy < 0 || profit?.clientBaseChangeBy > 0, [ profit?.clientBaseChangeBy ]);
	const retentionRateChanged = useMemo(() => profit?.retentionRateChangeBy < 0 || profit?.retentionRateChangeBy > 0, [ profit?.retentionRateChangeBy ]);
	const newCustomersChanged = useMemo(() => profit?.estimatedNewCustomersChangeBy < 0 || profit?.estimatedNewCustomersChangeBy > 0, [ profit?.estimatedNewCustomersChangeBy ]);
	const leadsPerYearChanged = useMemo(() => profit?.leadsPerYearChangeBy < 0 || profit?.leadsPerYearChangeBy > 0, [ profit?.leadsPerYearChangeBy ]);
	const leadConversionChanged = useMemo(() => profit?.leadConversionRateChangeBy < 0 || profit?.leadConversionRateChangeBy > 0, [ profit?.leadConversionRateChangeBy ]);
	const transactionsPerClientPerYearChanged = useMemo(() => profit?.transactionsPerClientPerYearChangeBy < 0 || profit?.transactionsPerClientPerYearChangeBy > 0, [ profit?.averageTransactionValueChangeBy ]);
	const averageTransactionValueChanged = useMemo(() => profit?.averageTransactionValueChangeBy < 0 || profit?.averageTransactionValueChangeBy > 0, [ profit?.averageTransactionValueChangeBy ]);

	const returnOnInvestmentChanged = useMemo(() => returnOnInvestment?.revenueByTeamMemberChangeBy < 0 || returnOnInvestment?.revenueByTeamMemberChangeBy > 0, [ returnOnInvestment?.revenueByTeamMemberChangeBy ]);

	useEffect(() => {
		if (!_.isEqual(modifications, props.reportModifications)) {
			setModifications(props.reportModifications);
		}
	}, [ props.reportModifications ]);

	const debouncedOnModificationChange = _.debounce(() => {
		if (props.onModificationChange instanceof Function) {
			props.onModificationChange(modifications);
		}
	}, 500);

	useEffect(() => {
		if (props.onModificationChange instanceof Function) {
			props.onModificationChange(modifications);
		}
		debouncedOnModificationChange();
		return debouncedOnModificationChange.cancel;
	}, [ modifications ]);

	const toggleSection = (section) => {
		if (section) {
			
			setModifications(prevState => ({
				...prevState,
				sections: {
					...prevState.sections || {},
					[section]: !modifications.sections?.[section],
				},
			}));
		}
	};

	const onEditableChange = (field, e) => {
		const val = e.target.value || '';
		setModifications(prevState => ({
			...prevState,
			fields: {
				...prevState.fields,
				[field]: val,
			},
		}));
	};

	const createMarkup = (html = '') => ({ __html: html });

	/**
	 * Wrap paragraphs into `<p>` tags
	 * @param {string} text 
	 * @returns {string}
	 */
	const convertLinebreakToP = (text = '') => {
		const reg = /(.+\n|.+)/gm;
		const result = text.match(reg) || [];
		let html = '';

		result.forEach((item, key) => {
			html += `<p${key === result.length - 1 ? ' class="mb-0"' : ''}>${item}</p>`;
		});
		
		return html;
	};

	return (
		<>
			<Helmet>
				<style type="text/css">{`
					.page{
						font-size: 12px;
					}
					.border-dashed{
						border-style: dashed;
					}
					textarea{
						border: none;
						background: none;
					}
					*[contenteditable], textarea{
						outline: dashed 1px var(--bs-blue);
						border-radius: 1px;
						transition: outline-offset ease 150ms;
					}
					*[contenteditable]:focus, textarea:focus{
						outline: solid 2px var(--bs-blue);
						outline-offset: 2px;
					}

					.break-before {
						page-break-before: always;
					}
					.break-after {
						page-break-after: always;
					}
					.avoid-break {
						page-break-inside: avoid;
					}

					@media print{
						.no-print, .no-print *{
							display: none !important;
						}
					}
				`}</style>
			</Helmet>

			<div className="page">

				<div>
					<div className="d-flex align-items-top justify-content-between p-5">
						<div className="d-flex flex-column flex-grow-1 flex-shrink-1 pe-4">
							<h1 className="mb-3 fw-bold">{calculationInfo?.title || defaultCalculationTitle}</h1>
							<h6 className="text-secondary fw-bold mb-1">Prepared for {calculationInfo?.clientInfo?.clientName || '-'} on {Moment().format('D MMMM, YYYY')}</h6>
							{
								props.readOnly ?
									<div className="text-secondary" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.reportIntro || ''))} />
									:
									<div className="text-secondary">
										<TextareaAutosize
											style={textareaAutoSizeStyle}
											onChange={e => onEditableChange('reportIntro', e)}
											value={ modifications?.fields?.reportIntro ?? (presetReportModifications?.fields?.reportIntro || '') }
										/>
									</div>
							}
						</div>

						<div className="d-flex flex-column flex-grow-0 flex-shrink-0">
							{
								!!calculationInfo?.advisorInfo?.logo === true ?
									<img src={calculationInfo?.advisorInfo?.logo} style={{ width: '14rem', height: 'auto', objectFit: 'contain' }} />
									:
									<Logo className="gap-logo" style={{ width: '12rem' }} />
							}
						</div>
					</div>
				</div>

				<div className="bg-light px-5 pb-5">

					{/* impact summary */}
					<div className="pt-4 avoid-break">
						<h5 className="text-bold my-0">Impact Summary</h5>

						<Table className="table avoid-break">
							<thead>
								<tr>
									<th className="border-0"></th>
									<th className="border-0 text-end">Cashflow</th>
									<th className="border-0 text-end">Profit</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Improve cash conversion</td>
									<td className="text-end">{addPlusMinusToNumber(beautifyToFixed(increaseCashflow?.impact?.cash || 0), true, currency)}</td>
									<td className="text-end">-</td>
								</tr>

								<tr>
									<td>Increase sales</td>
									<td className="text-end">{addPlusMinusToNumber(beautifyToFixed(increaseSales?.sales?.impact?.cash || 0), true, currency)}</td>
									<td className="text-end">{addPlusMinusToNumber(beautifyToFixed(increaseSales?.sales?.impact?.profit || 0), true, currency)}</td>
								</tr>

								<tr>
									<td>Increase gross margin</td>
									<td className="text-end">{addPlusMinusToNumber(beautifyToFixed(improveGrossMargin?.impact?.cash || 0), true, currency)}</td>
									<td className="text-end">{addPlusMinusToNumber(beautifyToFixed(improveGrossMargin?.impact?.profit || 0), true, currency)}</td>
								</tr>

								<tr>
									<td>Reduce overheads/increase investment</td>
									<td className="text-end">{addPlusMinusToNumber(beautifyToFixed(increaseProfit?.impact?.cash || 0), true, currency)}</td>
									<td className="text-end">{addPlusMinusToNumber(beautifyToFixed(increaseProfit?.impact?.profit || 0), true, currency)}</td>
								</tr>

								<tr>
									<td>Increase ROI</td>
									<td className="text-end">{addPlusMinusToNumber(beautifyToFixed(improveROI?.impact?.cash || 0), true, currency)}</td>
									<td className="text-end">{addPlusMinusToNumber(beautifyToFixed(improveROI?.impact?.profit || 0), true, currency)}</td>
								</tr>

								<tr>
									<td className="border-0 text-start fw-bold">Total</td>
									<td className="border-0 text-end fw-bold">{addPlusMinusToNumber(beautifyToFixed(totalImpact?.cash || 0), true, currency)}</td>
									<td className="border-0 text-end fw-bold">{addPlusMinusToNumber(beautifyToFixed(totalImpact?.profit || 0), true, currency)}</td>
								</tr>

								<tr>
									<td className="border-0 text-start fw-bold">Potential business value impact</td>
									<td className="border-0 text-end fw-bold"></td>
									<td className="border-0 text-end fw-bold">{addPlusMinusToNumber(beautifyToFixed(potentialValueImpact?.profit || 0), true, currency)}</td>
								</tr>

							</tbody>
						</Table>
					</div>

					{/* pie charts */}
					<div className="container-fluid px-0 pt-3 avoid-break">
						<div className="row">

							<div className="col-6">

								<div className="card shadow rounded-4">
									<div className="card-body">
										<div className="d-flex align-items-center justify-content-between">
											<div className="card-title fw-bold">Total Cash Impact</div>
											<h5>{addPlusMinusToNumber(beautifyToFixed(totalImpact?.cash || 0), true, currency)}</h5>
										</div>
										<div className="d-flex justify-content-center" style={{ height: '18rem', width: '100%' }}>
											<Pie options={pieChartOptions} data={pieDataCash} style={{ width: '100%' }} />
										</div>
									</div>
								</div>

							</div>

							<div className="col-6">

								<div className="card shadow rounded-4">
									<div className="card-body">
										<div className="d-flex align-items-center justify-content-between">
											<div className="card-title fw-bold">Total Profit Impact</div>
											<h5>{addPlusMinusToNumber(beautifyToFixed(totalImpact?.profit || 0), true, currency)}</h5>
										</div>
										<div className="d-flex justify-content-center" style={{ height: '18rem', width: '100%' }}>
											<Pie options={pieChartOptions} data={pieDataImpact} style={{ width: '100%' }} />
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>
				</div>

			</div>


			{/* cashflow */}
			{
				!props.readOnly &&
				<div className="no-print p-3">
					<FormControlLabel
						control={<Checkbox onChange={() => toggleSection(sections.cashflow)} checked={modifications.sections?.[sections.cashflow]} />}
						label="Show Cash Conversion"
						className="px-2 d-flex align-items-center"
					/>
				</div>
			}
			{
				modifications.sections?.[sections.cashflow] && (
					<div className="page px-5 pb-4">

						<div className="avoid-break">
							<div className="d-flex align-items-center justify-content-between pt-4">
								<h5 className="text-bold">Cash Conversion</h5>
								{
									(debtorDaysChanged || inventoryDaysChanged || payableDaysChanged) &&
									<h6 className={`text-end ${impact?.cash > 0 ? 'text-success' : 'text-danger'}`}>{addPlusMinusToNumber(beautifyToFixed(impact?.cash), true, currency)} Cash Impact</h6>
								}
							</div>

							<div className="pt-3 avoid-break">
								<div className={`card rounded-3 h-100 ${debtorDaysChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
									<div className="card-body p-4">
										{
											debtorDaysChanged ?
												<div className="d-flex align-items-center justify-content-between mb-2">
													{
														cashflow?.debtorDaysChangeBy < 0 &&
														<h6 className="my-0">Reduce debtor days by {Math.abs(cashflow?.debtorDaysChangeBy)}</h6>
													}
													{
														cashflow?.debtorDaysChangeBy > 0 &&
														<h6 className="my-0">Increase debtor days by {Math.abs(cashflow?.debtorDaysChangeBy)}</h6>
													}
													<strong className="text-end">{currency}{thousands(beautifyToFixed(Math.abs(debtorDays?.impact?.cash)))} {debtorDays?.impact?.cash < 0 && 'required'}{debtorDays?.impact?.cash > 0 && 'freed up'}</strong>
												</div>
												:
												<h6 className="text-black-50">Debtor days</h6>
										}

										<div className="d-flex flex-column">
											{
												(debtorDays?.current || 0) === (debtorDays?.new || 0) ?
													<small className="text-black-50">Debtor days are currently {thousands(beautifyToFixed(debtorDays?.current || 0))} days</small>
													:
													<small className="text-black-50">Change debtor days from {thousands(beautifyToFixed(debtorDays?.current || 0))} to <strong>{thousands(beautifyToFixed(debtorDays?.new || 0))} days</strong></small>
											}
										</div>

										{
											debtorDaysChanged &&
											<>
												{
													props.readOnly ?
														<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.debtorDayChangeIntro || ''))} />
														:
														<div className="mt-3">
															<TextareaAutosize
																style={textareaAutoSizeStyle}
																onChange={e => onEditableChange('debtorDayChangeIntro', e)}
																value={ modifications?.fields?.debtorDayChangeIntro ?? (presetReportModifications?.fields?.debtorDayChangeIntro || '') }
															/>
														</div>
												}
											</>
										}
									</div>
								</div>
							</div>
						</div>

						<div className="pt-3 avoid-break">
							<div className={`card rounded-3 h-100 ${inventoryDaysChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
								<div className="card-body p-4">
									{
										inventoryDaysChanged ?
											<div className="d-flex align-items-center justify-content-between mb-2">
												{
													cashflow?.inventoryDaysChangeBy < 0 &&
													<h6 className="my-0">Reduce inventory/WIP days by {Math.abs(cashflow?.inventoryDaysChangeBy)} days</h6>
												}
												{
													cashflow?.inventoryDaysChangeBy > 0 &&
													<h6 className="my-0">Increase inventory/WIP days by {Math.abs(cashflow?.inventoryDaysChangeBy)} days</h6>
												}
												<strong className="text-end">{currency}{thousands(beautifyToFixed(Math.abs(inventoryDays?.impact?.cash)))} {inventoryDays?.impact?.cash < 0 && 'required'}{inventoryDays?.impact?.cash > 0 && 'freed up'}</strong>
											</div>
											:
											<h6 className="text-black-50">Inventory/WIP days</h6>
									}

									<div className="d-flex flex-column">
										{
											inventoryDays?.current === inventoryDays?.new ?
												<small className="text-black-50">Inventory/WIP days are currently {thousands(beautifyToFixed(inventoryDays?.current))} days</small>
												:
												<small className="text-black-50">Change inventory/WIP days from {thousands(beautifyToFixed(inventoryDays?.current))} to <strong>{thousands(beautifyToFixed(inventoryDays?.new))} days</strong></small>
										}
									</div>

									{
										inventoryDaysChanged &&
										<>
											{
												props.readOnly ?
													<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.inventoryDayChangeIntro || ''))} />
													:
													<div className="mt-3">
														<TextareaAutosize
															style={textareaAutoSizeStyle}
															onChange={e => onEditableChange('inventoryDayChangeIntro', e)}
															value={ modifications?.fields?.inventoryDayChangeIntro ?? (presetReportModifications?.fields?.inventoryDayChangeIntro || '') }
														/>
													</div>
											}
										</>
									}
								</div>
							</div>
						</div>

						<div className="pt-3 avoid-break">
							<div className={`card rounded-3 h-100 ${payableDaysChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
								<div className="card-body p-4">
									{
										payableDaysChanged ?
											<div className="d-flex align-items-center justify-content-between mb-2">
												{
													cashflow?.payableDaysChangeBy < 0 &&
													<h6 className="my-0">Reduce payable days by {Math.abs(cashflow?.payableDaysChangeBy)} days</h6>
												}
												{
													cashflow?.payableDaysChangeBy > 0 &&
													<h6 className="my-0">Increase payable days by {Math.abs(cashflow?.payableDaysChangeBy)} days</h6>
												}
												<strong className="text-end">{currency}{thousands(beautifyToFixed(Math.abs(payableDays?.impact?.cash)))} {payableDays?.impact?.cash < 0 && 'freed up'}{payableDays?.impact?.cash > 0 && 'required'}</strong>
											</div>
											:
											<h6 className="text-black-50">Payable days</h6>
									}

									<div className="d-flex flex-column">
										{
											payableDays?.current === payableDays?.new ?
												<small className="text-black-50">Payable days are currently {thousands(beautifyToFixed(payableDays?.current))} days</small>
												:
												<small className="text-black-50">Change payable days from {thousands(beautifyToFixed(payableDays?.current))} to <strong>{thousands(beautifyToFixed(payableDays?.new))} days</strong></small>
										}
									</div>

									{
										payableDaysChanged &&
										<>
											{
												props.readOnly ?
													<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.payableDayChangeIntro || ''))} />
													:
													<div className="mt-3">
														<TextareaAutosize
															style={textareaAutoSizeStyle}
															onChange={e => onEditableChange('payableDayChangeIntro', e)}
															value={ modifications?.fields?.payableDayChangeIntro ?? (presetReportModifications?.fields?.payableDayChangeIntro || '') }
														/>
													</div>
											}
										</>
									}
								</div>
							</div>
						</div>

						<div className="pt-3 avoid-break">

							<div className="d-flex flex-row">
								{/*
								<IconInfo fontSize="small" className="me-2 no-print" />
								*/}
								<div>
									<div>Cash conversion cycle is calculated as average debtor days + inventory/WIP days - payable days. </div>
									{
										(cashConversionCycle?.current || 0) === (cashConversionCycle?.new || 0) ?
											<div>Current cash conversion cycle is {thousands(beautifyToFixed(cashConversionCycle?.current || 0))} days</div>
											:
											<div>Change cash conversion cycle from {thousands(beautifyToFixed(cashConversionCycle?.current || 0))} to <strong>{thousands(beautifyToFixed(cashConversionCycle?.new || 0))} days</strong></div>
									}
								</div>
							</div>

						</div>

					</div>
				)
			}


			{/* sales */}
			{
				!props.readOnly &&
				<div className="no-print p-3">
					<FormControlLabel
						control={<Checkbox onChange={() => toggleSection(sections.profit)} checked={modifications.sections?.[sections.profit]} />}
						label="Show Profit"
						className="px-2 d-flex align-items-center no-print"
					/>
				</div>
			}
			{
				modifications.sections?.[sections.profit] && (
					<>

						<div className="page px-5 pb-4 bg-light">

							<div className="avoid-break">
								<div className="d-flex align-items-baseline justify-content-between pt-4">
									<h5 className="text-bold">Sales</h5>
									<div>
										{
											(sales?.impact?.cash < 0 || sales?.impact?.cash > 0) &&
											<h6 className={`text-end ${sales?.impact?.cash > 0 ? 'text-success' : 'text-danger'}`}>{addPlusMinusToNumber(beautifyToFixed(sales?.impact?.cash), true, currency)} Cash Impact</h6>
										}
										{
											(sales?.impact?.profit < 0 || sales?.impact?.profit > 0) &&
											<h6 className={`text-end ${sales?.impact?.profit > 0 ? 'text-success' : 'text-danger'}`}>{addPlusMinusToNumber(beautifyToFixed(sales?.impact?.profit), true, currency)} Profit Impact</h6>
										}
									</div>
								</div>

								{
									!detailedSalesDrivers && <>
										<div className="pt-3 avoid-break">
											<div className={`card rounded-3 h-100 ${salesChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
												<div className="card-body p-4">
													{
														salesChanged ?
															<div className="d-flex align-items-center justify-content-between mb-2">
																{
																	profit?.salesChangeBy < 0 &&
																	<h6 className="my-0">Decrease sales by {thousands(beautifyToFixed(Math.abs(profit?.salesChangeBy) * 100))}%</h6>
																}
																{
																	profit?.salesChangeBy > 0 &&
																	<h6 className="my-0">Increase sales by {thousands(beautifyToFixed(Math.abs(profit?.salesChangeBy) * 100))}%</h6>
																}
																<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(sales?.diff), true, currency)} sales</strong>
															</div>
															:
															<h6 className="text-black-50">Sales</h6>
													}

													<div className="d-flex flex-column">
														{
															sales?.current === sales?.new ?
																<small className="text-black-50">Sales are currently {currency}{thousands(beautifyToFixed(sales?.current, 2))}</small>
																:
																<small className="text-black-50">Change sales from {currency}{thousands(beautifyToFixed(sales?.current, 2))} to <strong>{currency}{thousands(beautifyToFixed(sales?.new, 2))}</strong></small>
														}
													</div>

													{
														salesChanged &&
														<>
															{
																props.readOnly ?
																	<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.saleChangeIntro || ''))} />
																	:
																	<div className="mt-3">
																		<TextareaAutosize
																			style={textareaAutoSizeStyle}
																			onChange={e => onEditableChange('saleChangeIntro', e)}
																			value={ modifications?.fields?.saleChangeIntro ?? (presetReportModifications?.fields?.saleChangeIntro || '') }
																		/>
																	</div>
															}
														</>
													}
												</div>
											</div>
										</div>
									</>
								}
							</div>

							{
								detailedSalesDrivers && <>

									{
										!repeatCustomers && !knownLeadGeneration && <>

											<div className="pt-3 avoid-break">
												<div className={`card rounded-3 h-100 ${numberOfCustomersChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
													<div className="card-body p-4">
														{
															numberOfCustomersChanged ?
																<div className="d-flex align-items-center justify-content-between mb-2">
																	{
																		profit?.clientBaseChangeBy < 0 &&
																		<h6 className="my-0">Decrease number of customers by {thousands(beautifyToFixed(Math.abs(profit?.clientBaseChangeBy) * 100))}%</h6>
																	}
																	{
																		profit?.clientBaseChangeBy > 0 &&
																		<h6 className="my-0">Increase number of customers by {thousands(beautifyToFixed(Math.abs(profit?.clientBaseChangeBy) * 100))}%</h6>
																	}
																	<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(clientBase?.diff))} new customers</strong>
																</div>
																:
																<h6 className="text-black-50">Number of customers</h6>
														}

														<div className="d-flex flex-column">
															{
																clientBase?.current === clientBase?.new ?
																	<small className="text-black-50">Client base is currently {thousands(beautifyToFixed(clientBase?.current, 2))}</small>
																	:
																	<small className="text-black-50">Change client base from {thousands(beautifyToFixed(clientBase?.current))} to <strong>{thousands(beautifyToFixed(clientBase?.new))}</strong></small>
															}
														</div>

														{
															numberOfCustomersChanged &&
															<>
																{
																	props.readOnly ?
																		<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.numberOfCustomerChangeIntro || ''))} />
																		:
																		<div className="mt-3">
																			<TextareaAutosize
																				style={textareaAutoSizeStyle}
																				onChange={e => onEditableChange('numberOfCustomerChangeIntro', e)}
																				value={ modifications?.fields?.numberOfCustomerChangeIntro ?? (presetReportModifications?.fields?.numberOfCustomerChangeIntro || '') }
																			/>
																		</div>
																}
															</>
														}
													</div>
												</div>
											</div>

										</>
									}

									{
										repeatCustomers && <>

											<div className="pt-3 avoid-break">
												<div className={`card rounded-3 h-100 ${retentionRateChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
													<div className="card-body p-4">
														{
															retentionRateChanged ?
																<div className="d-flex align-items-center justify-content-between mb-2">
																	{
																		profit?.retentionRateChangeBy < 0 &&
																		<h6 className="my-0">Decrease retention rate by {thousands(beautifyToFixed(Math.abs(profit?.retentionRateChangeBy) * 100))}%</h6>
																	}
																	{
																		profit?.retentionRateChangeBy > 0 &&
																		<h6 className="my-0">Increase retention rate by {thousands(beautifyToFixed(Math.abs(profit?.retentionRateChangeBy) * 100))}%</h6>
																	}
																	<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(retainedCustomers?.diff))} retained customers</strong>
																</div>
																:
																<h6 className="text-black-50">Retention rate</h6>
														}

														<div className="d-flex flex-column">
															{
																retentionRate?.current === retentionRate?.new ?
																	<small className="text-black-50">Retention rate is currently {thousands(beautifyToFixed(retentionRate?.current * 100, 2))}%</small>
																	:
																	<small className="text-black-50">Change retention rate from {thousands(beautifyToFixed(retentionRate?.current * 100, 2))}% to <strong>{thousands(beautifyToFixed(retentionRate?.new * 100, 2))}%</strong></small>
															}
															{
																retainedCustomers?.current === retainedCustomers?.new ?
																	<small className="text-black-50">Retained customers are currently {beautifyToFixed(retainedCustomers?.current)}</small>
																	:
																	<small className="text-black-50">Change retained customers from {beautifyToFixed(retainedCustomers?.current)} to <strong>{beautifyToFixed(retainedCustomers?.new)}</strong></small>
															}
														</div>

														{
															retentionRateChanged &&
															<>
																{
																	props.readOnly ?
																		<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.retentionRateChangeIntro || ''))} />
																		:
																		<div className="mt-3">
																			<TextareaAutosize
																				style={textareaAutoSizeStyle}
																				onChange={e => onEditableChange('retentionRateChangeIntro', e)}
																				value={ modifications?.fields?.retentionRateChangeIntro ?? (presetReportModifications?.fields?.retentionRateChangeIntro || '') }
																			/>
																		</div>
																}
															</>
														}
													</div>
												</div>
											</div>

										</>
									}

									{
										repeatCustomers && !knownLeadGeneration && <>

											<div className="pt-3 avoid-break">
												<div className={`card rounded-3 h-100 ${newCustomersChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
													<div className="card-body p-4">
														{
															newCustomersChanged ?
																<div className="d-flex align-items-center justify-content-between mb-2">
																	{
																		profit?.estimatedNewCustomersChangeBy < 0 &&
																		<h6 className="my-0">Decrease number of new customers by {thousands(beautifyToFixed(Math.abs(profit?.estimatedNewCustomersChangeBy) * 100))}%</h6>
																	}
																	{
																		profit?.estimatedNewCustomersChangeBy > 0 &&
																		<h6 className="my-0">Increase number of new customers by {thousands(beautifyToFixed(Math.abs(profit?.estimatedNewCustomersChangeBy) * 100))}%</h6>
																	}
																	<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(estimatedNewCustomers?.diff))} new customers</strong>
																</div>
																:
																<h6 className="text-black-50">Number of new customers</h6>
														}

														<div className="d-flex flex-column">
															{
																estimatedNewCustomers?.current === estimatedNewCustomers?.new ?
																	<small className="text-black-50">Number of new customers is currently {thousands(beautifyToFixed(estimatedNewCustomers?.current, 2))}</small>
																	:
																	<small className="text-black-50">Change number of new customers from {thousands(beautifyToFixed(estimatedNewCustomers?.current, 2))} to <strong>{thousands(beautifyToFixed(estimatedNewCustomers?.new, 2))}</strong></small>
															}

															{
																repeatCustomers && !knownLeadGeneration && <>
																	{
																		(totalCustomers?.new == totalCustomers?.current) ?
																			<small className="text-black-50">Total customers per year is {beautifyToFixed(totalCustomers?.current || 0)}</small>
																			:
																			<small className="text-black-50">Change total customers per year from {beautifyToFixed(totalCustomers?.current || 0)} to <strong>{beautifyToFixed(totalCustomers?.new || 0)}</strong></small>
																	}

																</>
															}
														</div>

														{
															newCustomersChanged &&
															<>
																{
																	props.readOnly ?
																		<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.newCustomerChangeIntro || ''))} />
																		:
																		<div className="mt-3">
																			<TextareaAutosize
																				style={textareaAutoSizeStyle}
																				onChange={e => onEditableChange('newCustomerChangeIntro', e)}
																				value={ modifications?.fields?.newCustomerChangeIntro ?? (presetReportModifications?.fields?.newCustomerChangeIntro || '') }
																			/>
																		</div>
																}
															</>
														}
													</div>
												</div>
											</div>

										</>
									}

									{
										knownLeadGeneration && <>

											<div className="pt-3 avoid-break">
												<div className={`card rounded-3 h-100 ${leadsPerYearChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
													<div className="card-body p-4">
														{
															leadsPerYearChanged ?
																<div className="d-flex align-items-center justify-content-between mb-2">
																	{
																		profit?.leadsPerYearChangeBy < 0 &&
																		<h6 className="my-0">Decrease number of leads per year by {thousands(beautifyToFixed(Math.abs(profit?.leadsPerYearChangeBy) * 100))}%</h6>
																	}
																	{
																		profit?.leadsPerYearChangeBy > 0 &&
																		<h6 className="my-0">Increase number of leads per year by {thousands(beautifyToFixed(Math.abs(profit?.leadsPerYearChangeBy) * 100))}%</h6>
																	}
																	<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(leadsPerYear?.diff))} new leads</strong>
																</div>
																:
																<h6 className="text-black-50">Number of leads per year</h6>
														}

														<div className="d-flex flex-column">
															{
																leadsPerYear?.current === leadsPerYear?.new ?
																	<small className="text-black-50">Current number of leads is {thousands(beautifyToFixed(leadsPerYear?.current))}</small>
																	:
																	<small className="text-black-50">Change number of leads from {thousands(beautifyToFixed(leadsPerYear?.current))} to <strong>{thousands(beautifyToFixed(leadsPerYear?.new))}</strong></small>
															}
														</div>

														{
															leadsPerYearChanged &&
															<>
																{
																	props.readOnly ?
																		<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.leadsPerYearChangeIntro || ''))} />
																		:
																		<div className="mt-3">
																			<TextareaAutosize
																				style={textareaAutoSizeStyle}
																				onChange={e => onEditableChange('leadsPerYearChangeIntro', e)}
																				value={ modifications?.fields?.leadsPerYearChangeIntro ?? (presetReportModifications?.fields?.leadsPerYearChangeIntro || '') }
																			/>
																		</div>
																}
															</>
														}
													</div>
												</div>
											</div>

											<div className="pt-3 avoid-break">
												<div className={`card rounded-3 h-100 ${leadConversionChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
													<div className="card-body p-4">
														{
															leadConversionChanged ?
																<div className="d-flex align-items-center justify-content-between mb-2">
																	{
																		profit?.leadConversionRateChangeBy < 0 &&
																		<h6 className="my-0">Decrease lead conversion rate by {thousands(beautifyToFixed(Math.abs(profit?.leadConversionRateChangeBy) * 100))}%</h6>
																	}
																	{
																		profit?.leadConversionRateChangeBy > 0 &&
																		<h6 className="my-0">Increase lead conversion rate by {thousands(beautifyToFixed(Math.abs(profit?.leadConversionRateChangeBy) * 100))}%</h6>
																	}
																	<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(newCustomers?.diff))} new customers</strong>
																</div>
																:
																<h5 className="text-black-50">Increase lead conversion rate</h5>
														}

														<div className="d-flex flex-column">
															{
																leadConversionRate?.current === leadConversionRate?.new ?
																	<small className="text-black-50">Lead conversion rate is currently {thousands(beautifyToFixed(leadConversionRate?.current * 100, 2))}%</small>
																	:
																	<small className="text-black-50">Change lead conversion rate from {thousands(beautifyToFixed(leadConversionRate?.current * 100, 2))}% to <strong>{thousands(beautifyToFixed(leadConversionRate?.new * 100, 2))}%</strong></small>
															}

															{
																!repeatCustomers && <>
																	{
																		newCustomers?.current === newCustomers?.new ?
																			<small className="text-black-50">Number of customer is currently {beautifyToFixed(newCustomers?.current)}</small>
																			:
																			<small className="text-black-50">Change number of customers from {beautifyToFixed(newCustomers?.current)} to <strong>{beautifyToFixed(newCustomers?.new)}</strong></small>
																	}
																</>
															}

															{
																repeatCustomers && <>

																	{
																		newCustomers?.current === newCustomers?.new ?
																			<small className="text-black-50">New customers are currently {beautifyToFixed(newCustomers?.current)}</small>
																			:
																			<small className="text-black-50">Change new customers from {beautifyToFixed(newCustomers?.current)} to <strong>{beautifyToFixed(newCustomers?.new)}</strong></small>
																	}

																	{
																		totalCustomers?.current === totalCustomers?.new ?
																			<small className="text-black-50">Total customers are currently {beautifyToFixed(totalCustomers?.current)}</small>
																			:
																			<small className="text-black-50">Change total customers from {beautifyToFixed(totalCustomers?.current)} to <strong>{beautifyToFixed(totalCustomers?.new)}</strong></small>
																	}
																</>
															}
														</div>

														{
															leadConversionChanged &&
															<>
																{
																	props.readOnly ?
																		<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.leadConversionChangeIntro || ''))} />
																		:
																		<div className="mt-3">
																			<TextareaAutosize
																				style={textareaAutoSizeStyle}
																				onChange={e => onEditableChange('leadConversionChangeIntro', e)}
																				value={modifications?.fields?.leadConversionChangeIntro ?? (presetReportModifications?.fields?.leadConversionChangeIntro || '') }
																			/>
																		</div>
																}
															</>
														}
													</div>
												</div>
											</div>

										</>
									}

									<div className="pt-3 avoid-break">
										<div className={`card rounded-3 h-100 ${transactionsPerClientPerYearChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
											<div className="card-body p-4">
												{
													transactionsPerClientPerYearChanged ?
														<div className="d-flex align-items-center justify-content-between mb-2">
															{
																profit?.transactionsPerClientPerYearChangeBy < 0 &&
																<h6 className="my-0">Decrease transactions per customer by {thousands(beautifyToFixed(Math.abs(profit?.transactionsPerClientPerYearChangeBy) * 100))}%</h6>
															}
															{
																profit?.transactionsPerClientPerYearChangeBy > 0 &&
																<h6 className="my-0">Increase transactions per customer by {thousands(beautifyToFixed(Math.abs(profit?.transactionsPerClientPerYearChangeBy) * 100))}%</h6>
															}
															<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(transactionsPerClientPerYear?.diff, 1))} transactions per client</strong>
														</div>
														:
														<h6 className="text-black-50">Transactions per customer</h6>
												}

												<div className="d-flex flex-column">
													{
														transactionsPerClientPerYear?.current === transactionsPerClientPerYear?.new ?
															<small className="text-black-50">Transactions per customer is currently {thousands(beautifyToFixed(transactionsPerClientPerYear?.current, 1))}</small>
															:
															<small className="text-black-50">Change transaction per customer from {thousands(beautifyToFixed(transactionsPerClientPerYear?.current, 1))} to <strong>{thousands(beautifyToFixed(transactionsPerClientPerYear?.new, 1))}</strong></small>
													}
												</div>

												{
													transactionsPerClientPerYearChanged &&
													<>
														{
															props.readOnly ?
																<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.transactionPerClientPerYearChangeIntro || ''))} />
																:
																<div className="mt-3">
																	<TextareaAutosize
																		style={textareaAutoSizeStyle}
																		onChange={e => onEditableChange('transactionPerClientPerYearChangeIntro', e)}
																		value={ modifications?.fields?.transactionPerClientPerYearChangeIntro ?? (presetReportModifications?.fields?.transactionPerClientPerYearChangeIntro || '') }
																	/>
																</div>
														}
													</>
												}
											</div>
										</div>
									</div>

									<div className="pt-3 avoid-break">
										<div className={`card rounded-3 h-100 ${averageTransactionValueChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
											<div className="card-body p-4">
												{
													averageTransactionValueChanged ?
														<div className="d-flex align-items-center justify-content-between mb-2">
															{
																profit?.averageTransactionValueChangeBy < 0 &&
																<h6 className="my-0">Decrease average transaction value by {thousands(beautifyToFixed(Math.abs(profit?.averageTransactionValueChangeBy) * 100))}%</h6>
															}
															{
																profit?.averageTransactionValueChangeBy > 0 &&
																<h6 className="my-0">Increase average transaction value by {thousands(beautifyToFixed(Math.abs(profit?.averageTransactionValueChangeBy) * 100))}%</h6>
															}
															<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(averageTransactionValue?.diff), true, currency)} average value</strong>
														</div>
														:
														<h6 className="text-black-50">Average transaction value</h6>
												}

												<div className="d-flex flex-column">
													{
														averageTransactionValue?.current === averageTransactionValue?.new ?
															<small className="text-black-50">Average transaction value is currently {currency}{thousands(beautifyToFixed(averageTransactionValue?.current))}</small>
															:
															<small className="text-black-50">Change average transaction value from {currency}{thousands(beautifyToFixed(averageTransactionValue?.current))} to <strong>{currency}{thousands(beautifyToFixed(averageTransactionValue?.new))}</strong></small>
													}
												</div>

												{
													averageTransactionValueChanged &&
													<>
														{
															props.readOnly ?
																<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.averageTransactionValueChangeIntro || ''))} />
																:
																<div className="mt-3">
																	<TextareaAutosize
																		style={textareaAutoSizeStyle}
																		onChange={e => onEditableChange('averageTransactionValueChangeIntro', e)}
																		value={ modifications?.fields?.averageTransactionValueChangeIntro ?? (presetReportModifications?.fields?.averageTransactionValueChangeIntro || '') }
																	/>
																</div>
														}
													</>
												}
											</div>
										</div>
									</div>

									{
										detailedSalesDrivers && <>

											<div className="pt-3 avoid-break">

												<div className="d-flex flex-row">
													{/*
													<IconInfo fontSize="small" className="me-2 no-print" />
													*/}
													<div>
														{
															changeSales?.current == changeSales?.new ?
																<div>Sales are currently {currency}{thousands(beautifyToFixed(changeSales?.current))}</div>
																:
																<div>Change sales from {currency}{thousands(beautifyToFixed(changeSales?.current))} to <strong>{currency}{thousands(beautifyToFixed(changeSales?.new))}</strong></div>
														}
													</div>
												</div>

											</div>
										</>
									}

								</>
							}

						</div>

						<div className="page px-5 pb-4 avoid-break">

							<div className="avoid-break">
								<div className="d-flex align-items-baseline justify-content-between pt-4">
									<h5 className="text-bold">Gross Profit Margin</h5>
									{
										grossMarginChanged &&
										<div>
											<h6 className={`text-end ${improveGrossMargin?.impact?.cash > 0 ? 'text-success' : 'text-danger'}`}>{addPlusMinusToNumber(beautifyToFixed(improveGrossMargin?.impact?.cash), true, currency)} Cash Impact</h6>
											<h6 className={`text-end ${improveGrossMargin?.impact?.profit > 0 ? 'text-success' : 'text-danger'}`}>{addPlusMinusToNumber(beautifyToFixed(improveGrossMargin?.impact?.profit), true, currency)} Profit Impact</h6>
										</div>
									}
								</div>

								<div className="pt-3 avoid-break">
									<div className={`card rounded-3 h-100 ${grossMarginChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
										<div className="card-body p-4">
											{
												grossMarginChanged ?
													<div className="d-flex align-items-center justify-content-between mb-2">
														{
															profit?.grossMarginChangeBy < 0 &&
															<h6 className="my-0">Decrease gross profit margin by {thousands(beautifyToFixed(Math.abs(profit?.grossMarginChangeBy) * 100, 2))}%</h6>
														}
														{
															profit?.grossMarginChangeBy > 0 &&
															<h6 className="my-0">Increase gross profit margin by {thousands(beautifyToFixed(Math.abs(profit?.grossMarginChangeBy) * 100, 2))}%</h6>
														}
														<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(improveGrossMargin?.grossProfit?.diff), true, currency)} gross profit</strong>
													</div>
													:
													<h6 className="text-black-50">Gross profit margin</h6>
											}

											<div className="d-flex flex-column">
												{
													directCost?.current === directCost?.new ?
														<small className="text-black-50">Cost of sales is currently {currency}{thousands(beautifyToFixed(directCost?.current))}</small>
														:
														<small className="text-black-50">Change cost of sales from {currency}{thousands(beautifyToFixed(directCost?.current))} to <strong>{currency}{thousands(beautifyToFixed(directCost?.new))}</strong></small>
												}

												{
													grossProfit?.current === grossProfit?.new ?
														<small className="text-black-50">Gross profit is currently {currency}{thousands(beautifyToFixed(grossProfit?.current))}</small>
														:
														<small className="text-black-50">Change gross profit from {currency}{thousands(beautifyToFixed(grossProfit?.current))} to <strong>{currency}{thousands(beautifyToFixed(grossProfit?.new))}</strong></small>
												}

												{
													grossMargin?.current === grossMargin?.new ?
														<small className="text-black-50">Gross margin is currently {thousands(beautifyToFixed(grossMargin?.current * 100))}%</small>
														:
														<small className="text-black-50">Change gross margin from {thousands(beautifyToFixed(grossMargin?.current * 100))}% to <strong>{thousands(beautifyToFixed(grossMargin?.new * 100))}%</strong></small>
												}
											</div>

											{
												grossMarginChanged &&
												<>
													{
														props.readOnly ?
															<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.grossMarginChangeIntro || ''))} />
															:
															<div className="mt-3">
																<TextareaAutosize
																	style={textareaAutoSizeStyle}
																	onChange={e => onEditableChange('grossMarginChangeIntro', e)}
																	value={ modifications?.fields?.grossMarginChangeIntro ?? (presetReportModifications?.fields?.grossMarginChangeIntro || '') }
																/>
															</div>
													}
												</>
											}
										</div>
									</div>
								</div>

							</div>

						</div>

						<div className="page px-5 pb-4 bg-light">

							<div className="avoid-break">

								<div className="d-flex align-items-baseline justify-content-between pt-4">
									<h5 className="text-bold">Overheads</h5>
									{
										netProfitChanged &&
										<div>
											<h6 className={`text-end ${increaseProfit?.impact?.cash > 0 ? 'text-success' : 'text-danger'}`}>{addPlusMinusToNumber(beautifyToFixed(increaseProfit?.impact?.cash), true, currency)} Cash Impact</h6>
											<h6 className={`text-end ${increaseProfit?.impact?.profit > 0 ? 'text-success' : 'text-danger'}`}>{addPlusMinusToNumber(beautifyToFixed(increaseProfit?.impact?.profit), true, currency)} Profit Impact</h6>
										</div>
									}
								</div>

								<div className="pt-3 avoid-break">
									<div className={`card rounded-3 h-100 ${netProfitChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
										<div className="card-body p-4">
											{
												netProfitChanged ?
													<div className="d-flex align-items-center justify-content-between mb-2">
														{
															profit?.overheadExpensesChangeBy < 0 &&
															<h6 className="my-0">Reduce overheads by {currency}{thousands(beautifyToFixed(Math.abs(profit?.overheadExpensesChangeBy)))}</h6>
														}
														{
															profit?.overheadExpensesChangeBy > 0 &&
															<h6 className="my-0">Increase investment needed by {currency}{thousands(beautifyToFixed(Math.abs(profit?.overheadExpensesChangeBy)))}</h6>
														}
														<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(overheadExpenses?.impact?.profit), true, currency)} net profit</strong>
													</div>
													:
													<h6 className="text-black-50">Overheads</h6>
											}
											<div className="d-flex flex-column">

												{
													overheadExpenses?.current === overheadExpenses?.new ?
														<small className="text-black-50">Overheads are currently {currency}{thousands(beautifyToFixed(overheadExpenses?.current))}</small>
														:
														<small className="text-black-50">Change overheads from {currency}{thousands(beautifyToFixed(overheadExpenses?.current))} to <strong>{currency}{thousands(beautifyToFixed(overheadExpenses?.new))}</strong></small>
												}

												{
													inputs?.haveOtherIncome == 1 &&
													<small className="text-black-50">Other income is currently {currency}{thousands(beautifyToFixed(inputs?.otherIncome))}</small>
												}

												{
													netProfit?.current === netProfit?.new ?
														<small className="text-black-50">Net profit is currently {currency}{thousands(beautifyToFixed(netProfit?.current))}</small>
														:
														<small className="text-black-50">Change net profit from {currency}{thousands(beautifyToFixed(netProfit?.current))} to <strong>{currency}{thousands(beautifyToFixed(netProfit?.new))}</strong></small>
												}

												{
													netMargin?.current === netMargin?.new ?
														<small className="text-black-50">Net margin is currently {thousands(beautifyToFixed(netMargin?.current))}%</small>
														:
														<small className="text-black-50">Change net margin from {thousands(beautifyToFixed(netMargin?.current, 1))}% to <strong>{thousands(beautifyToFixed(netMargin?.new, 1))}%</strong></small>
												}

												{
													value?.current === value?.new ?
														<small className="text-black-50">Estimated value is currently {currency}{thousands(beautifyToFixed(value?.current))}</small>
														:
														<small className="text-black-50">Change estimated value from {currency}{thousands(beautifyToFixed(value?.current))} to <strong>{currency}{thousands(beautifyToFixed(value?.new))}</strong></small>
												}

											</div>

											{
												netProfitChanged &&
												<>
													{
														props.readOnly ?
															<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.netProfitChangeIntro || ''))} />
															:
															<div className="mt-3">
																<TextareaAutosize
																	style={textareaAutoSizeStyle}
																	onChange={e => onEditableChange('netProfitChangeIntro', e)}
																	value={ modifications?.fields?.netProfitChangeIntro ?? (presetReportModifications?.fields?.netProfitChangeIntro || '') }
																/>
															</div>
													}
												</>
											}
										</div>
									</div>
								</div>

							</div>

						</div>
					</>
				)
			}


			{/* ROI */}
			{
				!props.readOnly &&
				<div className="no-print p-3">
					<FormControlLabel
						control={<Checkbox onChange={() => toggleSection(sections.roi)} checked={modifications.sections?.[sections.roi]} />}
						label="Show ROI"
						className="px-2 d-flex align-items-center no-print"
					/>
				</div>
			}
			{
				modifications.sections?.[sections.roi] && (
					<div className="page px-5 pb-4">

						<div className="avoid-break">
							<div className="d-flex align-items-baseline justify-content-between pt-4 avoid-break">
								<h5 className="text-bold">Return on Investment (ROI)</h5>
								{
									returnOnInvestmentChanged &&
									<div>
										<h6 className={`text-end ${improveROI?.impact?.cash > 0 ? 'text-success' : 'text-danger'}`}>{addPlusMinusToNumber(beautifyToFixed(improveROI?.impact?.cash), true, currency)} Cash Impact</h6>
										<h6 className={`text-end ${improveROI?.impact?.profit > 0 ? 'text-success' : 'text-danger'}`}>{addPlusMinusToNumber(beautifyToFixed(improveROI?.impact?.profit), true, currency)} Profit Impact</h6>
									</div>
								}
							</div>

							<div className="pt-3 avoid-break">
								<div className={`card rounded-3 h-100 ${returnOnInvestmentChanged ? 'shadow' : 'border-dashed border-light-subtle bg-transparent'}`}>
									<div className="card-body p-4">
										{
											returnOnInvestmentChanged ?
												<div className="d-flex align-items-center justify-content-between mb-2">
													{
														returnOnInvestment?.revenueByTeamMemberChangeBy < 0 &&
														<h6 className="my-0">Reduce revenue per FTE by {thousands(beautifyToFixed(Math.abs(returnOnInvestment?.revenueByTeamMemberChangeBy) * 100, 2))}%</h6>
													}
													{
														returnOnInvestment?.revenueByTeamMemberChangeBy > 0 &&
														<h6 className="my-0">Increase revenue per FTE by {thousands(beautifyToFixed(Math.abs(returnOnInvestment?.revenueByTeamMemberChangeBy) * 100, 2))}%</h6>
													}
													<strong className="text-end">{addPlusMinusToNumber(beautifyToFixed(revenueByTeamMember?.diff), true, currency)}/FTE</strong>
												</div>
												:
												<h6 className="text-black-50">Revenue per FTE</h6>
										}
										<div className="d-flex flex-column">

											{
												revenueByTeamMember?.current === revenueByTeamMember?.new ?
													<small className="text-black-50">Revenue per FTE is currently {currency}{thousands(beautifyToFixed(revenueByTeamMember?.current))}</small>
													:
													<small className="text-black-50">Change revenue per FTE from {currency}{thousands(beautifyToFixed(revenueByTeamMember?.current))} to <strong>{currency}{thousands(beautifyToFixed(revenueByTeamMember?.new))}</strong></small>
											}

										</div>

										{
											returnOnInvestmentChanged &&
											<>
												{
													props.readOnly ?
														<div className="mt-3" dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.roiChangeIntro || ''))} />
														:
														<div className="mt-3">
															<TextareaAutosize
																style={textareaAutoSizeStyle}
																onChange={e => onEditableChange('roiChangeIntro', e)}
																value={ modifications?.fields?.roiChangeIntro ?? (presetReportModifications?.fields?.roiChangeIntro || '') }
															/>
														</div>
												}
											</>
										}
									</div>
								</div>
							</div>

						</div>

						<div className="pt-3 avoid-break">

							<div className="d-flex flex-row">
								{/*
								<IconInfo fontSize="small" className="me-2 no-print" />
								*/}
								<div>
									{
										currentEffectiveRate?.current === currentEffectiveRate?.new ?
											<div>Effective hourly rate is currently {currency}{thousands(beautifyToFixed(currentEffectiveRate?.current))}/hour</div>
											:
											<div>Change effective hourly rate from {currency}{thousands(beautifyToFixed(currentEffectiveRate?.current))}/hour to <strong>{currency}{thousands(beautifyToFixed(currentEffectiveRate?.new))}/hour</strong></div>
									}
								</div>
							</div>

						</div>

					</div>
				)
			}


			<div className="page px-5 py-4 bg-light avoid-break">
				<div className="d-flex align-items-baseline justify-content-between mb-2 avoid-break">
					<h5 className="text-bold">Purpose of This Report and Next Steps</h5>
				</div>

				<div className="container-fluid p-0">
					<div className="row">

						<div className="col-12 py-2 avoid-break">
							<>
								{
									props.readOnly ?
										<p dangerouslySetInnerHTML={createMarkup(convertLinebreakToP(modifications?.fields?.purposeAndNextIntro || ''))} />
										:
										<p>
											<TextareaAutosize
												style={textareaAutoSizeStyle}
												onChange={e => onEditableChange('purposeAndNextIntro', e)}
												value={ modifications?.fields?.purposeAndNextIntro ?? (presetReportModifications?.fields?.purposeAndNextIntro || '') }
											/>
										</p>
								}
							</>
							<p>
								{!!calculationInfo?.advisorInfo?.name && `${calculationInfo?.advisorInfo?.name}, `}{Moment().format('D MMMM, YYYY')}
							</p>
						</div>

					</div>
				</div>
			</div>


			{/* inputs */}
			{
				!props.readOnly &&
				<div className="no-print p-3">
					<FormControlLabel
						control={<Checkbox onChange={() => toggleSection(sections.inputs)} checked={modifications.sections?.[sections.inputs]} />}
						label="Show Inputs"
						className="px-2 d-flex align-items-center no-print"
					/>
				</div>
			}
			{
				modifications.sections?.[sections.inputs] && (
					<div className="page px-5 py-4">
						<h5 className="mb-4">Inputs</h5>

						<div className="mt-3 avoid-break">
							<h6 className="mb-2">Profit and Loss</h6>

							<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
								<span>Sales</span>
								<span>{currency}{thousands(beautifyToFixed(inputs?.sales, 2))}</span>
							</div>

							<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
								<span>Cost of Sales/Direct Cost</span>
								<span>{currency}{thousands(beautifyToFixed(inputs?.directCost, 2))}</span>
							</div>

							<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
								<span>Overhead Expenses</span>
								<span>{currency}{thousands(beautifyToFixed(inputs?.overheadExpenses, 2))}</span>
							</div>

							{
								inputs?.haveOtherIncome == 1 &&
								<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
									<span>Other Income</span>
									<span>{currency}{thousands(beautifyToFixed(inputs?.otherIncome, 2))}</span>
								</div>
							}

						</div>

						<div className="mt-3 avoid-break">
							<h6 className="mb-2">Balance Sheet</h6>

							<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
								<span>Accounts Receivable</span>
								<span>{currency}{thousands(beautifyToFixed(inputs?.accountsReceivable, 2))}</span>
							</div>

							<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
								<span>Inventory / Work in Progress</span>
								<span>{currency}{thousands(beautifyToFixed(inputs?.inventory, 2))}</span>
							</div>

							<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
								<span>Accounts Payable</span>
								<span>{currency}{thousands(beautifyToFixed(inputs?.accountsPayable, 2))}</span>
							</div>
						</div>

						<div className="mt-3 avoid-break">
							<h6 className="mb-2">Other Financial Variables</h6>

							<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
								<span>Tax Rate</span>
								<span>{thousands(beautifyToFixed(inputs?.taxRate * 100, 2))}%</span>
							</div>

							<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
								<span>Valuation Multiple</span>
								<span>{thousands(beautifyToFixed(inputs?.valuationMultiple, 2))}</span>
							</div>
						</div>

						<div className="mt-3 avoid-break">
							<h6 className="mb-2">Team Members</h6>

							<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
								<span>Number of team members (FTE)</span>
								<span>{thousands(beautifyToFixed(inputs?.teamMembers, 2))} FTE</span>
							</div>
						</div>

						{
							inputs?.useCustomersAndSales == 1 &&
							<>
								<div className="mt-3 avoid-break">
									<h6 className="mb-2">Customers and Sales</h6>

									{
										repeatCustomers && <>
											<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
												<span>Number of existing customers</span>
												<span>{thousands(beautifyToFixed(inputs?.existingCustomers, 2))}</span>
											</div>

											<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
												<span>Retention Rate</span>
												<span>{thousands(beautifyToFixed(inputs?.retentionRate * 100, 2))}%</span>
											</div>
										</>
									}

									{
										knownLeadGeneration && <>
											<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
												<span>Number of leads generated per year</span>
												<span>{thousands(beautifyToFixed(inputs?.leadsPerYear))}</span>
											</div>

											<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
												<span>Lead conversion rate</span>
												<span>{thousands(beautifyToFixed(inputs?.leadConversionRate * 100, 2))}%</span>
											</div>
										</>
									}

									{
										repeatCustomers && !knownLeadGeneration && <>
											<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
												<span>Estimated new customers</span>
												<span>{thousands(beautifyToFixed(inputs?.estimatedNewCustomers))}</span>
											</div>
										</>
									}

									<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
										<span>Total customers per year</span>
										<span>
											{
												thousands(beautifyToFixed(
													!repeatCustomers && !knownLeadGeneration ? inputs?.totalCustomers : increaseSales?.totalCustomers?.new
												))
											}
										</span>
									</div>

									<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
										<span>Transaction per customer per year</span>
										<span>
											{thousands(beautifyToFixed(inputs?.transactionsPerClientPerYear))}
										</span>
									</div>

									<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
										<span>Average transactions value</span>
										<span>{currency}{thousands(beautifyToFixed(increaseSales?.averageTransactionValue?.new || 0))}</span>
									</div>

									{
										repeatCustomers && <>
											<div className="d-flex align-items-center justify-content-between bg-light p-2 mb-2">
												<span>Project Sales</span>
												<span>{currency}{thousands(beautifyToFixed(increaseSales?.projectedSales?.new || 0))}</span>
											</div>
										</>
									}

								</div>

							</>
						}

					</div>
				)
			}

		</>
	);
};

export default Report;

Report.defaultProps = {
	currency: '$',
	reportModifications: presetReportModifications,
	readOnly: true,
};

const Table = styled.table`
	thead{
		&, & tr, & th{
			background-color: transparent !important;
			border-top: none !important;
			border-right: none !important;
			border-bottom: none !important;
			border-left: none !important;
		}
	}
	tr{
		border-width: 0 !important;
	}
	td, th{
		background-color: transparent !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		border-top: none !important;
		border-right: none !important;
		border-left: none !important;
	}
`;

const sections = {
	cashflow: 'cashflow',
	profit: 'profit',
	// sales: 'sales',
	// grossProfitMargin: 'grossProfitMargin',
	// overheads: 'overheads',
	roi: 'roi',
	inputs: 'inputs',
};

const textareaAutoSizeStyle: React.CSSProperties = {
	display: 'block',
	width: '100%',
	color: 'inherit',
	font: 'inherit',
	resize: 'none',
};
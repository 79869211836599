import React, { useState, useEffect, useRef, useMemo, PropsWithChildren } from "react";
// import { usePrevious } from '@uidotdev/usehooks';
import {
	Box,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Menu,
	MenuItem,
	Tabs,
	Tab,
	IconButton,
} from '@mui/material';

import {
	MoreVert as IconMoreVert,
} from '@mui/icons-material';

import _ from 'lodash';

import {
	Inputs,
	Cashflow,
	Profit,
	ROI,
	Summary,
} from './groups';
import Report from "./report";
import { TabPanel, promiseContainer } from "../";
import { CurrencyContext } from "./contexts";
import { executeFormula } from "../../utilities";
import { defaultDataJson, presetDataJson } from "./constants";
import { TCalculationResults } from "./index.d";
import mixpanel from "mixpanel-browser";

interface ICalculator {
	currency?: string;
	defaultTaxRate: number;
	calculation: TCalculationData;
	// eslint-disable-next-line no-unused-vars
	onDataChange: (...args: unknown[]) => void;
	// eslint-disable-next-line no-unused-vars
	onResultChange: (...args: unknown[]) => void;
};

const Calculator: React.FC<PropsWithChildren<ICalculator>> = props => {

	const [ tab, setTab ] = useState(0);
	const [ calculation, setCalculation ] = useState(props.calculation || {});

	const defaultData = useMemo(() => ({
		...defaultDataJson,
		inputs: {
			...defaultDataJson?.inputs,
			taxRate: props.defaultTaxRate,
		},
	}), [ props.defaultTaxRate ]);

	const presetData = useMemo(() => ({
		...presetDataJson,
		inputs: {
			...presetDataJson?.inputs,
			taxRate: props.defaultTaxRate,
		},
	}), [ props.defaultTaxRate ]);

	const [ calculationResults, setCalculationResults ] = useState<TCalculationResults | null>(null);
	const tabRef = useRef(null);

	const [ moreMenuAnchorEl, setMoreMenuAnchorEl ] = React.useState(null);
	const handleMoreMenuClick = (event) => {
		setMoreMenuAnchorEl(event.currentTarget);
	};
	const handleMoreMenuClose = () => {
		setMoreMenuAnchorEl(null);
	};

	useEffect(() => {
		if(!_.isEqual(props.calculation, calculation)){
			setCalculation(props.calculation);
		}
	}, [ props.calculation ]);

	useEffect(() => {
		const debouncedCalc = _.debounce(() => {
			const results = executeFormula(formula, formula, calculation);
			setCalculationResults(results);
			props.onResultChange(results);
			props.onDataChange(calculation);
		}, 300); // reduce calculation frequency

		debouncedCalc();

		return debouncedCalc.cancel;
	}, [ calculation ]);

	// synchronize the new overhead expenses if the new overhead expenses has not been modified
	// useEffect(() => {
	// 	if((prevData?.inputs?.overheadExpenses === data?.profit?.overheadExpenses_new || !Number.isFinite(data?.profit?.overheadExpenses_new)) && Object.prototype.hasOwnProperty.call(data?.profit, "overheadExpenses_new")){
	// 		setData(prevState => {
	// 			prevState = {
	// 				...prevState,
	// 				profit: {
	// 					...prevState.profit,
	// 					overheadExpenses_new: data?.inputs?.overheadExpenses,
	// 				}
	// 			};
	// 			return prevState;
	// 		});
	// 	}
	// }, [data?.inputs?.overheadExpenses]);

	const updateGroupData = (group, data) => {
		const debouncedFunc = _.debounce(() => {
			setCalculation(prevState => {

				const newState = {
					...prevState,
					[group]: data,
				};

				if (group === 'inputs' && prevState['inputs'].useCustomersAndSales !== data?.useCustomersAndSales) {
					// if `inputs.useCustomersAndSales` is changed
					if (data?.useCustomersAndSales == 0) {
						newState.profit = {
							...prevState.profit,
							detailedSalesDrivers: 0,
						};
					}
				}

				if (group === 'profit' && prevState['profit'].detailedSalesDrivers !== data?.detailedSalesDrivers) {
					// if `profit.detailedSalesDrivers` is changed
					if (data?.detailedSalesDrivers == 1) {
						newState.inputs = {
							...prevState.inputs,
							useCustomersAndSales: 1,
						};
					}
				}

				return newState;
			});
		}, 300); // reduce calculation frequency
		debouncedFunc();

		return debouncedFunc.cancel;
	};

	const clearImprovements = () => {
		mixpanel.track('Click "Clear improvements)');

		promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
			open={show}
			onClose={onDismiss}
		>
			<DialogTitle>Warning</DialogTitle>
			<DialogContent>
				<DialogContentText>Are you sure you want to clear improvements? If you have not saved this scenario you will not be able to restore.</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onDismiss}>Cancel</Button>
				<Button onClick={onConfirm} color="error">Clear</Button>
			</DialogActions>
		</Dialog>
		)
			.then(() => {
				mixpanel.track('Confirm "Clear improvements"');
				/* eslint-disable-next-line */
				const { inputs, ...rest } = defaultDataJson;
				setCalculation(prevState => ({
					...prevState,
					...rest,
				}));
			})
			.catch(() => { })
			.finally(() => {
				handleMoreMenuClose();
			});
	};

	const resetDataToPreset = () => {
		mixpanel.track('Click "Load dummy data"');

		promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
			open={show}
			onClose={onDismiss}
		>
			<DialogTitle>Warning</DialogTitle>
			<DialogContent>
				<DialogContentText>Are you sure you want to load dummy data? If you have not saved this scenario you will not be able to restore.</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onDismiss}>Cancel</Button>
				<Button onClick={onConfirm} color="error">Load</Button>
			</DialogActions>
		</Dialog>
		)
			.then(() => {
				mixpanel.track('Confirm "Load dummy data"');
				setCalculation(presetData);
			})
			.catch(() => { })
			.finally(() => {
				handleMoreMenuClose();
			});
	};

	const resetDataToDefault = () => {
		mixpanel.track('Click "Reset all data"');

		promiseContainer(({ onConfirm, onDismiss, show }) => <Dialog
			open={show}
			onClose={onDismiss}
		>
			<DialogTitle>Warning</DialogTitle>
			<DialogContent>
				<DialogContentText>Are you sure you want to clear all data? If you have not saved this scenario you will not be able to restore. </DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onDismiss}>Cancel</Button>
				<Button onClick={onConfirm} color="error">Clear</Button>
			</DialogActions>
		</Dialog>
		)
			.then(() => {
				mixpanel.track('Confirm "Reset all data"');
				setCalculation(defaultData);
			})
			.catch(() => { })
			.finally(() => {
				handleMoreMenuClose();
			});
	};

	return (
		<CurrencyContext.Provider value={ props.currency || '$' }>

			<Container maxWidth="xl" sx={{ pb: 4, display: 'flex' }}>

				<Box sx={{ width: '60%' }}>

					<Menu
						anchorEl={moreMenuAnchorEl}
						open={Boolean(moreMenuAnchorEl)}
						onClose={handleMoreMenuClose}
					>
						<MenuItem onClick={clearImprovements}>Clear improvements</MenuItem>
						<MenuItem onClick={resetDataToDefault}>Clear all data</MenuItem>
						<MenuItem onClick={resetDataToPreset}>Load dummy data</MenuItem>

					</Menu>

					<Tabs variant="scrollable" scrollButtons="auto" value={tab} onChange={(e, newValue) => setTab(newValue)}
						sx={{
							position: 'sticky', top: 0, zIndex: 1090,
							px: 1, mx: -1, mb: 2,
							backgroundColor: '#fff',
							transition: 'border-color ease 1000ms',
							borderBottom: 1,
							borderColor: 'divider',
							// ...tabRef?.current?.offsetTop === Math.round(y + 1) ? {
							// 	borderBottom: 1,
							// 	borderColor: 'divider',
							// } : {
							// 	borderBottom: 0,
							// 	borderColor: 'transparent',
							// },
						}}
						ref={tabRef}
					>
						<Tab label="Inputs" />
						<Tab label="Cash Conversion" />
						<Tab label="Profit" />
						<Tab label="ROI" />
						<IconButton sx={{ borderRadius: 0 }} onClick={ handleMoreMenuClick }><IconMoreVert /></IconButton>
					</Tabs>

					<TabPanel value={tab} index={0}>
						<Inputs
							values={calculation.inputs}
							onChange={val => updateGroupData('inputs', val)}
							calculationResults={calculationResults || {}}
						/>
					</TabPanel>

					<TabPanel value={tab} index={1}>
						<Cashflow
							values={calculation.cashflow}
							onChange={val => updateGroupData('cashflow', val)}
							calculationResults={calculationResults || {}}
						/>
					</TabPanel>

					<TabPanel value={tab} index={2}>
						<Profit
							inputs={calculation.inputs}
							values={calculation.profit}
							enableDetailedSalesDrivers={calculation.inputs?.useCustomersAndSales == 1}
							onChange={val => updateGroupData('profit', val)}
							calculationResults={calculationResults || {}}
						/>
					</TabPanel>

					<TabPanel value={tab} index={3}>
						<ROI
							values={calculation.returnOnInvestment}
							onChange={val => updateGroupData('returnOnInvestment', val)}
							calculationResults={calculationResults || {}}
						/>
					</TabPanel>
				</Box>

				<Box sx={{ width: '40%' }}>
					<Box sx={{ position: 'sticky', top: 4, mt: 8, pl: 2 }}>
						<Summary calculationResults={calculationResults || {}} />
					</Box>
				</Box>

			</Container>

		</CurrencyContext.Provider>
	);
};


// Calculator.propTypes = {
// 	currency: PropTypes.string,
// 	defaultTaxRate: PropTypes.string,
// 	data: PropTypes.object,
// 	onDataChange: PropTypes.func,
// 	onResultChange: PropTypes.func,
// };

Calculator.defaultProps = {
	currency: '$',
	defaultTaxRate: 0,
	calculation: presetDataJson,
	onDataChange: () => {},
	onResultChange: () => {},
};

export default Calculator;
export { Calculator, Report };

const formula = {
	increaseSales: {
		sales: {
			current: "{inputs.sales}",
			changeBy: "{profit.salesChangeBy}",
			new: "{inputs.sales} * (1 + {profit.salesChangeBy})",
			diff: "@increaseSales.sales.new - @increaseSales.sales.current",
			impact: {
				cash: "@increaseSales.sales.impact.profit * (1 - {inputs.taxRate})",
				// profit: "val_detailed_off = ((@increaseSales.sales.new * @improveGrossMargin.grossMargin.current - {inputs.overheadExpenses}) - ({inputs.sales} * @improveGrossMargin.grossMargin.current - {inputs.overheadExpenses}));      val_detailed_on = ((@increaseSales.changeSales.new * @improveGrossMargin.grossMargin.current - {inputs.overheadExpenses}) - (@increaseSales.sales.current * @improveGrossMargin.grossMargin.current - {inputs.overheadExpenses}));          {profit.detailedSalesDrivers} == 1 ? val_detailed_on : val_detailed_off",
				profit: "val_detailed_off = ((@increaseSales.sales.new * @improveGrossMargin.grossMargin.current - {inputs.overheadExpenses}) - ({inputs.sales} * @improveGrossMargin.grossMargin.current - {inputs.overheadExpenses}));      val_detailed_on = ((@increaseSales.changeSales.new - @increaseSales.changeSales.current) * @improveGrossMargin.grossMargin.current);          {profit.detailedSalesDrivers} == 1 ? val_detailed_on : val_detailed_off",
			},
		},
		retentionRate: {
			current: "{inputs.retentionRate}",
			changeBy: "{profit.retentionRateChangeBy}",
			new: "clamp({inputs.retentionRate} + {profit.retentionRateChangeBy}, 0, 1)",
		},

		retainedCustomers: {
			current: "round({inputs.existingCustomers} * {inputs.retentionRate})",
			new: "round({inputs.existingCustomers} * @increaseSales.retentionRate.new)",
			diff: "@increaseSales.retainedCustomers.new - @increaseSales.retainedCustomers.current",
		},

		estimatedNewCustomers: {
			current: "{inputs.estimatedNewCustomers}",
			changeBy: "{profit.estimatedNewCustomersChangeBy}",
			new: "round({inputs.estimatedNewCustomers} * (1 + {profit.estimatedNewCustomersChangeBy}))",
			diff: "@increaseSales.estimatedNewCustomers.new - @increaseSales.estimatedNewCustomers.current",
		},

		newCustomers: {
			current: "@increaseSales.leadsPerYear.current * @increaseSales.leadConversionRate.current",
			new: "@increaseSales.leadsPerYear.new * @increaseSales.leadConversionRate.new",
			diff: "@increaseSales.newCustomers.new - @increaseSales.newCustomers.current",
		},

		totalCustomers: {
			// current: "@increaseSales.retainedCustomers.current + {inputs.estimatedNewCustomers} + @increaseSales.newCustomers.current",
			// current: "@increaseSales.retainedCustomers.current + @increaseSales.newCustomers.current",
			current: "repeatCustomers = {inputs.repeatCustomers} == 1; knownLeadGen = {inputs.knownLeadGen} == 1;      val_other = @increaseSales.retainedCustomers.current + @increaseSales.newCustomers.current;      val_yes_no = @increaseSales.retainedCustomers.current + @increaseSales.estimatedNewCustomers.current;          repeatCustomers and not(knownLeadGen) ? val_yes_no : val_other",
			diff: "@increaseSales.totalCustomers.new - @increaseSales.totalCustomers.current",
			// new: 'repeatCustomers = {inputs.repeatCustomers} == 1; knownLeadGen = {inputs.knownLeadGen} == 1;    val_no_no = {inputs.totalCustomers};    val_no_yes = {inputs.leadsPerYear} * {inputs.leadConversionRate};    val_yes_yes = @increaseSales.retainedCustomers.new + @increaseSales.newCustomers.new;    val_yes_no = @increaseSales.retainedCustomers.new + {inputs.estimatedNewCustomers};                repeatCustomers and knownLeadGen ? val_yes_yes : (not(repeatCustomers) and knownLeadGen ? val_no_yes : (repeatCustomers and not(knownLeadGen) ? val_yes_no : val_no_no))',
			new: 'repeatCustomers = {inputs.repeatCustomers} == 1; knownLeadGen = {inputs.knownLeadGen} == 1;    val_no_no = {inputs.totalCustomers};    val_no_yes = {inputs.leadsPerYear} * {inputs.leadConversionRate};    val_yes_yes = @increaseSales.retainedCustomers.new + @increaseSales.newCustomers.new;    val_yes_no = @increaseSales.retainedCustomers.new + @increaseSales.estimatedNewCustomers.new;                repeatCustomers and knownLeadGen ? val_yes_yes : (not(repeatCustomers) and knownLeadGen ? val_no_yes : (repeatCustomers and not(knownLeadGen) ? val_yes_no : val_no_no))',
		},

		transactionsPerClientPerYear: {
			current: "{inputs.transactionsPerClientPerYear}",
			changeBy: "@increaseSales.transactionsPerClientPerYear.new - {inputs.transactionsPerClientPerYear}",
			new: "{inputs.transactionsPerClientPerYear} * (1 + {profit.transactionsPerClientPerYearChangeBy})",
			diff: "@increaseSales.transactionsPerClientPerYear.new - @increaseSales.transactionsPerClientPerYear.current",
		},

		averageTransactionValue: {
			current: "repeatCustomers = {inputs.repeatCustomers} == 1; knownLeadGen = {inputs.knownLeadGen} == 1;      val_no_no = ({inputs.sales} / {inputs.totalCustomers} / {inputs.transactionsPerClientPerYear});      val_yes_no = ({inputs.sales} / {inputs.existingCustomers} / {inputs.transactionsPerClientPerYear});      val_no_yes = ({inputs.sales} / @increaseSales.totalCustomers.new / {inputs.transactionsPerClientPerYear});      val_yes_yes = val_yes_no;               repeatCustomers and knownLeadGen ? val_yes_yes : (not(repeatCustomers) and knownLeadGen ? val_no_yes : (repeatCustomers and not(knownLeadGen) ? val_yes_no : val_no_no))",

			// current_deprecated: "val2 = ({inputs.sales} / {inputs.totalCustomers} / {inputs.transactionsPerClientPerYear});      val3 = ({inputs.sales} / @increaseSales.totalCustomers.current / {inputs.transactionsPerClientPerYear});      val4 = ({inputs.sales} / {inputs.existingCustomers} / {inputs.transactionsPerClientPerYear});      val1 = (@increaseSales.totalCustomers.current == 0 ? val2 : ({inputs.existingCustomers} == 0 ? val3 : val4));      {inputs.sales} == 0 ? 0 : val1",

			changeBy: "{profit.averageTransactionValueChangeBy}",

			// new: "{inputs.sales} / @increaseSales.totalCustomers.new / @increaseSales.transactionsPerClientPerYear.current",
			new: "@increaseSales.averageTransactionValue.current + (@increaseSales.averageTransactionValue.current * {profit.averageTransactionValueChangeBy})",
			diff: "@increaseSales.averageTransactionValue.new - @increaseSales.averageTransactionValue.current",
		},

		projectedSales: {
			current: "val1 = ({inputs.totalCustomers} * {inputs.transactionsPerClientPerYear} * @increaseSales.averageTransactionValue.current); val2 = (@increaseSales.totalCustomers.current * {inputs.transactionsPerClientPerYear} * @increaseSales.averageTransactionValue.current); @increaseSales.totalCustomers.current == 0 ? val1 : val2",
			new: "val1 = (@increaseSales.totalCustomers.new * @increaseSales.transactionsPerClientPerYear.new * @increaseSales.averageTransactionValue.new); val2 = (@increaseSales.totalCustomers.new * @increaseSales.transactionsPerClientPerYear.new * @increaseSales.averageTransactionValue.new); @increaseSales.totalCustomers.new == 0 ? val1 : val2",
			diffInCash: "@increaseSales.projectedSales.new - @increaseSales.projectedSales.current",
			diffInPercentage: "@increaseSales.projectedSales.diffInCash / @increaseSales.projectedSales.current * 100",
		},

		leadsPerYear: {
			current: "{inputs.leadsPerYear}",
			changeBy: "{profit.leadsPerYearChangeBy}",
			new: "{inputs.leadsPerYear} == 0 ? 0 : ({inputs.leadsPerYear} * (1 + {profit.leadsPerYearChangeBy}))",
			diff: "@increaseSales.leadsPerYear.new - @increaseSales.leadsPerYear.current",
		},

		leadConversionRate: {
			current: "{inputs.leadConversionRate}",
			changeBy: "{profit.leadConversionRateChangeBy}",
			new: "clamp({inputs.leadConversionRate} + {profit.leadConversionRateChangeBy}, 0, 100)",
		},
		changeSales: {
			// current: "@increaseSales.totalCustomers.current * @increaseSales.transactionsPerClientPerYear.current * @increaseSales.averageTransactionValue.current",
			current: "repeatCustomers = {inputs.repeatCustomers} == 1; knownLeadGen = {inputs.knownLeadGen} == 1;      val_no_no = (@increaseProfit.clientBase.current * @increaseSales.transactionsPerClientPerYear.current * @increaseSales.averageTransactionValue.current);      val_yes_no = (@increaseSales.totalCustomers.current * @increaseSales.transactionsPerClientPerYear.current * @increaseSales.averageTransactionValue.current);      val_no_yes = (@increaseSales.newCustomers.current * @increaseSales.transactionsPerClientPerYear.current * @increaseSales.averageTransactionValue.current);      val_yes_yes = val_yes_no;               repeatCustomers and knownLeadGen ? val_yes_yes : (not(repeatCustomers) and knownLeadGen ? val_no_yes : (repeatCustomers and not(knownLeadGen) ? val_yes_no : val_no_no))",

			// new: "@increaseSales.totalCustomers.new * @increaseSales.transactionsPerClientPerYear.new * @increaseSales.averageTransactionValue.new",
			new: "repeatCustomers = {inputs.repeatCustomers} == 1; knownLeadGen = {inputs.knownLeadGen} == 1;      val_no_no = (@increaseProfit.clientBase.new * @increaseSales.transactionsPerClientPerYear.new * @increaseSales.averageTransactionValue.new);      val_yes_no = (@increaseSales.totalCustomers.new * @increaseSales.transactionsPerClientPerYear.new * @increaseSales.averageTransactionValue.new);      val_no_yes = (@increaseSales.newCustomers.new * @increaseSales.transactionsPerClientPerYear.new * @increaseSales.averageTransactionValue.new);      val_yes_yes = val_yes_no;               repeatCustomers and knownLeadGen ? val_yes_yes : (not(repeatCustomers) and knownLeadGen ? val_no_yes : (repeatCustomers and not(knownLeadGen) ? val_yes_no : val_no_no))",

			diff: "@increaseSales.changeSales.new - @increaseSales.changeSales.current",
		},
		impact: {
			cash: "@increaseSales.impact.profit * (1 - {inputs.taxRate})",
			profit: "(@increaseSales.projectedSales.new * @improveGrossMargin.grossMargin.current - {inputs.overheadExpenses}) - (@increaseSales.projectedSales.current * @improveGrossMargin.grossMargin.current - {inputs.overheadExpenses})",
		},
	},
	improveGrossMargin: {
		grossMargin: {
			current: "({inputs.sales} == 0) ? 0 : (({inputs.sales} - {inputs.directCost}) / {inputs.sales})",
			changeBy: "{profit.grossMarginChangeBy}",
			new: "clamp(@improveGrossMargin.grossMargin.current + {profit.grossMarginChangeBy}, null, 1)",
			diff: "@improveGrossMargin.grossMargin.new - @improveGrossMargin.grossMargin.current",
		},
		grossProfit: {
			current: "val_off = (@increaseSales.sales.current - @improveGrossMargin.directCost.current);         val_on = (@increaseSales.changeSales.current - @improveGrossMargin.directCost.current);         {profit.detailedSalesDrivers} != 1 ? val_off : val_on",

			new: "val1 = (@increaseSales.sales.new - @improveGrossMargin.directCost.new);      val2 = (@increaseSales.changeSales.new - @improveGrossMargin.directCost.new);         {profit.detailedSalesDrivers} != 1 ? val1 : val2",
			diff: "@improveGrossMargin.grossProfit.new - @improveGrossMargin.grossProfit.current",
		},
		directCost: {
			current: "{profit.detailedSalesDrivers} != 1 ? (@increaseSales.sales.current * (1 - @improveGrossMargin.grossMargin.current)) : (@increaseSales.changeSales.current * (1 - @improveGrossMargin.grossMargin.current))",

			new: "{profit.detailedSalesDrivers} != 1 ? (@increaseSales.sales.new * (1 - @improveGrossMargin.grossMargin.new)) : (@increaseSales.changeSales.new * (1 - @improveGrossMargin.grossMargin.new))",
		},
		impact: {
			cash: "@improveGrossMargin.impact.profit * (1 - {inputs.taxRate})",
			profit: "@improveGrossMargin.grossProfit.new - @improveGrossMargin.grossProfit.current - @increaseSales.sales.impact.profit",
			// _profit: "@improveGrossMargin.grossProfit.new - @improveGrossMargin.grossProfit.current - @increaseSales.sales.impact.profit - @increaseSales.impact.profit",
		},
	},
	increaseProfit: {
		clientBase: {
			current: "@increaseSales.totalCustomers.new",
			new: "@increaseSales.totalCustomers.new * {profit.clientBaseChangeBy} + @increaseSales.totalCustomers.new",
			diff: "@increaseProfit.clientBase.new - @increaseProfit.clientBase.current",
		},
		overheadExpenses: {
			current: "{inputs.overheadExpenses}",
			changeBy: "{profit.overheadExpensesChangeBy}",
			new: "{inputs.overheadExpenses} + {profit.overheadExpensesChangeBy}",
			diff: "@increaseProfit.overheadExpenses.new - @increaseProfit.overheadExpenses.current",
			impact: {
				profit: "@increaseProfit.overheadExpenses.changeBy * -1",
			},
		},
		netProfit: {
			current: "@improveGrossMargin.grossProfit.current - {inputs.overheadExpenses} + {inputs.otherIncome}",
			new: "@improveGrossMargin.grossProfit.new - @increaseProfit.overheadExpenses.new + {inputs.otherIncome}",
			diff: "@increaseProfit.netProfit.new - @increaseProfit.netProfit.current",
		},
		netMargin: {
			current: "{inputs.sales} == 0 ? 0 : ({profit.detailedSalesDrivers} != 1 ? (@increaseProfit.netProfit.current / @increaseSales.sales.current * 100) : (@increaseProfit.netProfit.current / @increaseSales.changeSales.current * 100))",

			new: "{inputs.sales} == 0 ? 0 : ({profit.detailedSalesDrivers} != 1 ? (@increaseProfit.netProfit.new / @increaseSales.sales.new * 100) : (@increaseProfit.netProfit.new / @increaseSales.projectedSales.new * 100))",

			diff: "@increaseProfit.netMargin.new - @increaseProfit.netMargin.current",
		},
		value: {
			current: "@increaseProfit.netProfit.current * {inputs.valuationMultiple}",
			new: "@increaseProfit.netProfit.new * {inputs.valuationMultiple}",
			diff: "@increaseProfit.value.new - @increaseProfit.value.current",
		},
		impact: {
			cash: "@increaseProfit.impact.profit * (1 - {inputs.taxRate})",
			profit: "@increaseProfit.overheadExpenses.current - @increaseProfit.overheadExpenses.new",
		},
	},
	increaseCashflow: {
		debtorDays: {
			current: "{inputs.sales == 0} ? 0 : ({inputs.accountsReceivable} / {inputs.sales} * 365)",
			changeBy: "{cashflow.debtorDaysChangeBy}",
			new: "@increaseCashflow.debtorDays.current + {cashflow.debtorDaysChangeBy}",
			impact: {
				cash: "{inputs.accountsReceivable} - ({inputs.sales} / 365 * @increaseCashflow.debtorDays.new)",
			},
		},
		inventoryDays: {
			current: "{inputs.directCost} == 0 ? 0 : ({inputs.inventory} == 0 ? 0 :({inputs.inventory} / {inputs.directCost} * 365))",
			changeBy: "{cashflow.inventoryDaysChangeBy}",
			new: "@increaseCashflow.inventoryDays.current + {cashflow.inventoryDaysChangeBy}",
			impact: {
				cash: "({cashflow.inventoryDaysChangeBy} * {inputs.directCost} / 365) * -1",
				// cash: "{inputs.inventory} - {inputs.directCost} / 365 * @increaseCashflow.inventoryDays.new",
			},
		},
		payableDays: {
			current: "{inputs.directCost} == 0 ? 0 :({inputs.accountsPayable} == 0 ? 0 :({inputs.accountsPayable} / {inputs.directCost} *365))",
			changeBy: "{cashflow.payableDaysChangeBy}",
			new: "@increaseCashflow.payableDays.current + {cashflow.payableDaysChangeBy}",
			impact: {
				cash: "({cashflow.payableDaysChangeBy} * {inputs.directCost} / 365) * -1",
				// cash: "{inputs.accountsPayable} - ({inputs.directCost} / 365 * @increaseCashflow.payableDays.new)",
			},
		},
		cashConversionCycle: {
			current: "@increaseCashflow.debtorDays.current + @increaseCashflow.inventoryDays.current - @increaseCashflow.payableDays.current",
			new: "@increaseCashflow.debtorDays.new + @increaseCashflow.inventoryDays.new - @increaseCashflow.payableDays.new",
			diff: "@increaseCashflow.cashConversionCycle.new - @increaseCashflow.cashConversionCycle.current",
		},
		impact: {
			cash: "@increaseCashflow.debtorDays.impact.cash + @increaseCashflow.inventoryDays.impact.cash - @increaseCashflow.payableDays.impact.cash",
		}
	},
	improveROI: {
		revenueByTeamMember: {
			current: "{inputs.sales} == 0 ? 0 : ({inputs.sales} / {inputs.teamMembers})",
			changeBy: "{returnOnInvestment.revenueByTeamMemberChangeBy}",
			new: "@improveROI.revenueByTeamMember.current * (1 + {returnOnInvestment.revenueByTeamMemberChangeBy})",
			diff: "@improveROI.revenueByTeamMember.new - @improveROI.revenueByTeamMember.current",
		},
		currentEffectiveRate: {
			current: "@improveROI.revenueByTeamMember.current / 1800",
			new: "@improveROI.revenueByTeamMember.new / 1800",
			diff: "@improveROI.currentEffectiveRate.new - @improveROI.currentEffectiveRate.current",
		},
		impact: {
			cash: "@improveROI.impact.profit * (1 - {inputs.taxRate})",
			profit: "(@improveROI.revenueByTeamMember.new - @improveROI.revenueByTeamMember.current) * {inputs.teamMembers}",
		},
	},
	totalImpact: {
		// cash: "@increaseSales.sales.impact.cash + @increaseSales.impact.cash + @improveGrossMargin.impact.cash + @increaseProfit.impact.cash + @increaseCashflow.impact.cash + @improveROI.impact.cash",

		cash: "fix(@increaseCashflow.impact.cash) + fix(@increaseSales.sales.impact.cash) + fix(@improveGrossMargin.impact.cash) + fix(@increaseProfit.impact.cash) + fix(@improveROI.impact.cash)",

		// profit: "@increaseSales.sales.impact.profit + @increaseSales.impact.profit + @improveGrossMargin.impact.profit + @increaseProfit.impact.profit + @improveROI.impact.profit",

		profit: "fix(@increaseSales.sales.impact.profit) + fix(@improveGrossMargin.impact.profit) + fix(@increaseProfit.impact.profit) + fix(@improveROI.impact.profit)",

		// value: "@totalImpact.profit * {inputs.valuationMultiple}",
	},
	potentialValueImpact: {
		profit: "@totalImpact.profit * {inputs.valuationMultiple}",
	},
	// _testVariables: {
	// 	fixNumber: "fix(@totalImpact.notDefined) + 100",
	// 	valueArray: "x = {inputs.sales}; x \n x * 12",
	// 	singleValue: "x = @improveGrossMargin.grossMargin.current ; y = {inputs.sales}; z = x * y; z",
	// 	refer: "val = @_testVariables.singleValue; 1 == 2 ? val : 2",
	// 	refer2: "@_testVariables.refer",
	// 	refer3: [
	// 		"@_testVariables.singleValue",
	// 		"@_testVariables.refer"
	// 	],
	// },
};
import { create, all } from 'mathjs';
import objectPath from 'object-path';
import thousands from 'thousands';

const math = create(all);

/**
 * 
 * @param {object} originalFormulas an object contains math expressions.
 * @param {object} formulas an object contains math expressions.
 * @param {object} data data JSON
 * 
 * @returns {object} Calculated results. The results structure is the same as the formula structure.
 * 
 * @example
 * - data JSON
 * {
 *   price: 10,
 *   sales: {
 *     Jan: 5,
 *     Feb: 8,
 *     Mar: 16
 *   }
 * }
 * 
 * - formulas JSON
 * {
 *   totalSold: "{sales.Jan} + {sales.Feb} + {sales.Mar}",
 *   totalPrice: "({sales.Jan} + {sales.Feb} + {sales.Mar}) * {price}",
 *   totalSoldRef: "@totalSold"
 * }
 * 
 * // More expressions, check out https://mathjs.org/docs/expressions
 * 
 * - results
 * {
 *   totalSold: 29,
 *   totalPrice: 290,
 * }
 * 
 * More details, please refer to /docs/formula.md
 */
export const executeFormula = (originalFormulas, formulas, data) => {
	const debug = false;
	// console.log('----- originalFormulas', originalFormulas);
	// console.log('----- formulas', formulas);
	// console.log('----- data', data);
	const calculation_results = {};

	Object.keys(formulas || {}).forEach(item => {
		let formula = formulas[item];
		const formula_type = formula?.constructor?.name?.toLowerCase();
		const regexp_constant = /\{([^{}]+)\}/g;

		/**
		 * 
		 * @param {number|object} result - evaluation result
		 * @returns {number}
		 */
		const getResult = (result) => {
			// console.log('result', result, 'type', result?.constructor?.name);
			if(result?.constructor?.name === 'DenseMatrix'){
				// console.log('DeseMatrix', result?.toArray());
				return result?.toArray()?.[0];
			}

			return result?.entries?.[0] ?? result; // return ResultSet value or number if it is not ResultSet object
		};

		const processRaw = (formula) => {
			try {
				if (formula?.constructor?.name?.toLowerCase() !== 'string') {
					return formula;
				}

				// replace @reference with formula
				const max_recursion = 100;
				let recursion_count = 1;

				const regexp_formula_ref = /@([\w.[\]]+)(?!\w)/g;

				while (regexp_formula_ref.test(formula) && recursion_count <= max_recursion) {
					formula = formula.replace(regexp_formula_ref, (match, group) => {

						// console.log(item, '\nreplace quote\nmatch', match, '\ngroup', group);

						if (group) {
							let value = objectPath.get(originalFormulas, group);
							if ([ undefined, null, '' ].includes(value)) {
								console.warn(`"${group}" cannot be found or the value is invalid. Please check the spelling or the refered value.`, 'refered value:', value);
								value = NaN;
							}
							// console.log(item, 'refer to', group, '\n - formulas', formulas, ' - \nformula', value);
							if ([ null, undefined ].includes(value)) {
								return match;
							}

							if (value?.constructor?.name?.toLowerCase() === 'string' && value?.search(regexp_formula_ref) >= 0) {
								value = processRaw(value);
							}

							// use constants
							let _value;
							let has_ref = false;
							let has_const = false;
							if (value?.constructor?.name?.toLowerCase() === 'string' && value?.search(regexp_constant) >= 0) {
								_value = value.replace(regexp_constant, (match, group) => {
									if (group) {
										const value = objectPath.get(data, group);
										if ([ null, undefined ].includes(value)) {
											return NaN;
										}
										return value;
									}
									return match; // Keep the original if no replacement is specified
								});
								has_ref = _value.search(regexp_formula_ref) >= 0;
								has_const = _value.search(regexp_constant) >= 0;
							} else {
								_value = value;
							}

							debug && console.log('checking any ref and const in', `"${_value}"`, '\n - ref', has_ref, '\n - const', has_const);

							// no more referece and constants found in the formula
							if (!has_ref && !has_const) {
								try {
									debug && console.log('clean formula, evaluate', `"${_value}"\n\n`);
									const result = getResult(math.evaluate(String(_value), customFunctions) ?? NaN);
									debug && console.log('clean formula result:', result);
									return result;
								} catch (err) {
									console.error('clean formula, evaluate failed', `\nformula: ${_value}\n`, err);
								}
							}

							debug && console.log('found ref', match, '\ngroup', group, '\nvalue', _value);

							// // calculate separated statements
							// const regexp_separator = /[;|\\n]/g;
							// if (value?.constructor?.name?.toLowerCase() === 'string' && value?.search(regexp_separator) >= 0) {
							// 	console.log('evaluate separated statements', `"${value}"`);
							// 	const result = getResult(math.evaluate(value, customFunctions) ?? NaN);
							// 	console.log('separated statements result:', result);
							// 	return result;
							// }

							return _value;
						}

						return match; // Keep the original if no replacement is specified
					});


					recursion_count++;

					if (recursion_count >= max_recursion) {
						console.warn(`The formula reference reached the max recursion limit of ${max_recursion}. Please reduce the formula references to increase the performance.`);
						break;
					}
				}

				debug && console.log('🚩 replaced refs', `\n${formula}`);

				// use constants
				formula = formula.replace(regexp_constant, (match, group) => {
					if (group) {
						const value = objectPath.get(data, group);
						if ([ null, undefined ].includes(value)) {
							return NaN;
						}

						return value;
					}

					return match; // Keep the original if no replacement is specified
				});

				debug && console.log('🚩 replaced consts', `\n${formula}`);

				try {
					debug && console.log('🚩 finalize formula', `\n${formula}`);
					const result = getResult(math.evaluate(formula, customFunctions) ?? NaN);
					debug && console.log('✅ finalize formula result:', result);
					return result;
				} catch (err) {
					console.error('finalize formula, evaluate failed', `\nformula: ${formula}\n`, err);
				}
			} catch (err) {
				console.error('Error', err);
				return NaN;
			}
		};


		if (formula_type === 'string') {
			calculation_results[item] = processRaw(formula);
		} else if (formula_type === 'object') {
			calculation_results[item] = executeFormula(originalFormulas, formula, data);
		} else if (formula_type === 'array') {
			calculation_results[item] = formula.map(expr => {
				try {
					const formula_arr = expr.replace(regexp_constant, (match, group) => {
						if (group) {
							const value = objectPath.get(data, group);
							if ([ null, undefined ].includes(value)) {
								return NaN;
							}
							return value;
						}
						return match; // Keep the original if no replacement is specified
					});
					try {
						debug && console.log('in array, evaluate', `"${formula_arr}"\n\n`);
						// const result = getResult(math.evaluate(formula_arr, customFunctions) ?? NaN);
						const result = processRaw(formula_arr);
						debug && console.log('in array result', `"${formula_arr}"\n\n`);
						return result;
					} catch (err) {
						console.error('in array, evaluate failed', `\nformula: ${formula_arr}\n`, err);
					}
				} catch (err) {
					return NaN;
				}
			});
		} else {
			calculation_results[item] = NaN;
		}
	});

	return calculation_results;
};

const customFunctions = {
	/**
	 * Clamps a number
	 * @param {number} value 
	 * @param {number | null} min 
	 * @param {number | null} max
	 * @returns {number}
	 */
	clamp: (value, min, max) => {
		if (Number.isFinite(min) && Number.isFinite(max)) {
			// both min & max specified
			return Math.min(Math.max(value, min), max);
		} else if (Number.isFinite(min)) {
			// only min specified
			return Math.max(value, min);
		} else if (Number.isFinite(max)) {
			// only max specified
			return Math.min(value, max);
		}
	},
	/**
	 * Determines if two values are equal
	 * @param {number | string} value1 
	 * @param {number | string} value2 
	 * @returns {boolean}
	 */
	isEqual: (value1, value2) => {
		return value1 == value2;
	},
	/**
	 * Fix NaN, falls back to `0`
	 * @param {number | string} value 
	 * @returns {number}
	 */
	fix: (value) => {
		value = Number(value);
		return isNaN(value) ? 0 : value;
	},
};

export const beautifyToFixed = (number = 0, decimalCount = 0) => {
	try{
		return Number(number?.toFixed(decimalCount));
	}catch(err){
		console.error(err);
		return NaN;
	}
};

export const clampNumber = (value, min, max) => {
	if (Number.isFinite(min) && Number.isFinite(max)) {
		// both min & max specified
		return Math.min(Math.max(value, min), max);
	} else if (Number.isFinite(min)) {
		// only min specified
		return Math.max(value, min);
	} else if (Number.isFinite(max)) {
		// only max specified
		return Math.min(value, max);
	}
};

export const addPlusMinusToNumber = (number = 0, enableThousands = true, prefixOrSuffix) => {
	if(!Number.isFinite(number)) return NaN;

	let result = Math.abs(number);

	if(enableThousands){
		result = thousands(result);
	}

	if(prefixOrSuffix?.length){
		if(prefixOrSuffix === '%'){
			result = result + prefixOrSuffix;
		}else{
			result = prefixOrSuffix + result;
		}
	}

	if(number > 0){
		result = '+' + result;
	}else if(number < 0){
		result = '-' + result;
	}

	return result;
};


/**
 * Gets the value of a given search param key and remove it from the address bar
 * @param {string} key 
 * @returns { string | undefined }
 */
export const getAndRemoveSearchParam = (key) => {
	if (key) {
		const params = new URLSearchParams(window.location.search);	
		const valFromParams = params.get(key);

		params.delete(key); 

		const newSearch = params.toString();
		window.history.replaceState({}, '', `${window.location.pathname}?${newSearch}`);
		
		return valFromParams;	
	}
};


/*
let parser = math.parser();
let formula = `width = 100;
width * 2
100`;

formula.split('\n').map(val => parser.evaluate(val));
*/
import { ChartOptions } from "chart.js";

export const defaultCalculationTitle = 'Value Gap Analysis';

export const pieChartOptions: ChartOptions<'pie'> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'bottom',
		},
	}
};
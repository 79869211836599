import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import BigNumber from 'bignumber.js';
import { 
	Box,
	Slider,
} from '@mui/material';
import _ from 'lodash';

import { usePrev } from '../hooks';
import { NumericInput } from './';
import { numberInput } from './calculator/constants';

const SliderInput = (props) => {
	const [ inputValue, setInputValue ] = useState(0);
	/* eslint-disable-next-line */
	const { onChange, type, value, currency, min, max, step, sliderMin, sliderMax, size, disabled, ...restProps } = props;
	const prevInputValue = usePrev(inputValue);

	useEffect(() => {
		const update = () => {
			if (props.value != prevInputValue) {
				setInputValue(props.value);
			}
		};

		if(props.disableDebouncing){
			update();
		}else{
			const debouncedFunc = _.debounce(() => {
				update();
			}, props.debounceDelay);
			debouncedFunc();

			return debouncedFunc.cancel;
		}
	}, [ props.value ]);

	const handleOnChange = (val) => {
		props.onChange({
			target: {
				name: props.name,
				value: val ?? inputValue,
			}
		});
	};

	const handleInputChange = e => {
		setInputValue(e.target.value);
		handleOnChange(e.target.value);
	};

	return (
		<Box { ...{ ...restProps, sx: { ...props.sx, display: 'flex', alignItems: 'center', width: '100%' } } }>
			<NumericInput {...numberInput}
				type={type}
				{ ...type === 'currency' && { placeholder: currency } }
				{ ...type === 'percentage' && { placeholder: '%' } }
				size={size}
				step={step}
				min={min * 100}
				max={max * 100}
				disableDebouncing
				value={inputValue}
				onChange={handleInputChange}
				disabled={disabled}
			/>
			<Slider
				size={size}
				step={step}
				min={sliderMin}
				max={sliderMax}
				valueLabelFormat={val => {
					switch(type){
						case 'currency':
						case 'number':
						default:
							break;
						case 'percentage':
							val = Number(new BigNumber(val).times(100));
							break;
					}
					return props.valueLabelTemplate?.replace(/\{value\}/gm, val);
				}}
				valueLabelDisplay="auto"
				sx={{ mx: 2 }}
				value={inputValue}
				onChange={handleInputChange}
				disabled={disabled}
			/>
		</Box>
	);
};

export default SliderInput;

SliderInput.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.number,	
		PropTypes.string,	
	]),
	currency: PropTypes.string,
	onChange: PropTypes.func,
	variant: PropTypes.oneOf([ 'outlined', 'filled', 'standard' ]),
	size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
	type: PropTypes.oneOf([ 'currency', 'number', 'percentage' ]),
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.nubmer,
	sliderMin: PropTypes.nubmer,
	sliderMax: PropTypes.nubmer,
	valueLabelTemplate: PropTypes.string, // `{value} days`
	disableDebouncing: PropTypes.bool,
	debounceDelay: PropTypes.number,
	disabled: PropTypes.bool,
	showInnerSpinButtons: PropTypes.bool,
	sx: PropTypes.object,
};

SliderInput.defaultProps = {
	onChange: () => {},
	variant: 'outlined',
	size: 'medium',
	currency: '$',
	type: 'number',
	min: Number.MIN_SAFE_INTEGER,
	max: Number.MAX_SAFE_INTEGER,
	sliderMin: -50,
	sliderMax: 50,
	valueLabelTemplate: `{value}`,
	disableDebouncing: false,
	debounceDelay: 300,
	disabled: false,
	showInnerSpinButtons: true,
};
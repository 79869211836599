import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { 
	Box,
	Grid,
	Card,
	CardContent,
	Divider,
	Tooltip,
	Typography,
} from '@mui/material';
import _ from 'lodash';
import thousands from 'thousands';

import { 
	InfoOutlined as IconInfo,
} from '@mui/icons-material';

import { SliderInput } from '../../';
import { beautifyToFixed, addPlusMinusToNumber } from '../../../utilities';
import { tooltip, card, cardContent } from '../constants';
import { CurrencyContext } from '../contexts';

const ROI = (props) => {
	const [ data, setData ] = useState({});
	const { calculationResults } = props;
	const { revenueByTeamMember, currentEffectiveRate, impact } = calculationResults?.improveROI || {};
	const currency = useContext(CurrencyContext);

	useEffect(() => {
		if (!_.isEqual(props.values, data)) {
			setData(props.values);
		}
	}, [ props.values ]);

	const changeFieldValue = (field, value) => {
		setData(prevState => {
			const new_state = {
				...prevState,
				[field]: value,
			};

			if (props.onChange?.constructor?.name === 'Function') props.onChange(new_state);

			return new_state;
		});
	};

	return (
		<Card {...card}>
			<CardContent {...cardContent}>
				<Typography variant="h6" sx={{ px: 2.5, py: 1.5 }}>Return on Investment (ROI)</Typography>

				<Box>

					<Box sx={{ px: 1.5 }}>
						<Grid {...gridContainerHeader}>
							<Grid {...gridColumn1}>
								<Typography sx={{ color: 'gray' }}>Strategy</Typography>
							</Grid>
							<Grid {...gridColumn2}>
								<Typography sx={{ color: 'gray' }}>Change</Typography>
							</Grid>
							<Grid {...gridColumn3}>
								<Typography sx={{ color: 'gray' }}>Impact</Typography>
							</Grid>
						</Grid>
					</Box>

					<Divider />

					<Box sx={{ px: 1.5 }}>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Box>
									<Typography sx={{ mr: 1 }}>
										<Typography sx={{ mr: 1, display: 'inline' }}>Increase revenue per FTE</Typography>
										<Tooltip {...tooltip} title="ROI is a measure of your team's overall contribution to sales. It's expressed as total sales divided by full time equivalent (FTE) team members. Your biggest assets in your business are often your team members. Monitoring and measuring the return you are getting from your team is a great way to improve your overall results.">
											<IconInfo fontSize="small" />
										</Tooltip>
									</Typography>
								</Box>
								{
									revenueByTeamMember?.current === revenueByTeamMember?.new ?
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Revenue per FTE is currently {currency}{thousands(beautifyToFixed(revenueByTeamMember?.current))}</Typography>
										:
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change revenue per FTE from {currency}{thousands(beautifyToFixed(revenueByTeamMember?.current))} to <strong>{currency}{thousands(beautifyToFixed(revenueByTeamMember?.new))}</strong></Typography>
								}
							</Grid>
							<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
								<SliderInput
									type="percentage"
									size="small"
									sliderMin={-.2}
									sliderMax={.2}
									step={.01}
									valueLabelTemplate="{value}%"
									value={data?.revenueByTeamMemberChangeBy}
									onChange={e => changeFieldValue('revenueByTeamMemberChangeBy', parseFloat(e.target.value))}
								/>
							</Grid>
							<Grid {...gridColumn3}>
								<Typography>{addPlusMinusToNumber(beautifyToFixed(revenueByTeamMember?.diff), true, currency)}/FTE</Typography>
							</Grid>
						</Grid>

						<Grid {...gridContainer}>
							<Grid {...gridColumn1}>
								<Box>
									<Typography sx={{ mr: 1 }}>
										<Typography sx={{ mr: 1, display: 'inline' }}>Increase effective hourly rate</Typography>
										<Tooltip {...tooltip} title="Assumes 1,800 hours worked per year per full time team member, based on 45 weeks x 40 hours per week.">
											<IconInfo fontSize="small" />
										</Tooltip>
									</Typography>
								</Box>
								{
									currentEffectiveRate?.current === currentEffectiveRate?.new ?
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Effective hourly rate is currently {currency}{thousands(beautifyToFixed(currentEffectiveRate?.current))}/hour</Typography>
										:
										<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change effective hourly rate from {currency}{thousands(beautifyToFixed(currentEffectiveRate?.current))}/hour to <strong>{currency}{thousands(beautifyToFixed(currentEffectiveRate?.new))}/hour</strong></Typography>
								}
							</Grid>
							<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}></Grid>
							<Grid {...gridColumn3}>
								<Typography>{addPlusMinusToNumber(beautifyToFixed(currentEffectiveRate?.diff), true, currency)}/hour</Typography>
							</Grid>
						</Grid>

					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', borderTop: 1, borderColor: 'divider', p: 1 }}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant="subtitle2" sx={{ fontWeight: 700, textAlignt: 'right', ml: 3, mr: 2 }}>Cash Impact</Typography>
							<Typography variant="h6">{addPlusMinusToNumber(beautifyToFixed(impact?.cash), true, currency)}</Typography>
						</Box>

						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant="subtitle2" sx={{ fontWeight: 700, textAlignt: 'right', ml: 3, mr: 2 }}>Profit Impact</Typography>
							<Typography variant="h6">{addPlusMinusToNumber(beautifyToFixed(impact?.profit), true, currency)}</Typography>
						</Box>
					</Box>

				</Box>
			</CardContent>
		</Card>
	);
};

export default ROI;

export const propTypes = {
	values: PropTypes.shape({
		revenueByTeamMemberChangeBy: PropTypes.number.isRequired,
	}).isRequired,
	calculationResults: PropTypes.object.isRequired,
	onChange: PropTypes.func,
};

ROI.propTypes = propTypes;

ROI.defaultProps ={
	values: {
		revenueByTeamMemberChangeBy: 0,
	},
};

const gridContainer = {
	container: true,
	columnSpacing: 2,
	rowSpacing: 2,
	sx: {
		p: 1,
		mb: 2,
	},
	direction: "row",
	justifyContent: "space-between",
	alignItems: "baseline",
};

const gridContainerHeader = {
	...gridContainer,
	sx: {
		...gridContainer.sx,
		mb: 0,
	},
};

const gridColumn = {
	item: true,
	sx: {
		display: 'flex', 
		flexDirection: 'column',
		alignItems: 'start',
	},
};

const gridColumn1 = {
	...gridColumn,
	xs: 5,
	sm: 5,
	md: 5,
	lg: 5,
};

const gridColumn2 = {
	...gridColumn,
	sx: {
		...gridColumn.sx,
		justifyContent: 'end',
	},
	xs: 4,
	sm: 4,
	md: 4,
	lg: 4,
};

const gridColumn3 = {
	...gridColumn,
	sx: {
		...gridColumn.sx,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'end',
		textAlign: 'right',
	},
	xs: 3,
	sm: 3,
	md: 3,
	lg: 3,
};
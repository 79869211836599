import React, { useEffect, useState, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

import {
	Stack,
	IconButton,
} from '@mui/material';

import { 
	ExpandLess as IconExpandLessIcon,
	ExpandMore as IconExpandMoreIcon,
} from '@mui/icons-material';

import { 
	TextField,
	OutlinedInput,
} from '@mui/material';

import { NumericFormat } from 'react-number-format';
import { usePrev } from '../hooks';
import { clampNumber } from '../utilities';

const NumericInput = (props) => {

	const [ inputValue, setInputValue ] = useState(props.value);
	/* eslint-disable-next-line */
	const { onChange, type, value, currency, min, max, showInnerSpinButtons, step, ...restProps } = props;
	const prevInputValue = usePrev(inputValue);

	const inputProps = useMemo(() => {
		switch(type?.toLowerCase()){
			case 'percentage':
				return {
					InputProps: {
						inputComponent: PercentageCustom,
						inputProps: {
							min, 
							max,
							showInnerSpinButtons,
							step,
							// style: {
							// 	textAlign: 'right',
							// }
						},
					}
				};

			case 'currency':
				return {
					InputProps: {
						inputComponent: NumericFormatCustom,
						inputProps: {
							min, 
							max,
							currency,
							showInnerSpinButtons,
							step,
						},
					}
				};

			default:
				return {};
		}
	}, []);

	useEffect(() => {
		const update = () => {
			if (props.value != prevInputValue) {
				setInputValue(props.value);
			}
		};

		if(props.disableDebouncing){
			update();
		}else{
			const debouncedFunc = _.debounce(() => {
				update();
			}, props.debounceDelay);
			debouncedFunc();

			return debouncedFunc.cancel;
		}
	}, [ props.value ]);

	const handleOnChange = (val) => {
		props.onChange({
			target: {
				name: props.name,
				value: val ?? inputValue,
			}
		});
	};

	const handleInputBlur = e => {
		let clamped_value = clampNumber(e.target.value, min, max);
		setInputValue(clamped_value);
		handleOnChange(clamped_value);
	};

	const handleCustomInputBlur = e => {
		let val = Number(e.target.value?.replace(/[^0-9-\\.]/g, ''));

		val = clampNumber(val, min, max);

		if(/%$/.test(e.target.value)){
			val = val / 100;
		}

		setInputValue(val);
		handleOnChange(val);
	};

	const handleInputChange = e => {
		setInputValue(e.target.value);
		handleOnChange(e.target.value);
	};

	switch(type?.toLowerCase()){
		case 'number':
		default:
			return (
				<OutlinedInput
					type="number"
					value={inputValue}
					onChange={handleInputChange}
					onBlur={handleInputBlur}
					inputProps={{ min, max }}
					error={inputValue !== undefined && inputValue !== null && !Number.isFinite(parseFloat(inputValue))}
					{...restProps}
				/>
			);

		case 'currency':
		case 'percentage':
			return <TextField
				value={inputValue}
				onChange={handleInputChange}
				onBlur={handleCustomInputBlur}
				currency={props.currency}
				error={inputValue !== undefined && inputValue !== null && !Number.isFinite(parseFloat(inputValue))}
				{...inputProps}
				{...restProps}
			/>;
	}
};

NumericInput.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.number,	
		PropTypes.string,	
	]),
	currency: PropTypes.string,
	onChange: PropTypes.func,
	variant: PropTypes.oneOf([ 'outlined', 'filled', 'standard' ]),
	size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
	type: PropTypes.oneOf([ 'currency', 'number', 'percentage' ]),
	min: PropTypes.number,
	max: PropTypes.number,
	disableDebouncing: PropTypes.bool,
	disabled: PropTypes.bool,
	debounceDelay: PropTypes.number,
	showInnerSpinButtons: PropTypes.bool,
	step: PropTypes.number,
};

NumericInput.defaultProps = {
	onChange: () => {},
	variant: 'outlined',
	size: 'medium',
	currency: '$',
	type: 'number',
	min: Number.MIN_SAFE_INTEGER,
	max: Number.MAX_SAFE_INTEGER,
	disableDebouncing: false,
	disabled: false,
	debounceDelay: 300,
	showInnerSpinButtons: true,
};

export default NumericInput;






const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {

	const { onChange, currency, showInnerSpinButtons, ...other } = props;

	return (
		<Stack flex={1} direction="row" alignItems="center" justifyContent="space-between">

			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				thousandSeparator
				valueIsNumericString
				prefix={currency}
				sx={{ flexGrow: 1 }}
			/>
			{
				showInnerSpinButtons && !props?.disabled && <Stack sx={{ flexGrow: 0 }}>
					<IconButton sx={{ p: 0 }}
						onClick={() => {
							let val = parseFloat(props.value);
							val += props.step;
							onChange({
								target: {
									name: props.name,
									value: val,
								},
							});
						}}
					><IconExpandLessIcon fontSize="small" /></IconButton>
					<IconButton sx={{ p: 0 }}
						onClick={() => {
							let val = parseFloat(props.value);
							val -= props.step;
							onChange({
								target: {
									name: props.name,
									value: val,
								},
							});
						}}
					><IconExpandMoreIcon fontSize="small" /></IconButton>
				</Stack>
			}
		</Stack>
	);
});

NumericFormatCustom.defaultProps = {
	currency: '$',
	disabled: false,
	showInnerSpinButtons: true,
	step: 1,
};

NumericFormatCustom.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	currency: PropTypes.string,
	value: PropTypes.string,
	step: PropTypes.number,
	disabled: PropTypes.bool,
	showInnerSpinButtons: PropTypes.bool,
};





const PercentageCustom = React.forwardRef(function PercentageCustom(props, ref) {
	const { onChange, value, showInnerSpinButtons, ...other } = props;
	const _value = useMemo(() => {
		const num = new BigNumber(value);
		const factor = new BigNumber(100);
		let val = Number(num.times(factor));
		return val;
	}, [ value ]);

	return (
		<Stack flex={1} direction="row" alignItems="center" justifyContent="space-between">
			<NumericFormat
				{...other}
				getInputRef={ref}
				value={_value}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: Number(new BigNumber(parseFloat(values.value)).dividedBy(new BigNumber(100))),
						},
					});
				}}
				suffix="%"
				thousandSeparator
				valueIsNumericString
				sx={{ flexGrow: 1 }}
			/>
			{
				showInnerSpinButtons && !props?.disabled && <Stack sx={{ flexGrow: 0 }}>
					<IconButton sx={{ p: 0 }}
						onClick={() => {
							let val = parseFloat(_value);
							val += Number(new BigNumber(props.step).multipliedBy(new BigNumber(100)));
							onChange({
								target: {
									name: props.name,
									value: Number(new BigNumber(val).dividedBy(new BigNumber(100))),
								},
							});
						}}
					><IconExpandLessIcon fontSize="small" /></IconButton>
					<IconButton sx={{ p: 0 }}
						onClick={() => {
							let val = parseFloat(_value);
							val -= Number(new BigNumber(props.step).multipliedBy(new BigNumber(100)));
							onChange({
								target: {
									name: props.name,
									value: Number(new BigNumber(val).dividedBy(new BigNumber(100))),
								},
							});
						}}
					><IconExpandMoreIcon fontSize="small" /></IconButton>
				</Stack>
			}
		</Stack>
	);
});

PercentageCustom.defaultProps = {
	showInnerSpinButtons: true,
	step: 1,
};

PercentageCustom.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	showInnerSpinButtons: PropTypes.bool,
	step: PropTypes.number,
};
import React, { useContext, useMemo } from "react";
import { 
	Box,
	Card,
	CardContent,
	Divider,
	Grid,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Stack,
} from '@mui/material';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import PropTypes from "prop-types";
import { beautifyToFixed, addPlusMinusToNumber, clampNumber } from "../../../utilities";
import { CurrencyContext } from "../contexts";
import { pieChartOptions } from "../../../constants";

ChartJS.register(ArcElement, Tooltip, Legend);

const Summary = (props) => {
	const currency = useContext(CurrencyContext);

	const {
		increaseSales,
		increaseCashflow,
		increaseProfit,
		improveROI,
		improveGrossMargin,
		totalImpact,
		potentialValueImpact,
	} = props?.calculationResults || {};

	const pieDataCash = useMemo(() => {
		return {
			labels: [
				'Improve cash conversion', 
				'Increase sales', 
				'Increase gross margin', 
				'Reduce overheads/increase investment', 
				'Increase ROI',
			],
			datasets: [
				{
					label: currency,
					data: [
						clampNumber(increaseCashflow?.impact?.cash?.toFixed() || 0, 0), 
						clampNumber(increaseSales?.sales?.impact?.cash?.toFixed() || 0, 0), 
						clampNumber(improveGrossMargin?.impact?.cash?.toFixed() || 0, 0), 
						clampNumber(increaseProfit?.impact?.cash?.toFixed() || 0, 0),
						clampNumber(improveROI?.impact?.cash?.toFixed() || 0, 0),
					],
					backgroundColor: [
						'rgb(51,20,53)',
						'rgb(173,163,214)',
						'rgb(181,225,206)',
						'rgb(22,59,68)',
						'rgb(239,103,55)',
					],
					borderColor: "white",
					borderWidth: 3,
				},
			],
		};
	}, [ props.calculationResults ]);

	const pieDataImpact = useMemo(() => {
		return {
			labels: [ 
				'Improve cash conversion', 
				'Increase sales', 
				'Increase gross margin', 
				'Reduce overheads/increase investment', 
				'Increase ROI',
			],
			datasets: [
				{
					label: currency,
					data: [
						0,
						clampNumber(increaseSales?.sales?.impact?.profit?.toFixed() || 0, 0),
						clampNumber(improveGrossMargin?.impact?.profit?.toFixed() || 0, 0),
						clampNumber(increaseProfit?.impact?.profit?.toFixed() || 0, 0),
						clampNumber(improveROI?.impact?.profit?.toFixed() || 0, 0),
					],
					backgroundColor: [
						'rgb(51,20,53)',
						'rgb(173,163,214)',
						'rgb(181,225,206)',
						'rgb(22,59,68)',
						'rgb(239,103,55)',
					],
					borderColor: "white",
					borderWidth: 3,
				},
			],
		};
	}, [ props.calculationResults ]);

	return (
		<>

			<Card {...card}>
				<CardContent {...cardContent}>
					<Typography variant="h6" sx={{ px: 2.5, py: 1.5 }}>Impact Summary</Typography>

					<Divider />

					<Box>
						<Table {...table}>
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell align="right">Cashflow</TableCell>
									<TableCell align="right">Profit</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>

								<TableRow {...tableRow}>
									<TableCell component="th" scope="row">Improve cash conversion</TableCell>
									<TableCell align="right">{addPlusMinusToNumber(beautifyToFixed(increaseCashflow?.impact?.cash || 0), true, currency)}</TableCell>
									<TableCell align="right">-</TableCell>
								</TableRow>

								<TableRow {...tableRow}>
									<TableCell component="th" scope="row">Increase sales</TableCell>
									<TableCell align="right">{addPlusMinusToNumber(beautifyToFixed(increaseSales?.sales?.impact?.cash || 0), true, currency)}</TableCell>
									<TableCell align="right">{addPlusMinusToNumber(beautifyToFixed(increaseSales?.sales?.impact?.profit || 0), true, currency)}</TableCell>
								</TableRow>

								<TableRow {...tableRow}>
									<TableCell component="th" scope="row">Increase gross margin</TableCell>
									<TableCell align="right">{addPlusMinusToNumber(beautifyToFixed(improveGrossMargin?.impact?.cash || 0), true, currency)}</TableCell>
									<TableCell align="right">{addPlusMinusToNumber(beautifyToFixed(improveGrossMargin?.impact?.profit || 0), true, currency)}</TableCell>
								</TableRow>

								<TableRow {...tableRow}>
									<TableCell component="th" scope="row">Reduce overheads/<br/>increase investment</TableCell>
									<TableCell align="right">{addPlusMinusToNumber(beautifyToFixed(increaseProfit?.impact?.cash || 0), true, currency)}</TableCell>
									<TableCell align="right">{addPlusMinusToNumber(beautifyToFixed(increaseProfit?.impact?.profit || 0), true, currency)}</TableCell>
								</TableRow>

								<TableRow {...tableRow}>
									<TableCell component="th" scope="row">Increase ROI</TableCell>
									<TableCell align="right">{addPlusMinusToNumber(beautifyToFixed(improveROI?.impact?.cash || 0), true, currency)}</TableCell>
									<TableCell align="right">{addPlusMinusToNumber(beautifyToFixed(improveROI?.impact?.profit || 0), true, currency)}</TableCell>
								</TableRow>

								<TableRow {...tableRow}>
									<TableCell component="th" scope="row">Total</TableCell>
									<TableCell align="right" sx={{ fontWeight: 700 }}>{addPlusMinusToNumber(beautifyToFixed(totalImpact?.cash || 0), true, currency)}</TableCell>
									<TableCell align="right" sx={{ fontWeight: 700 }}>{addPlusMinusToNumber(beautifyToFixed(totalImpact?.profit || 0), true, currency)}</TableCell>
								</TableRow>

								<TableRow {...tableRow}>
									<TableCell component="th" scope="row">Potential business value impact</TableCell>
									<TableCell align="right" sx={{ fontWeight: 700 }}></TableCell>
									<TableCell align="right" sx={{ fontWeight: 700 }}>{addPlusMinusToNumber(beautifyToFixed(potentialValueImpact?.profit || 0), true, currency)}</TableCell>
								</TableRow>

							</TableBody>
						</Table>
					</Box>
				</CardContent>
			</Card>

			<Grid {...gridContainer}>

				<Grid {...gridColumn}>
					<Card {...card}>
						<CardContent {...cardContent}>
							<Stack direction="column" justifyContent="space-between" flex={1}>

								<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2.5, py: 1.5 }}>
									<Typography variant="subtitle2">Total Cash Impact</Typography>
									<Typography variant="subtitle2">{addPlusMinusToNumber(beautifyToFixed(totalImpact?.cash || 0), true, currency)}</Typography>
								</Stack>

								<Box sx={{ pb: 1, height: 288, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<Pie options={pieChartOptions} data={pieDataCash} />
								</Box>

							</Stack>
						</CardContent>
					</Card>
				</Grid>

				<Grid {...gridColumn}>
					<Card {...card}>
						<CardContent {...cardContent}>
							<Stack direction="column" justifyContent="space-between" flex={1}>

								<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2.5, py: 1.5 }}>
									<Typography variant="subtitle2">Total Profit Impact</Typography>
									<Typography variant="subtitle2">{addPlusMinusToNumber(beautifyToFixed(totalImpact?.profit || 0), true, currency)}</Typography>
								</Stack>

								<Box sx={{ pb: 1, height: 288, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<Pie options={pieChartOptions} data={pieDataImpact} />
								</Box>

							</Stack>
						</CardContent>
					</Card>
				</Grid>

			</Grid>

		</>
	);
};

export default Summary;

Summary.propTypes = {
	calculationResults: PropTypes.object,
};

const card = {
	sx: {
		borderRadius: 4,
		boxShadow: 4,
		mb: 2,
		overflow: 'hidden',
		width: '100%',
	},
};

const cardContent = {
	sx: {
		'&, &:last-child': { 
			p: 0,
		},
	},
};

const table = {
	sx: {
		'tbody tr:nth-last-child(2)': {
			borderTop: 1,
			borderColor: 'divider',
		},
		'tbody tr:last-child td, tbody tr:last-child th': {
			py: 2,
		},
		'tbody tr:nth-last-child(3) td, tbody tr:nth-last-child(3) th': {
			pb: 4,
		},
		'td, th': {
			border: 0,
			// fontSize: 
		},
		mt: 2,
	}, 
	size: "small",
};

const tableRow = {
	sx: {
		"td, th": {
			px: 2.5,
			py: 1,
			fontSize: 16,
		},
	},
};

const gridContainer = {
	container: true,
	columnSpacing: 2,
	rowSpacing: 2,
	sx: {
		p: 0,
		mb: 2,
	},
	direction: "row",
	justifyContent: "space-between",
	alignItems: "baseline",
};

const gridColumn = {
	item: true,
	sx: {
		display: 'flex', 
		flexDirection: 'column',
		alignItems: 'start',
	},
	xs: 6,
	sm: 6,
	md: 6,
	lg: 6,
};
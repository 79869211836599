import React from "react";
import {
	Box,
} from '@mui/material';

interface ComponentProps {
	children: React.JSX.Element | string;
	value: number;
	index: number;
};

/**
 * Tab Panel
 * @param {number} value - tab index / id
 * @param {number} index - current tab index
 * @param {string|Element|ReactComponentElement} children
 * 
 */
const TabPanel: React.FC<ComponentProps> = (props): React.JSX.Element => {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

export default TabPanel;

TabPanel.defaultProps = {
	children: <></>,
};
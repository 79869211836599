import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '../store';

const destroy_delay = 1000;

// eslint-disable-next-line no-unused-vars
type TOnConfirm = (...args: unknown[]) => unknown;
// eslint-disable-next-line no-unused-vars
type TOnDismiss = (...args: unknown[]) => unknown;
type TShow = boolean;

export interface IPromiseContainer {
  onConfirm: TOnConfirm;
  onDismiss: TOnDismiss;
  show: TShow;
}

/**
 * Opens a component and allows the component to resolve and reject the promise
 * @param {Function} func - Function that returns the component
 * @returns {Promise}
 * @example
 * promiseContainer(({ onConfirm, onDismiss, show }) => (
 *   <Modal show={ show } onConfirm={ onConfirm } onDismiss={ onDismiss } { ...otherProps } />
 * ));
 */
// eslint-disable-next-line no-unused-vars
const promiseContainer = (func: ({ onConfirm, onDismiss, show }: IPromiseContainer) => React.JSX.Element): Promise<unknown> => {

	const container = document.createElement('div');
	document.body.appendChild(container);

	function show({
		onConfirm,
		onDismiss,
	}: {
    onConfirm: TOnConfirm;
    onDismiss: TOnDismiss;
  }) {
		render(
			<Provider store={ store }>
				{ func({ onConfirm, onDismiss, show: true }) }
			</Provider>,
			container
		);
	}

	function hide(
		{
			onConfirm,
			onDismiss,
		}: { onConfirm: TOnConfirm; onDismiss: TOnDismiss },
		callback: () => void
	) {
		render(
			<Provider store={ store }>
				{ func({ onConfirm, onDismiss, show: false }) }
			</Provider>,
			container,
			callback
		);
	}

	function destroy() {
		unmountComponentAtNode(container);
		document.body.removeChild(container);
	}

	const confirmation = new Promise((resolve, reject) => {
		const onConfirm = (value: unknown) => resolve(value);
		const onDismiss = () => reject('dismissed');
		show({ onConfirm, onDismiss });
	});

	return confirmation.finally(() => {
		const emptyFunc = () => {};
		const onConfirm = emptyFunc;
		const onDismiss = emptyFunc;

		hide({ onConfirm, onDismiss }, () => {
			setTimeout(destroy, destroy_delay);
		});
	});
};

export default promiseContainer;

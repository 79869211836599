import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { 
	Box,
	Grid,
	Card,
	CardContent,
	Divider,
	Switch,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import _ from 'lodash';
import thousands from 'thousands';

import { 
	InfoOutlined as IconInfo,
} from '@mui/icons-material';

import { SliderInput, NumericInput } from '../../';
import { beautifyToFixed, addPlusMinusToNumber } from '../../../utilities';
import { currencyInput, tooltip, card, cardContent } from '../constants';
import { CurrencyContext } from '../contexts';

const Profit = (props) => {
	const [ data, setData ] = useState({});
	const currency = useContext(CurrencyContext);

	const { calculationResults } = props;

	const { improveGrossMargin, increaseProfit } = calculationResults || {};
	const { grossMargin, grossProfit, directCost } = calculationResults?.improveGrossMargin || {};
	const { overheadExpenses, netProfit, netMargin, value, clientBase } = calculationResults?.increaseProfit || {};
	const { 
		sales, 
		retentionRate, 
		retainedCustomers, 
		estimatedNewCustomers, 
		transactionsPerClientPerYear, 
		averageTransactionValue,
		leadsPerYear,
		leadConversionRate,
		newCustomers,
		totalCustomers,
		changeSales,
	} = calculationResults?.increaseSales || {};

	const repeatCustomers = props.inputs?.repeatCustomers == 1 ? true : false;
	const knownLeadGeneration = props.inputs?.knownLeadGen == 1 ? true : false;
	const detailedSalesDrivers = data?.detailedSalesDrivers == 1 ? true : false;

	useEffect(() => {
		if (!_.isEqual(props.values, data)) {
			setData(props.values);
		}
	}, [ props.values ]);

	const changeFieldValue = (field, value) => {
		setData(prevState => {
			const new_state = {
				...prevState,
				[field]: value,
			};

			if (props.onChange?.constructor?.name === 'Function') props.onChange(new_state);

			return new_state;
		});
	};

	return (
		<>
			<Card {...card}>
				<CardContent {...cardContent}>
					<Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
						<Typography variant="h6" sx={{  px: 2.5, py: 1.5 }}>Sales</Typography>

						<Stack direction="row" alignItems="center" justifyContent="end" sx={{ pr: 1 }}>
							<Typography variant="body2" sx={{ color: 'gray' }}>Show detailed sales drivers</Typography>
							<Switch
								checked={data?.detailedSalesDrivers == 1 ? true : false}
								onChange={e => changeFieldValue('detailedSalesDrivers', e.target.checked ? 1 : 0)}
							/>
						</Stack>
					</Stack>

					<Box>

						<Box sx={{ px: 1.5 }}>
							<Grid {...gridContainerHeader}>
								<Grid {...gridColumn1}>
									<Typography sx={{ color: 'gray' }}>Strategy</Typography>
								</Grid>
								<Grid {...gridColumn2}>
									<Typography sx={{ color: 'gray' }}>Change</Typography>
								</Grid>
								<Grid {...gridColumn3}>
									<Typography sx={{ color: 'gray' }}>Impact</Typography>
								</Grid>
							</Grid>
						</Box>

						<Divider />

						<Box sx={{ px: 1.5 }}>

							{
								// model: total
								!detailedSalesDrivers && <Grid {...gridContainer}>
									<Grid {...gridColumn1}>
										<Box>
											<Typography sx={{ mr: 1 }}>
												<Typography sx={{ mr: 1, display: 'inline' }}>Increase sales</Typography>
											</Typography>
										</Box>
										{
											sales?.current === sales?.new ?
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Sales are currently {currency}{thousands(beautifyToFixed(sales?.current, 2))}</Typography>
												:
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change sales from {currency}{thousands(beautifyToFixed(sales?.current, 2))} to <strong>{currency}{thousands(beautifyToFixed(sales?.new, 2))}</strong></Typography>
										}
									</Grid>
									<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
										<SliderInput
											type="percentage"
											size="small"
											sliderMin={-.2}
											sliderMax={.2}
											step={.01}
											valueLabelTemplate="{value}%"
											value={data?.salesChangeBy}
											onChange={e => changeFieldValue('salesChangeBy', parseFloat(e.target.value))}
										/>
									</Grid>
									<Grid {...gridColumn3}>
										<Typography>{addPlusMinusToNumber(beautifyToFixed(sales?.diff), true, currency)} sales</Typography>
									</Grid>
								</Grid>
							}

							{
								// model: detailed
								detailedSalesDrivers && <Stack>

									{
										!repeatCustomers && !knownLeadGeneration && <>
											<Grid {...gridContainer}>
												<Grid {...gridColumn1}>
													<Box>
														<Typography sx={{ mr: 1 }}>
															<Typography sx={{ mr: 1, display: 'inline' }}>Increase number of customers</Typography>
															<Tooltip {...tooltip} title="If you can grow the number of customers you have, you will grow sales (as long as the average value and frequency remain constant or increase).">
																<IconInfo fontSize="small" />
															</Tooltip>
														</Typography>
													</Box>
													{
														clientBase?.current === clientBase?.new ?
															<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Client base is currently {thousands(beautifyToFixed(clientBase?.current, 2))}</Typography>
															:
															<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change client base from {thousands(beautifyToFixed(clientBase?.current))} to <strong>{thousands(beautifyToFixed(clientBase?.new))}</strong></Typography>
													}
												</Grid>
												<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
													<SliderInput
														type="percentage"
														size="small"
														sliderMin={-.5}
														sliderMax={.5}
														step={.01}
														valueLabelTemplate="{value}%"
														value={data?.clientBaseChangeBy}
														onChange={e => changeFieldValue('clientBaseChangeBy', parseFloat(e.target.value))}
													/>
												</Grid>
												<Grid {...gridColumn3}>
													<Typography>{addPlusMinusToNumber(beautifyToFixed(clientBase?.diff))} new customers</Typography>
												</Grid>
											</Grid>
										</>
									}

									{
										repeatCustomers && <>
											<Grid {...gridContainer}>
												<Grid {...gridColumn1}>
													<Box>
														<Typography sx={{ mr: 1 }}>
															<Typography sx={{ mr: 1, display: 'inline' }}>Increase retention rate</Typography>
															<Tooltip {...tooltip} title="It's often overlooked that you can grow your business by reducing the number of customers you lose i.e. by increasing your customer retention rate.">
																<IconInfo fontSize="small" />
															</Tooltip>
														</Typography>
													</Box>
													<Box>
														{
															retentionRate?.current === retentionRate?.new ?
																<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Retention rate is currently {thousands(beautifyToFixed(retentionRate?.current * 100, 2))}%</Typography>
																:
																<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change retention rate from {thousands(beautifyToFixed(retentionRate?.current * 100, 2))}% to <strong>{thousands(beautifyToFixed(retentionRate?.new * 100, 2))}%</strong></Typography>
														}
														{
															retainedCustomers?.current === retainedCustomers?.new ?
																<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Retained customers are currently {beautifyToFixed(retainedCustomers?.current)}</Typography>
																:
																<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change retained customers from {beautifyToFixed(retainedCustomers?.current)} to <strong>{beautifyToFixed(retainedCustomers?.new)}</strong></Typography>
														}
													</Box>
												</Grid>
												<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
													<SliderInput
														type="percentage"
														size="small"
														sliderMin={-.2}
														sliderMax={.2}
														step={.01}
														valueLabelTemplate="{value}%"
														value={data?.retentionRateChangeBy}
														onChange={e => changeFieldValue('retentionRateChangeBy', parseFloat(e.target.value))}
													/>
												</Grid>
												<Grid {...gridColumn3}>
													<Typography>{addPlusMinusToNumber(beautifyToFixed(retainedCustomers?.diff))} retained customers</Typography>
												</Grid>
											</Grid>
										</>
									}

									{
										repeatCustomers && !knownLeadGeneration && <>

											<Grid {...gridContainer}>
												<Grid {...gridColumn1}>
													<Box>
														<Typography sx={{ mr: 1 }}>
															<Typography sx={{ mr: 1, display: 'inline' }}>Increase number of new customers</Typography>
															<Tooltip {...tooltip} title="If you can grow the number of customers you have, you will grow sales (as long as the average value and frequency remain constant or increase).">
																<IconInfo fontSize="small" />
															</Tooltip>
														</Typography>
													</Box>
													{
														estimatedNewCustomers?.current === estimatedNewCustomers?.new ?
															<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Number of new customers is currently {thousands(beautifyToFixed(estimatedNewCustomers?.current, 2))}</Typography>
															:
															<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change number of new customers from {thousands(beautifyToFixed(estimatedNewCustomers?.current, 2))} to <strong>{thousands(beautifyToFixed(estimatedNewCustomers?.new, 2))}</strong></Typography>
													}

													{
														repeatCustomers && !knownLeadGeneration && <>
															{
																(totalCustomers?.new == totalCustomers?.current) ?
																	<Typography variant="body2" color="gray" sx={{ mt: .5, textAlign: 'right' }}>Total customers per year is {beautifyToFixed(totalCustomers?.current || 0)}</Typography>
																	:
																	<Typography variant="body2" color="gray" sx={{ mt: .5, textAlign: 'right' }}>Change total customers per year from {beautifyToFixed(totalCustomers?.current || 0)} to <strong>{beautifyToFixed(totalCustomers?.new || 0)}</strong></Typography>
															}

														</>
													}
												</Grid>
												<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
													<SliderInput
														type="percentage"
														size="small"
														sliderMin={-.2}
														sliderMax={.2}
														step={.01}
														valueLabelTemplate="{value}%"
														value={data?.estimatedNewCustomersChangeBy}
														onChange={e => changeFieldValue('estimatedNewCustomersChangeBy', parseFloat(e.target.value))}
													/>
												</Grid>
												<Grid {...gridColumn3}>
													<Typography>{addPlusMinusToNumber(beautifyToFixed(estimatedNewCustomers?.diff))} new customers</Typography>
												</Grid>
											</Grid>

										</>
									}

									{
										knownLeadGeneration && <>

											<Grid {...gridContainer}>
												<Grid {...gridColumn1}>
													<Box>
														<Typography sx={{ mr: 1 }}>
															<Typography sx={{ mr: 1, display: 'inline' }}>Increase number of leads per year</Typography>
															<Tooltip {...tooltip} title="If you increase enquiries / requests for proposals, you will grow sales (as long as your sales conversion rate, transaction value, and frequency remain constant or increase).">
																<IconInfo fontSize="small" />
															</Tooltip>
														</Typography>
													</Box>
													{
														leadsPerYear?.current === leadsPerYear?.new ?
															<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Current number of leads is {thousands(beautifyToFixed(leadsPerYear?.current))}</Typography>
															:
															<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change number of leads from {thousands(beautifyToFixed(leadsPerYear?.current))} to <strong>{thousands(beautifyToFixed(leadsPerYear?.new))}</strong></Typography>
													}
												</Grid>
												<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
													<SliderInput
														type="percentage"
														size="small"
														sliderMin={-.5}
														sliderMax={.5}
														step={.01}
														valueLabelTemplate="{value}%"
														value={data?.leadsPerYearChangeBy}
														onChange={e => changeFieldValue('leadsPerYearChangeBy', parseFloat(e.target.value))}
													/>
												</Grid>
												<Grid {...gridColumn3}>
													<Typography>{addPlusMinusToNumber(beautifyToFixed(leadsPerYear?.diff))} new leads</Typography>
												</Grid>
											</Grid>

											<Grid {...gridContainer}>
												<Grid {...gridColumn1}>
													<Box>
														<Typography sx={{ mr: 1 }}>
															<Typography sx={{ mr: 1, display: 'inline' }}>Increase lead conversion rate</Typography>
															<Tooltip {...tooltip} title="If you increase the number of prospective customers that buy from you, you will grow sales (as long as the number of leads, transaction value, and transaction frequency remain constant or increase).">
																<IconInfo fontSize="small" />
															</Tooltip>
														</Typography>
													</Box>
													<Box>
														{
															leadConversionRate?.current === leadConversionRate?.new ?
																<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Lead conversion rate is currently {thousands(beautifyToFixed(leadConversionRate?.current * 100, 2))}%</Typography>
																:
																<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change lead conversion rate from {thousands(beautifyToFixed(leadConversionRate?.current * 100, 2))}% to <strong>{thousands(beautifyToFixed(leadConversionRate?.new * 100, 2))}%</strong></Typography>
														}

														{
															!repeatCustomers && <>
																{
																	newCustomers?.current === newCustomers?.new ?
																		<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Number of customer is currently {beautifyToFixed(newCustomers?.current)}</Typography>
																		:
																		<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change number of customers from {beautifyToFixed(newCustomers?.current)} to <strong>{beautifyToFixed(newCustomers?.new)}</strong></Typography>
																}
															</>
														}

														{
															repeatCustomers && <>

																{
																	newCustomers?.current === newCustomers?.new ?
																		<Typography variant="body2" color="gray" sx={{ mt: 1 }}>New customers are currently {beautifyToFixed(newCustomers?.current)}</Typography>
																		:
																		<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change new customers from {beautifyToFixed(newCustomers?.current)} to <strong>{beautifyToFixed(newCustomers?.new)}</strong></Typography>
																}

																{
																	totalCustomers?.current === totalCustomers?.new ?
																		<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Total customers are currently {beautifyToFixed(totalCustomers?.current)}</Typography>
																		:
																		<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change total customers from {beautifyToFixed(totalCustomers?.current)} to <strong>{beautifyToFixed(totalCustomers?.new)}</strong></Typography>
																}
															</>
														}
													</Box>
												</Grid>
												<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
													<SliderInput
														type="percentage"
														size="small"
														sliderMin={-.5}
														sliderMax={.5}
														step={.01}
														valueLabelTemplate="{value}%"
														value={data?.leadConversionRateChangeBy}
														onChange={e => changeFieldValue('leadConversionRateChangeBy', parseFloat(e.target.value))}
													/>
												</Grid>
												<Grid {...gridColumn3}>
													<Typography>{addPlusMinusToNumber(beautifyToFixed(newCustomers?.diff))} new customers</Typography>
												</Grid>
											</Grid>
										</>
									}

									<Grid {...gridContainer}>
										<Grid {...gridColumn1}>
											<Box>
												<Typography sx={{ mr: 1 }}>
													<Typography sx={{ mr: 1, display: 'inline' }}>Increase transactions per customer</Typography>
													<Tooltip {...tooltip} title="If you increase the number of times customers buy from you, you will grow sales (as long as the number of leads you generate, how many of those leads convert to customers, and transaction value remain constant or increase).">
														<IconInfo fontSize="small" />
													</Tooltip>
												</Typography>
											</Box>
											{
												transactionsPerClientPerYear?.current === transactionsPerClientPerYear?.new ?
													<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Transactions per customer is currently {thousands(beautifyToFixed(transactionsPerClientPerYear?.current, 1))}</Typography>
													:
													<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change transaction per customer from {thousands(beautifyToFixed(transactionsPerClientPerYear?.current, 1))} to <strong>{thousands(beautifyToFixed(transactionsPerClientPerYear?.new, 1))}</strong></Typography>
											}
										</Grid>
										<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
											<SliderInput
												type="percentage"
												size="small"
												sliderMin={-.5}
												sliderMax={.5}
												step={.01}
												valueLabelTemplate="{value}%"
												value={data?.transactionsPerClientPerYearChangeBy}
												onChange={e => changeFieldValue('transactionsPerClientPerYearChangeBy', parseFloat(e.target.value))}
											/>
										</Grid>
										<Grid {...gridColumn3}>
											<Typography>{addPlusMinusToNumber(beautifyToFixed(transactionsPerClientPerYear?.diff, 1))} transactions per client</Typography>
										</Grid>
									</Grid>

									<Grid {...gridContainer}>
										<Grid {...gridColumn1}>
											<Box>
												<Typography sx={{ mr: 1 }}>
													<Typography sx={{ mr: 1, display: 'inline' }}>Increase average transaction value</Typography>
													<Tooltip {...tooltip} title="If you increase the amount customers spend with you each time they buy, you will grow sales (as long as the number of leads you generate, how many of those leads convert to customers, and transaction frequency remain constant or increase).">
														<IconInfo fontSize="small" />
													</Tooltip>
												</Typography>
											</Box>
											{
												averageTransactionValue?.current === averageTransactionValue?.new ?
													<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Average transaction value is currently {currency}{thousands(beautifyToFixed(averageTransactionValue?.current))}</Typography>
													:
													<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change average transaction value from {currency}{thousands(beautifyToFixed(averageTransactionValue?.current))} to <strong>{currency}{thousands(beautifyToFixed(averageTransactionValue?.new))}</strong></Typography>
											}
										</Grid>
										<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
											<SliderInput
												type="percentage"
												size="small"
												sliderMin={-.2}
												sliderMax={.2}
												step={.01}
												valueLabelTemplate="{value}%"
												value={data?.averageTransactionValueChangeBy}
												onChange={e => changeFieldValue('averageTransactionValueChangeBy', parseFloat(e.target.value))}
											/>
										</Grid>
										<Grid {...gridColumn3}>
											<Typography>{addPlusMinusToNumber(beautifyToFixed(averageTransactionValue?.diff), true, currency)} average value</Typography>
										</Grid>
									</Grid>

								</Stack>
							}

						</Box>

						{
							detailedSalesDrivers &&
							<Box sx={{ borderTop: 1, borderColor: 'divider', p: 1 }}>
								<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1.5, py: .5 }}>
									<Box className="">
										{
											changeSales?.current == changeSales?.new ?
												<Typography sx={{ mr: 1, display: 'inline' }}>Sales are currently {currency}{thousands(beautifyToFixed(changeSales?.current))}</Typography>
												:
												<Typography sx={{ mr: 1, display: 'inline' }}>Change sales from {currency}{thousands(beautifyToFixed(changeSales?.current))} to <strong>{currency}{thousands(beautifyToFixed(changeSales?.new))}</strong></Typography>
										}
									</Box>
									<Box>
										<Typography>{addPlusMinusToNumber(beautifyToFixed(changeSales?.diff), true, currency)} sales</Typography>
									</Box>
								</Stack>
							</Box>
						}

						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', borderTop: 1, borderColor: 'divider', p: 1 }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography variant="subtitle2" sx={{ fontWeight: 700, textAlignt: 'right', ml: 3, mr: 2 }}>Cash Impact</Typography>
								<Typography variant="h6">{addPlusMinusToNumber(beautifyToFixed(sales?.impact?.cash), true, currency)}</Typography>
							</Box>

							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography variant="subtitle2" sx={{ fontWeight: 700, textAlignt: 'right', ml: 3, mr: 2 }}>Profit Impact</Typography>
								<Typography variant="h6">{addPlusMinusToNumber(beautifyToFixed(sales?.impact?.profit), true, currency)}</Typography>
							</Box>
						</Box>

					</Box>
				</CardContent>
			</Card>

			<Card {...card}>
				<CardContent {...cardContent}>
					<Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
						<Typography variant="h6" sx={{ px: 2.5, py: 1.5 }}>Gross Profit Margin</Typography>
					</Stack>

					<Box>

						<Box sx={{ px: 1.5 }}>
							<Grid {...gridContainerHeader}>
								<Grid {...gridColumn1}>
									<Typography sx={{ color: 'gray' }}>Strategy</Typography>
								</Grid>
								<Grid {...gridColumn2}>
									<Typography sx={{ color: 'gray' }}>Change</Typography>
								</Grid>
								<Grid {...gridColumn3}>
									<Typography sx={{ color: 'gray' }}>Impact</Typography>
								</Grid>
							</Grid>
						</Box>

						<Divider />

						<Box sx={{ px: 1.5 }}>

							<Grid {...gridContainer}>
								<Grid {...gridColumn1}>
									<Box>
										<Typography sx={{ mr: 1 }}>
											<Typography sx={{ mr: 1, display: 'inline' }}>Increase gross profit margin</Typography>
											<Tooltip {...tooltip} title="Gross profit margin is your total sales divided by your variable costs (or cost of sales). Gross profit margin is often expressed as a percentage (gross profit divided by sales). If you can increase your gross profit margin, you will grow your profit (as long as fixed/overhead costs remain the same or decrease).">
												<IconInfo fontSize="small" />
											</Tooltip>
										</Typography>
									</Box>

									<Stack direction="column">
										{
											directCost?.current === directCost?.new ?
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Cost of sales is currently {currency}{thousands(beautifyToFixed(directCost?.current))}</Typography>
												:
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change cost of sales from {currency}{thousands(beautifyToFixed(directCost?.current))} to <strong>{currency}{thousands(beautifyToFixed(directCost?.new))}</strong></Typography>
										}

										{
											grossProfit?.current === grossProfit?.new ?
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Gross profit is currently {currency}{thousands(beautifyToFixed(grossProfit?.current))}</Typography>
												:
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change gross profit from {currency}{thousands(beautifyToFixed(grossProfit?.current))} to <strong>{currency}{thousands(beautifyToFixed(grossProfit?.new))}</strong></Typography>
										}

										{
											grossMargin?.current === grossMargin?.new ?
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Gross margin is currently {thousands(beautifyToFixed(grossMargin?.current * 100))}%</Typography>
												:
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change gross margin from {thousands(beautifyToFixed(grossMargin?.current * 100))}% to <strong>{thousands(beautifyToFixed(grossMargin?.new * 100))}%</strong></Typography>
										}

									</Stack>
								</Grid>
								<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
									<SliderInput
										type="percentage"
										size="small"
										sliderMin={-.2}
										sliderMax={.2}
										step={.01}
										valueLabelTemplate="{value}%"
										value={data?.grossMarginChangeBy}
										onChange={e => changeFieldValue('grossMarginChangeBy', parseFloat(e.target.value))}
									/>
								</Grid>
								<Grid {...gridColumn3}>
									<Typography>{addPlusMinusToNumber(beautifyToFixed(grossProfit?.diff), true, currency)} gross profit</Typography>
								</Grid>
							</Grid>

						</Box>

						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', borderTop: 1, borderColor: 'divider', p: 1 }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography variant="subtitle2" sx={{ fontWeight: 700, textAlignt: 'right', ml: 3, mr: 2 }}>Cash Impact</Typography>
								<Typography variant="h6">{addPlusMinusToNumber(beautifyToFixed(improveGrossMargin?.impact?.cash), true, currency)}</Typography>
							</Box>

							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography variant="subtitle2" sx={{ fontWeight: 700, textAlignt: 'right', ml: 3, mr: 2 }}>Profit Impact</Typography>
								<Typography variant="h6">{addPlusMinusToNumber(beautifyToFixed(improveGrossMargin?.impact?.profit), true, currency)}</Typography>
							</Box>
						</Box>

					</Box>
				</CardContent>
			</Card>

			<Card {...card}>
				<CardContent {...cardContent}>
					<Typography variant="h6" sx={{ px: 2.5, py: 1.5 }}>Overheads</Typography>

					<Box>

						<Box sx={{ px: 1.5 }}>
							<Grid {...gridContainerHeader}>
								<Grid {...gridColumn1}>
									<Typography sx={{ color: 'gray' }}>Strategy</Typography>
								</Grid>
								<Grid {...gridColumn2}>
									<Typography sx={{ color: 'gray' }}>Change</Typography>
								</Grid>
								<Grid {...gridColumn3}>
									<Typography sx={{ color: 'gray' }}>Impact</Typography>
								</Grid>
							</Grid>
						</Box>

						<Divider />

						<Box sx={{ px: 1.5 }}>

							<Grid {...gridContainer}>
								<Grid {...gridColumn1}>
									<Box>
										<Typography sx={{ mr: 1 }}>
											<Typography sx={{ mr: 1, display: 'inline' }}>Reduce overheads/increase investment needed</Typography>
											<Tooltip {...tooltip} title="Net profit is calculated as gross profit less overheads/fixed costs (plus other income). Net profit can be expressed before or after tax. You may need to increase overheads for investment in initiatives to improve sales or cashflow, e.g. marketing and professional expenses.">
												<IconInfo fontSize="small" />
											</Tooltip>
										</Typography>
									</Box>

									<Stack direction="column">

										{
											overheadExpenses?.current === overheadExpenses?.new ?
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Overheads are currently {currency}{thousands(beautifyToFixed(overheadExpenses?.current))}</Typography>
												:
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change overheads from {currency}{thousands(beautifyToFixed(overheadExpenses?.current))} to <strong>{currency}{thousands(beautifyToFixed(overheadExpenses?.new))}</strong></Typography>
										}

										{
											props.inputs?.haveOtherIncome == 1 &&
											<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Other income is currently {currency}{thousands(beautifyToFixed(props.inputs?.otherIncome))}</Typography>
										}

										{
											netProfit?.current === netProfit?.new ?
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Net profit is currently {currency}{thousands(beautifyToFixed(netProfit?.current))}</Typography>
												:
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change net profit from {currency}{thousands(beautifyToFixed(netProfit?.current))} to <strong>{currency}{thousands(beautifyToFixed(netProfit?.new))}</strong></Typography>
										}

										{
											netMargin?.current === netMargin?.new ?
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Net margin is currently {thousands(beautifyToFixed(netMargin?.current))}%</Typography>
												:
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change net margin from {thousands(beautifyToFixed(netMargin?.current, 1))}% to <strong>{thousands(beautifyToFixed(netMargin?.new, 1))}%</strong></Typography>
										}

										{
											value?.current === value?.new ?
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Estimated value is currently {currency}{thousands(beautifyToFixed(value?.current))}</Typography>
												:
												<Typography variant="body2" color="gray" sx={{ mt: 1 }}>Change estimated value from {currency}{thousands(beautifyToFixed(value?.current))} to <strong>{currency}{thousands(beautifyToFixed(value?.new))}</strong></Typography>
										}


									</Stack>
								</Grid>
								<Grid {...gridColumn2} sx={{ display: 'flex', alignItems: 'center' }}>
									<NumericInput {...currencyInput} currency={currency} placeholder={currency}
										step={1000}
										value={data?.overheadExpensesChangeBy}
										onChange={e => changeFieldValue('overheadExpensesChangeBy', parseFloat(e.target.value))}
									/>
								</Grid>
								<Grid {...gridColumn3}>
									<Typography>{addPlusMinusToNumber(beautifyToFixed(overheadExpenses?.impact?.profit), true, currency)} net profit</Typography>
								</Grid>
							</Grid>

						</Box>

						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', borderTop: 1, borderColor: 'divider', p: 1 }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography variant="subtitle2" sx={{ fontWeight: 700, textAlignt: 'right', ml: 3, mr: 2 }}>Cash Impact</Typography>
								<Typography variant="h6">{addPlusMinusToNumber(beautifyToFixed(increaseProfit?.impact?.cash), true, currency)}</Typography>
							</Box>

							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography variant="subtitle2" sx={{ fontWeight: 700, textAlignt: 'right', ml: 3, mr: 2 }}>Profit Impact</Typography>
								<Typography variant="h6">{addPlusMinusToNumber(beautifyToFixed(increaseProfit?.impact?.profit), true, currency)}</Typography>
							</Box>
						</Box>

					</Box>
				</CardContent>
			</Card>

		</>
	);
};

export default Profit;

export const propTypes = {
	inputs: PropTypes.shape({
		repeatCustomers: 		PropTypes.oneOf([ 1, 0 ]).isRequired,
		knownLeadGen:			PropTypes.oneOf([ 1, 0 ]).isRequired,
		haveOtherIncome: 		PropTypes.oneOf([ 1, 0 ]).isRequired,
		otherIncome: 			PropTypes.number.isRequired,
		overheadExpenses:		PropTypes.number.isRequired,
	}),
	values: PropTypes.shape({
		detailedSalesDrivers:			PropTypes.oneOf([ 1, 0 ]).isRequired,
		salesChangeBy:				PropTypes.number.isRequired,
		grossMarginChangeBy:			PropTypes.number.isRequired,
		overheadExpensesChangeBy:	PropTypes.number.isRequired,
	}).isRequired,
	enableDetailedSalesDrivers: PropTypes.bool,
	calculationResults: PropTypes.object.isRequired,
	onChange: PropTypes.func,
};

Profit.propTypes = propTypes;

Profit.defaultProps ={
	inputs: {
		repeatCustomers: 		0,
		knownLeadGen:			0,
		overheadExpenses:		0,
	},
	values: {
		detailedSalesDrivers:			0,
		salesChangeBy:				0,
		grossMarginChangeBy:			0,
		overheadExpensesChangeBy:	0,
	},
	enableDetailedSalesDrivers: true,
};

const gridContainer = {
	container: true,
	columnSpacing: 2,
	rowSpacing: 2,
	sx: {
		p: 1,
		mb: 2,
	},
	direction: "row",
	justifyContent: "space-between",
	alignItems: "baseline",
};

const gridContainerHeader = {
	...gridContainer,
	sx: {
		...gridContainer.sx,
		mb: 0,
	},
};

const gridColumn = {
	item: true,
	sx: {
		display: 'flex', 
		flexDirection: 'column',
		alignItems: 'start',
	},
};

const gridColumn1 = {
	...gridColumn,
	xs: 5,
	sm: 5,
	md: 5,
	lg: 5,
};

const gridColumn2 = {
	...gridColumn,
	sx: {
		...gridColumn.sx,
		justifyContent: 'end',
	},
	xs: 4,
	sm: 4,
	md: 4,
	lg: 4,
};

const gridColumn3 = {
	...gridColumn,
	sx: {
		...gridColumn.sx,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'end',
		textAlign: 'right',
	},
	xs: 3,
	sm: 3,
	md: 3,
	lg: 3,
};
export const currencyInput = {
	type: 'currency',
	placeholder: "$",
	size: "small",
	sx: {
		width: '100%',
	},
};

export const percentageInput = {
	type: 'percentage',
	placeholder: "%",
	size: "small",
	sx: {
		width: '100%',
	},
};

export const numberInput = {
	type: "number",
	size: "small",
	sx: {
		width: '100%',
	},
};

export const tooltip = {
	arrow: true,
};

export const card = {
	sx: {
		borderRadius: 4,
		boxShadow: 4,
		mb: 2,
		overflow: 'hidden',
	},
};

export const cardContent = {
	sx: {
		'&, &:last-child': { 
			p: 0,
		},
	},
};

export const presetDataJson: TCalculationData = {
	inputs: {
		sales: 1250000,
		accountsReceivable: 230000,
		taxRate: .28,
		directCost: 700000,
		inventory: 110000,
		valuationMultiple: 3.5,
		overheadExpenses: 500000,
		accountsPayable: 95000,
		teamMembers: 6.5,
		haveOtherIncome: 0,
		otherIncome: 0,

		useCustomersAndSales: 0, // 1: yes, 0: no
		repeatCustomers: 1, // 1: yes, 0: no
		knownLeadGen: 0, // 1: yes, 0: no

		existingCustomers: 765,
		retentionRate: .75,
		estimatedNewCustomers: 80,
		transactionsPerClientPerYear: 1.0,
		totalCustomers: 765,
		// totalCustomers_change_by: 0, // deprecated?
		leadsPerYear: 160,
		leadConversionRate: .5,
	},
	profit: {
		detailedSalesDrivers: 0, // 1: on, 0: off
		salesChangeBy: 0,
		clientBaseChangeBy: 0,
		grossMarginChangeBy: 0,
		overheadExpensesChangeBy: 0,
		retentionRateChangeBy: 0,
		estimatedNewCustomersChangeBy: 0,
		transactionsPerClientPerYearChangeBy: 0,
		averageTransactionValueChangeBy: 0,
		leadsPerYearChangeBy: 0,
		leadConversionRateChangeBy: 0,
	},
	cashflow: {
		debtorDaysChangeBy: 0,
		inventoryDaysChangeBy: 0,
		payableDaysChangeBy: 0,
	},
	returnOnInvestment: {
		revenueByTeamMemberChangeBy: 0
	}
};

export const defaultDataJson: TCalculationData = {
	inputs: {
		sales: 0,
		accountsReceivable: 0,
		taxRate: 0,
		directCost: 0,
		inventory: 0,
		valuationMultiple: 0,
		overheadExpenses: 0,
		accountsPayable: 0,
		teamMembers: 0,
		haveOtherIncome: 0,
		otherIncome: 0,

		useCustomersAndSales: 0, // 1: yes, 0: no
		repeatCustomers: 0, // 1: yes, 0: no
		knownLeadGen: 0, // 1: yes, 0: no

		existingCustomers: 0,
		retentionRate: 0,
		estimatedNewCustomers: 0,
		transactionsPerClientPerYear: 0,
		totalCustomers: 0,
		// totalCustomers_change_by: 0, // deprecated?
		leadsPerYear: 0,
		leadConversionRate: 0,
	},
	profit: {
		detailedSalesDrivers: 0, // 1: on, 0: off
		salesChangeBy: 0,
		clientBaseChangeBy: 0,
		grossMarginChangeBy: 0,
		overheadExpensesChangeBy: 0,
		retentionRateChangeBy: 0,
		estimatedNewCustomersChangeBy: 0,
		transactionsPerClientPerYearChangeBy: 0,
		averageTransactionValueChangeBy: 0,
		leadsPerYearChangeBy: 0,
		leadConversionRateChangeBy: 0,
	},
	cashflow: {
		debtorDaysChangeBy: 0,
		inventoryDaysChangeBy: 0,
		payableDaysChangeBy: 0,
	},
	returnOnInvestment: {
		revenueByTeamMemberChangeBy: 0
	}
};

export const presetReportModifications: TCalculationReportModifications = {
	sections: {
		cashflow: true,
		profit: true,
		roi: true,
		inputs: true,
	},
	fields: {
		reportIntro: `This report provides an analysis of the strategies you can adopt to increase your business's profitability, cashflow, and value. You'll find an overview of potential changes and the impact each change has on your profit and cashflow. We're here to support you to implement tactics and projects to ensure you achieve these improvements.`,
		debtorDayChangeIntro: `Debtor days is how long, on average, it takes your customers to pay you after you've invoiced them. Reducing debtor days frees up cash, as your customers are paying you quicker.`,
		inventoryDayChangeIntro: `Inventory days measures how long, on average, you hold an item in stock before it is sold (invoiced) to a customer. Work in progress (WIP) days measures how long, on average, your business works on a job before it is invoiced to a customer. Reducing inventory / WIP days frees up cash, as you are selling to / invoicing customers faster.`,
		payableDayChangeIntro: `Payable days measures how long, on average, it takes you to pay suppliers. While paying your suppliers slower allows you to hold onto cash longer, this can be stressful if these payment terms have not been agreed in advance. Reducing payable days requires an increase in cash conversion to be created elsewhere in the business to maintain cash levels.`,
		saleChangeIntro: `Increasing sales results in increased profit, cashflow, and business valuation (as long as margins, overheads, and cash conversion remain at current levels). Given there are 5 ways to grow sales, additional data on customer retention, lead growth and conversion, average annual spend and spend frequency is required to enable a more accurate analysis as to how to grow sales. We have provided an analysis on the overall impact of a percentage increase in sales. Our preference is to discuss which of the 5 ways to grow sales will be most appropriate and impactful in your business.`,
		numberOfCustomerChangeIntro: `Increasing your total number of new customers per year will increase sales, profitability, cashflow, and business valuation (as long as margins, overheads, and cash conversion remain at current levels).`,
		retentionRateChangeIntro: `One of the 5 ways to grow sales is to increase your customer retention rate (expressed as a percentage of your total customers that you keep each year). The easiest way to grow your business is to reduce the number of customers (of the type you want) that you lose each year.`,
		newCustomerChangeIntro: `Increasing your total number of new customers per year will increase sales, profitability, cashflow, and business valuation (as long as margins, overheads, and cash conversion remain at current levels).`,
		leadsPerYearChangeIntro: `Increasing your total number of leads (prospective customers) per year will increase sales, profitability, cashflow, and business valuation (as long as sales conversion, margins, overheads, and cash conversion remain at current levels).`,
		leadConversionChangeIntro: `Lead conversion is the rate at which proposals are accepted / enquiries turn into a sale. Increasing your lead conversion rate per year will increase sales, profitability, cashflow, and business valuation (as long as leads generated, margins, overheads, and cash conversion rates remain at current levels).`,
		transactionPerClientPerYearChangeIntro: `A great way to grow sales is to increase the number of times, on average, your customers buy from you each year.`,
		averageTransactionValueChangeIntro: `You can grow sales by increasing the amount, on average, that your customers spend with you each time they purchase your goods or services.`,
		grossMarginChangeIntro: `Your gross margin (or gross profit) is calculated by deducting all variable (sometimes referred to as 'direct') costs from your total sales. Increasing the margin (often expressed as a percentage of sales) will increase profitability, cashflow, and business value (as long as sales, overheads, and conversion rates remain at current levels).`,
		netProfitChangeIntro: `Overheads are the fixed costs that are incurred in your business regardless of the level of sales. Reducing overheads will increase profit and cashflow, and potentially business value, but can be difficult to achieve, particularly in inflationary times.`,
		roiChangeIntro: `Revenue per team member is the effective share of sales that each team member in the business (regardless of role) is contributing, and is therefore an overall measure of people efficiency. Increasing revenue per team member (while maintaining margin, overheads, and cash conversion), drives profit, cashflow, and potentially, business valuation.`,
		purposeAndNextIntro: `The purpose of this report is to identify a select (and manageable) number of strategies that, when successfully implemented, will enhance the profitability, cashflow and potential value of your business.

The potential cashflow, profit and business value impacts of the strategies outlined in this report are estimates only. There may be existing circumstances that hinder the realisation of these improvements. Whilst we provide valuable insights and recommendations, neither our team nor we accept responsibility for non-implementation of the strategies or for any unrealised improvements.

Within each strategy there are multiple tactics that can be employed to achieve the changes you seek. To fully unlock your Value Gap will require selecting the tactics that are most suited to your business and most likely to have the biggest impact. The logical next step is to schedule a Cashflow & Profit Improvement session with us. If you haven't already done so, this session will enable us to prioritise tactics, assign projects, and establish actionable tasks. If you have already had a Cashflow & Profit Improvement session with us, this report will serve as a reference to reinforce the strategies and tactics discussed during that session.
`,
	}
};